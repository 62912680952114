// Doc Templates actions
export const REQUEST_TEMPLATES = "REQUEST_TEMPLATES";
export const RECEIVE_TEMPLATES = "RECEIVE_TEMPLATES";
export const SET_TEMPLATES_LOADING_ERROR = "SET_TEMPLATES_LOADING_ERROR";

// Doc Template actions
export const REQUEST_TEMPLATE = "REQUEST_TEMPLATE";
export const SET_TEMPLATE_DATA = "SET_TEMPLATE_DATA";
export const SET_TEMPLATE_LOADING_ERROR = "SET_TEMPLATE_LOADING_ERROR";

// Doc Template editor
export const CREATE_NEW_DOCUMENT = "CREATE_NEW_DOCUMENT";
export const CREATE_NEW_AREA = "CREATE_NEW_AREA";
export const SET_CONTROL_PANEL_TOOL = "SET_CONTROL_PANEL_TOOL";
export const REMOVE_AREA = "REMOVE_AREA";
export const MOVE_AREA = "MOVE_AREA";
export const RESIZE_AREA = "RESIZE_AREA";
export const CLEAR_DOCUMENT_DATA = "CLEAR_DOCUMENT_DATA";
export const EDIT_AREA = "EDIT_AREA";
export const SET_DOCUMENT_NAME = "SET_DOCUMENT_NAME";
export const CLEAR_ERROR_TEXT = "CLEAR_ERROR_TEXT";

export const DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_REQUEST_SUCCESS = "DELETE_TEMPLATE_REQUEST_SUCCESS";
export const DELETE_TEMPLATE_REQUEST_FAIL = "DELETE_TEMPLATE_REQUEST_FAIL";

export const HIGHLIGHT_AREA = "HIGHLIGHT_AREA";
export const CLEAR_ALL_HIGHLIGHTED_AREAS = "CLEAR_ALL_HIGHLIGHTED_AREAS";

// Doc recognition
export const RECOGNIZE_FILE_REQUEST = "RECOGNIZE_FILE_REQUEST";
export const RECOGNIZE_FILE_REQUEST_SUCCESS = "RECOGNIZE_FILE_REQUEST_SUCCESS";
export const RECOGNIZE_FILE_REQUEST_FAIL = "RECOGNIZE_FILE_REQUEST_FAIL";

export const CLEAR_RECOGNITION_RESULTS = "CLEAR_RECOGNITION_RESULTS";
export const EDIT_RECOGNITION_RESULTS = "EDIT_RECOGNITION_RESULTS";
export const DELETE_RECOGNITION_RESULTS_AREA = "DELETE_RECOGNITION_RESULTS_AREA";
export const SET_FILE_TO_RECOGNIZE = "SET_FILE_TO_RECOGNIZE";

export const DOCUMENTS_REQUEST = "DOCUMENTS_REQUEST";
export const DOCUMENTS_REQUEST_SUCCESS = "DOCUMENTS_REQUEST_SUCCESS";
export const DOCUMENTS_REQUEST_FAIL = "DOCUMENTS_REQUEST_FAIL";

export const DOCUMENT_REQUEST = "DOCUMENT_REQUEST";
export const DOCUMENT_REQUEST_SUCCESS = "DOCUMENT_REQUEST_SUCCESS";
export const DOCUMENT_REQUEST_FAIL = "DOCUMENT_REQUEST_FAIL";

export const R_HIGHLIGHT_AREA = "R_HIGHLIGHT_AREA";
export const R_CLEAR_ALL_HIGHLIGHTED_AREAS = "R_CLEAR_ALL_HIGHLIGHTED_AREAS";

export const GET_RECOGNITION_RESULTS_FROM_LOCAL_STORAGE =
  "GET_RECOGNITION_RESULTS_FROM_LOCAL_STORAGE";
//authorization
export const GET_USER_DATA_REQUEST = "GET_USER_DATA_REQUEST";
export const GET_USER_DATA_REQUEST_SUCCESS = "GET_USER_DATA_REQUEST_SUCCESS";
export const GET_USER_DATA_REQUEST_FAIL = "GET_USER_DATA_REQUEST_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_BY_LOCALSTORAGE = "USER_LOGIN_BY_LOCALSTORAGE";
export const USER_TOKEN_IS_EXPIRED = "USER_TOKEN_IS_EXPIRED";

//actions for template group
export const SET_TEMPLATE_GROUP = "SET_TEMPLATE_GROUP";
export const GROUP_REQUEST = "GROUP_REQUEST";
export const GROUP_REQUEST_SUCCESS = "GROUP_REQUEST_SUCCESS";
export const GROUP_REQUEST_FAIL = "GROUP_REQUEST_FAIL";
