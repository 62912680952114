import React, {useState, useRef, useEffect} from "react";
import "./CategoryPanel.scss";
import {useTranslation} from "react-i18next";
// import {ReactComponent as DoubleForwardArrow} from "../../assets/DoubleForwardArrow.svg";
import DropMenu from "./DropMenu";
import ToogleView from "./ToogleView";
import ButtonField from "./ButtonField";
import GroupField from "./GroupField";

const CategoryPanel = ({
  view,
  activeGroup,
  groups,
  onSetActiveGroup: setActiveGroup,
  onSetActiveGroupID: setActiveGroupID,
  onViewChoose: viewChooseHandler,
  onLoadAllData: loadAllData,
  onLoadDataByGroup: loadDataByGroup,
}) => {
  const {t} = useTranslation();

  const [isShowDropView, setIsShowDropView] = useState(false);
  const [isDisabledPrevButton, setIsDisabledPrevButton] = useState(true);
  const [isDisabledNextButton, setIsDisabledNextButton] = useState(false);

  const scrollMenuRef = useRef();

  const clickGroupHandler = (group) => {
    setActiveGroup(group.name);
    setActiveGroupID(group.id);
    if (group.name === "All-Templates") {
      loadAllData();
    } else {
      loadDataByGroup(group);
    }
  };

  const toogleDropView = () => {
    setIsShowDropView((isShowDropView) => !isShowDropView);
  };

  const clickNextButton = () => {
    scrollMenuRef.current.scrollLeft += scrollMenuRef.current.clientWidth;
  };

  const clickPrevtButton = () => {
    scrollMenuRef.current.scrollLeft -= scrollMenuRef.current.clientWidth;
  };

  useEffect(() => {
    //дисейблим кнопки вперед и назад, если нужно
    const check = () => {
      if (scrollMenuRef.current.scrollLeft === 0) {
        setIsDisabledPrevButton(true);
      } else {
        setIsDisabledPrevButton(false);
      }
      if (
        scrollMenuRef.current.clientWidth + scrollMenuRef.current.scrollLeft >=
        scrollMenuRef.current.scrollWidth
      ) {
        setIsDisabledNextButton(true);
      } else {
        setIsDisabledNextButton(false);
      }
    };

    const elem = scrollMenuRef.current;

    elem.addEventListener("scroll", check);

    return function cleanUp() {
      elem.removeEventListener("scroll", check);
    };
  }, []);

  return (
    <div className="category-panel">
      <div className="category-panel__upper-part d-flex justify-content-between align-items-center pr-3 pl-3 pt-2 pb-2">
        <div ref={scrollMenuRef} className="category-panel__scroll-menu">
          <p
            onClick={() => clickGroupHandler({name: "All-Templates"})}
            className={
              "mr-5 mb-0 cursor-pointer category-name predefined " +
              (activeGroup === "All-Templates" ? "category-name_selected" : "")
            }
          >
            {t("category-panel.all-templates").toLocaleUpperCase()}
          </p>
          <p
            onClick={() => clickGroupHandler({name: null})}
            className={
              "mr-5 mb-0 cursor-pointer category-name predefined " +
              (activeGroup === null ? "category-name_selected" : "")
            }
          >
            {t("category-panel.no-group").toLocaleUpperCase()}
          </p>
          {groups.map((group) => (
            <GroupField
              key={group.name}
              group={group}
              className={
                "mr-5 mb-0 cursor-pointer category-name " +
                (activeGroup === group.name ? "category-name_selected" : "")
              }
              onClick={() => clickGroupHandler(group)}
            />
          ))}
        </div>
        <ButtonField
          className="d-flex align-items-center category-panel__buttons-field"
          isShowDropView={isShowDropView}
          isDisabledPrev={isDisabledPrevButton}
          isDisabledNext={isDisabledNextButton}
          onClickNext={clickNextButton}
          onClickPrev={clickPrevtButton}
          onToogleDropView={toogleDropView}
        />
        <ToogleView
          className="d-flex flex-nowrap category-panel__toogle-view-component"
          view={view}
          onChooseView={viewChooseHandler}
        />
      </div>
      {isShowDropView ? (
        <DropMenu
          className={"category-panel__drop-menu p-3"}
          groups={groups}
          activeGroup={activeGroup}
          onGroupClick={clickGroupHandler}
        />
      ) : null}
    </div>
  );
};

export default CategoryPanel;
