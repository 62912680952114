import React, {useRef} from "react";
import {Label} from "reactstrap";
import {checkFile} from "../../utils";
import "./CreateNewTplButton.scss";

const CreateNewTplButton = React.forwardRef(
  (
    {
      className,
      buttonText,
      hidden,
      onAnchorFile: anchorFileHandler,
      onFileIsLoading: setIsFileLoading,
    },
    ref
  ) => {
    const docImageInputRef = useRef(null);

    const anchorFile = () => {
      setIsFileLoading(true);
      let input = docImageInputRef.current;

      if (input && input.files && input.files[0]) {
        anchorFileHandler(input.files);
        input.value = "";
        setTimeout(() => setIsFileLoading(false), 500);
      }
    };

    return (
      <div ref={ref} hidden={hidden} className="create-template-component__container">
        <Label className="cursor-pointer">
          <span
            className={`${className} p-2 pr-3 pl-3 create-template-component__button`}
            size="sm"
          >
            {buttonText}
          </span>
          <input
            className="create-template-component__input"
            ref={docImageInputRef}
            type="file"
            accept="image/*, application/pdf"
            name="createTmpl"
            id="createTmpl"
            onChange={async (e) => {
              try {
                await checkFile(e.target.files[0]);
                anchorFile();
              } catch (err) {
                alert(err);
              }
            }}
          />
        </Label>
      </div>
    );
  }
);

export default CreateNewTplButton;
