import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  saveRecognitionResults,
  getDocumentByID,
} from "../../redux/feature/recognitionV2/actions";
import {
  highlightArea,
  clearAllHighlightedAreas,
  editRecognitionResults,
  deleteRecognitionResultsArea,
  setWarningModalInfo,
  returnOneActionBack,
  returnOneActionForward,
  setIsDocumentWereEdit,
} from "../../redux/feature/recognitionV2";
import {logout} from "../../redux/feature/authV2/actions";
import {useTranslation} from "react-i18next";
import {Col, Row, Spinner, Button} from "reactstrap";
import {Container} from "reactstrap";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router-dom";
import RecognitionTextAreasField from "../../components/RecognitionTextAreasField/RecognitionTextAreasField";
import RecognitionCanvas from "../../components/RecognitionCanvas/RecognitionCanvas";
import {
  useOnClickOutsideCanvasAreas,
  useOnClickOutsideDocumentAreas,
} from "../../hooks/useOnClickOutside";
import {convertDataForCSVLink, capitalizeFirstLetter} from "../../utils";
import "./Recognize.scss";
import ExportFileButton from "../../components/Buttons/ExportFileButton/ExportFileButton";
import ActionsManagePanel from "../../components/TemplateEditor/ActionsManagePanel";
import WarningModal from "../../components/WarningModal/WarningModal";
import RecognizeHeader from "../../components/DefaultLayout/RecognizeHeader/RecognizeHeader";
import usePrevious from "../../hooks/usePrevious";
import RefWrapper from "../../components/RefWrapper";
import NonDesktopHeader from "../../components/DefaultLayout/NonDesktopHeader";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import {truncateString} from "../../utils/index";

const RecognitionResults = () => {
  const history = useHistory();
  const {t} = useTranslation();

  const {docID} = useParams();
  const dispatch = useDispatch();
  const isNonDesktop = useMediaQuery();

  const stageRef = useRef();

  const recognitionResults = useSelector(
    (state) => state.recognitionV2.recognitionResultsCurrentData.data
  );
  const initialRecognitionResults = useSelector(
    (state) => state.recognitionV2.recognitionResultsInitialData.data
  );
  const highlightAreas = useSelector((state) => state.recognitionV2.highlightedAreas);
  const file = useSelector((state) => state.recognitionV2.file);
  const warningModalInfo = useSelector((state) => state.recognitionV2.warningModalInfo);
  const isDocumentWereEdit = useSelector(
    (state) => state.recognitionV2.isDocumentWereEdit
  );
  const backlog = useSelector((state) => state.recognitionV2.dataBackLog);
  const isLoading = useSelector(
    (state) => state.recognitionV2.recognitionResultsCurrentData.isLoading
  );

  const saveResultsHandler = () => {
    dispatch(saveRecognitionResults(recognitionResults)).then(() =>
      history.push("/recognition/results")
    );
  };

  const editResultsHandler = (regionID, value) => {
    dispatch(editRecognitionResults({regionID, value}));
  };

  const deleteRecognitionResultsAreaHandler = (regionID) => {
    dispatch(deleteRecognitionResultsArea(regionID));
    dispatch(clearAllHighlightedAreas());
  };

  const highlightAreaHandler = (areaID, type) => {
    dispatch(highlightArea({areaID, type}));
  };

  const cancelAllActions = () => {
    dispatch(
      setWarningModalInfo({isOpen: true, whereToGoAfterAccept: "/recognition/results"})
    );
  };

  const acceptCancelAllActions = () => {
    const leaveSystem = () => {
      dispatch(logout()).then((data) => {
        if (data.error) {
          alert(data.payload);
        }
      });
    };

    if (warningModalInfo.whereToGoAfterAccept === "logout") {
      dispatch(saveRecognitionResults(initialRecognitionResults)).then((data) => {
        if (!data.error) {
          leaveSystem();
        } else {
          alert("Something is going wrong. Try later.");
        }
      });
    } else {
      dispatch(saveRecognitionResults(initialRecognitionResults)).then((data) => {
        if (data.error) {
          alert("Something is going wrong. Try later.");
          return;
        }
        history.push(warningModalInfo.whereToGoAfterAccept);
      });
    }
  };

  useOnClickOutsideDocumentAreas("data-area", () => {
    dispatch(clearAllHighlightedAreas());
  });
  useOnClickOutsideCanvasAreas(stageRef.current, () => {
    dispatch(clearAllHighlightedAreas());
  });

  useEffect(() => {
    dispatch(getDocumentByID(docID));
  }, [docID, dispatch]);

  useEffect(() => {
    dispatch(setWarningModalInfo({isOpen: false, whereToGoAfterAccept: ""}));
    dispatch(setIsDocumentWereEdit(false));
  }, [dispatch]);

  useEffect(() => {
    //show warning when want to reload or leave page
    const innerFunc = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", innerFunc);

    return function cleanUp() {
      window.removeEventListener("beforeunload", innerFunc);
    };
  }, []);

  //for autosaving - only if backlog changed. It means that user made some changes.
  const prevBackLog = usePrevious(backlog);
  useEffect(() => {
    if (prevBackLog && backlog !== prevBackLog) {
      dispatch(saveRecognitionResults(recognitionResults));
    }
  }, [backlog, dispatch, prevBackLog, recognitionResults]);

  return (
    <div>
      {isNonDesktop ? (
        <NonDesktopHeader linkTitle={t("header.recognition")} />
      ) : (
        <RecognizeHeader />
      )}
      {isLoading ? (
        <div className="spinner__container">
          <Spinner />
        </div>
      ) : (
        <Container fluid>
          <RefWrapper className="ref-wrapper">
            <Row id="main-row" className={"main-row"} noGutters>
              <Col xs={12} className={"main-row__left-column"}>
                <RefWrapper className={"wrapper__container"}>
                  <div className={"left-column__upper-div"}>
                    <span className={"upper-div__label"}>
                      {truncateString(recognitionResults?.template?.name || "", 15)}
                    </span>
                  </div>
                  <div className={"left-column__file-name"}>
                    <p className={file && "p-3 m-0"}>{file?.name || ""}</p>
                    {recognitionResults?.texts && (
                      <div className={`cursor-pointer ${isNonDesktop ? "" : "p-3"}`}>
                        <ExportFileButton
                          data={convertDataForCSVLink(recognitionResults?.texts || [])}
                        />
                      </div>
                    )}
                  </div>
                  <RecognitionTextAreasField
                    recognitionResults={recognitionResults}
                    highlightAreas={highlightAreas}
                    onEditResults={editResultsHandler}
                    onHighlightArea={highlightAreaHandler}
                  />
                  <div className={"main-row__buttons-container p-3"}>
                    <Button
                      size="sm"
                      className="main-row__cancel-all-actions-button"
                      color="link"
                      disabled={!isDocumentWereEdit}
                      onClick={cancelAllActions}
                    >
                      {t("edit-box.cancel-all-changes").toLocaleUpperCase()}
                    </Button>
                    <RefWrapper className={"main-row__save-button"}>
                      <Button size="sm" color="primary" onClick={saveResultsHandler}>
                        {t("recognition-edit-box.save-results").toUpperCase()}
                      </Button>
                    </RefWrapper>
                  </div>
                </RefWrapper>
              </Col>
              <Col xs={12} className="main-row__right-column">
                <ActionsManagePanel
                  className="recognition-manage-actions-panel"
                  onReturnOneActionBack={() => dispatch(returnOneActionBack())}
                  onReturnOneActionForward={() => dispatch(returnOneActionForward())}
                />
                <RecognitionCanvas
                  ref={stageRef}
                  imageUrl={recognitionResults?.image_url}
                  areas={recognitionResults?.texts}
                  highlightAreas={highlightAreas}
                  onHighlightArea={highlightAreaHandler}
                  onDeleteResultsArea={deleteRecognitionResultsAreaHandler}
                />
              </Col>
            </Row>
          </RefWrapper>
          <WarningModal
            isOpen={warningModalInfo.isOpen}
            headerText={capitalizeFirstLetter(t("recognize.warning-modal-title"))}
            bodyText={capitalizeFirstLetter(t("recognize.warning-modal-text"))}
            leftButtonText={t("recognize.warning-left-button-text").toLocaleUpperCase()}
            rightButtonText={t("recognize.warning-right-button-text").toLocaleUpperCase()}
            onClickCancel={() =>
              dispatch(setWarningModalInfo({isOpen: false, whereToGoAfterAccept: ""}))
            }
            onClickAccept={acceptCancelAllActions}
          />
        </Container>
      )}
    </div>
  );
};

export default RecognitionResults;
