import React from "react";
import Processing from "../../pages/Recognition/RecognitionResultList/Processing";
import {CloseIcon} from "../../components/Icons";
import ExportFileButton from "../../components/Buttons/ExportFileButton/ExportFileButton";
import {
  convertDataForCSVLink,
  getTimeFromDateTime,
  getDateFromDateTime,
} from "../../utils";
import {MainColors} from "../../constants/CommonProperties";
import {truncateString} from "../../utils/index";
import "./DocumentCard.scss";

const DocumentCard = ({
  document,
  className,
  style,
  onSetFailedAIRecognitions: setFailedAIRecognitions,
  onDeleteDocument: deleteTemplate,
  onShowDocument: showDocument,
}) => {
  const showDocumentHandler = (e) => {
    console.dir(e.target.nodeName);
    if (
      e.target.nodeName === "path" ||
      e.target.nodeName === "svg" ||
      e.target.nodeName === "A"
    ) {
      return;
    }
    showDocument(document);
  };

  return (
    <div
      style={style}
      className={`${className || ""} document-card`}
      onClick={showDocumentHandler}
    >
      <div className="document-card__data">
        <div>
          <span>{getTimeFromDateTime(document.datetime)}</span>
          <span>{getDateFromDateTime(document.datetime)}</span>
        </div>
        {document.status === "success" ? (
          <div id="document-card-actions" style={{maxWidth: "60px"}}>
            <ExportFileButton
              iconWidth="13px"
              iconHeight="13px"
              data={convertDataForCSVLink(document?.texts || [])}
            />
            <CloseIcon
              style={{marginTop: "2.5px"}}
              onClick={() => deleteTemplate(document)}
              width="9.5px"
              height="9.5px"
              fill={MainColors.greyPalette.grey5}
            />
          </div>
        ) : (
          <Processing
            status={document.status}
            documentID={document.id}
            onSetFailedAIRecognitions={setFailedAIRecognitions}
          />
        )}
      </div>
      <div className="document-card__name">
        {truncateString(document?.filename || "", 30)}
      </div>
    </div>
  );
};

export default DocumentCard;
