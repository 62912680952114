import React from "react";
import {VisibilityIcon, PencilIcon, CloseIcon} from "../Icons";
import {Table} from "reactstrap";
import "./ListView.scss";
import {MainColors} from "../../constants/CommonProperties";
import TemplatesEditingPopUp from "../TemplatesEditingPopUp/TemplatesEditingPopUp";
import {
  capitalizeFirstLetter,
  getDateFromDateTime,
  getTimeFromDateTime,
  truncateString,
} from "../../utils";
import {useTranslation} from "react-i18next";

const ListView = React.forwardRef(
  (
    {
      templatesList,
      groups,
      templateListWithCheck,
      onGetGroups: getGroups,
      onChangeCheckBoxStatus: checkBoxChangeHandler,
      onEditTemplate: editTemplateHandler,
      onShowTemplateImage: showTemplateImageHandler,
      onDeleteTemplate: deleteTemplateHandler,
      onToggleAllCheckBoxHandler: toggleAllCheckBoxHandler,
      onDeleteByEditingPopUp: deleteByEditingPopUp,
      onDuplicateByEditingPopUp: duplicateByEditingPopUp,
      onRecognizeById: recognizeByID,
      onMoveTemplatesToCertainGroup: moveTemplatesToCertainGroup,
      onCreateNewGroup: createNewGroup,
      onDeleteGroup: deleteGroup,
      onEditGroup: editGroup,
    },
    ref
  ) => {
    const {t} = useTranslation();

    const checkAllCheckBoxIsTrue = () => {
      for (let item of templateListWithCheck) {
        if (!item.checked) return false;
      }
      return true;
    };

    const checkListHasCheckedBox = () => {
      for (let item of templateListWithCheck) {
        if (item.checked) return true;
      }
      return false;
    };

    const checkListHasCheckedBoxWithId = (id) => {
      for (let item of templateListWithCheck) {
        if (item.checked && item.id === id) return true;
      }
      return false;
    };

    const generateHeaders = () => [
      "",
      "list-view.template-name",
      "list-view.group",
      "list-view.time",
      "list-view.date",
      "list-view.actions",
    ];

    return (
      <div className="list-view">
        <TemplatesEditingPopUp
          someData={groups}
          isShow={checkListHasCheckedBox()}
          onGetGroups={getGroups}
          onDeleteTemplates={deleteByEditingPopUp}
          onDuplicateTemplates={duplicateByEditingPopUp}
          onMoveTemplatesToCertainGroup={moveTemplatesToCertainGroup}
          onCreateNewGroup={createNewGroup}
          onDeleteGroup={deleteGroup}
          onEditGroup={editGroup}
        />
        <Table striped borderless className="position-relative">
          <thead>
            <tr className="position-relative">
              {generateHeaders().map((header, index) => {
                if (index === 0)
                  return (
                    <th key={header}>
                      <label
                        className={
                          checkAllCheckBoxIsTrue()
                            ? "checkbox-container main active"
                            : "checkbox-container main intermediate"
                        }
                        style={{bottom: "12px"}}
                      >
                        <input
                          checked={checkListHasCheckedBox()}
                          onChange={() => toggleAllCheckBoxHandler()}
                          type="checkbox"
                        />
                        <span className="checkmark" style={{zIndex: 2}} />
                      </label>
                    </th>
                  );
                return (
                  <th key={header} className="metadata-bold-2">
                    {capitalizeFirstLetter(t(header))}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {templatesList.map((template, index) => (
              <tr
                onClick={(e) => {
                  if (
                    e.target.tagName === "TD" ||
                    e.target.classList.contains("no-active")
                  ) {
                    editTemplateHandler(template);
                  }
                }}
                key={template.id}
                className={
                  "list-view__row " +
                  (checkListHasCheckedBoxWithId(template.id)
                    ? "list-view__row_selected"
                    : "")
                }
              >
                <td>
                  <label className="checkbox-container">
                    <input
                      onChange={(e) => {
                        checkBoxChangeHandler(e, template);
                      }}
                      type="checkbox"
                      checked={checkListHasCheckedBoxWithId(template.id)}
                    />
                    <span className="checkmark" />
                  </label>
                </td>
                <td>
                  <span
                    className="body-regular no-active"
                    onMouseOver={() => showTemplateImageHandler(template)}
                    onMouseOut={() => showTemplateImageHandler(null)}
                  >
                    {truncateString(template.name || "", 10)}
                  </span>
                </td>
                <td>
                  <span id="group" className="no-active">
                    {truncateString(template.group?.name.toUpperCase() || "", 10) ||
                      t("dropdown.no-group").toLocaleUpperCase()}
                  </span>
                </td>
                <td>
                  <span className="no-active">
                    {getTimeFromDateTime(template.last_modified)}
                  </span>
                </td>
                <td>
                  <span className="no-active">
                    {getDateFromDateTime(template.last_modified)}
                  </span>
                </td>
                <td>
                  <div ref={index === 0 ? ref : null} style={{maxWidth: "85px"}}>
                    <VisibilityIcon
                      title={capitalizeFirstLetter(t("list-view-buttons.recognize"))}
                      onClick={() => recognizeByID(template.id)}
                      className="mr-3"
                      width="16px"
                      height="16px"
                      fill={MainColors.greyPalette.grey5}
                    />
                    <PencilIcon
                      title={capitalizeFirstLetter(t("list-view-buttons.edit"))}
                      onClick={() => editTemplateHandler(template)}
                      className="mr-3"
                      width="14px"
                      height="14px"
                      fill={MainColors.greyPalette.grey5}
                    />
                    <CloseIcon
                      title={capitalizeFirstLetter(t("list-view-buttons.delete"))}
                      onClick={() => deleteTemplateHandler(template)}
                      width="12px"
                      height="12px"
                      fill={MainColors.greyPalette.grey5}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
);

export default ListView;
