import React from "react";
import {Link} from "react-router-dom";
import {Navbar, Nav, NavItem, NavLink} from "reactstrap";
import {useSelector, useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import "../Header.scss";
import UserInfo from "../../UserInfo/UserInfo";
import {setWarningModalInfo} from "../../../redux/feature/recognitionV2";
import {ReactComponent as DocIcon} from "../../../assets/DocIcon.svg";

const RecognizeHeader = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.authV2.isLoggedIn);

  const {t} = useTranslation();

  const showWarningModal = (url) => {
    dispatch(setWarningModalInfo({isOpen: true, whereToGoAfterAccept: url}));
  };

  const changeActivePageHandler = (url) => {
    showWarningModal(url);
  };

  return (
    <Navbar
      light
      expand="md"
      className="pr-0 pt-3 pb-3 justify-content-between position-relative"
    >
      <div className={"header-navbar__container"}>
        <a href={process.env.REACT_APP_LANDING_URL}>
          <DocIcon />
        </a>
        <Nav>
          <NavItem>
            <NavLink tag={Link} onClick={() => changeActivePageHandler("/templates")}>
              {t("header.templates").toLocaleUpperCase()}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active
              tag={Link}
              onClick={() => changeActivePageHandler("/recognition")}
            >
              {t("header.recognition").toLocaleUpperCase()}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      {isLoggedIn && (
        <div className={"header-navbar__container"}>
          <UserInfo onShowWarningBeforeLogout={showWarningModal} />
        </div>
      )}
    </Navbar>
  );
};

export default RecognizeHeader;
