import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchWithToken} from "../../../utils";

const tokenFetch = fetchWithToken(fetch);

const REQUEST_LIMIT = 8;

export const deleteDocument = createAsyncThunk(
  "documents/deleteDocument",
  async (arg) => {
    try {
      const response = await tokenFetch(`/api/v1/documents/${arg}/`, {
        method: "DELETE",
      });

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        Promise.reject("Something is going wrong. Try later");
      }
      return null;
    } catch (error) {
      throw error;
    }
  }
);

export const getDocuments = createAsyncThunk(
  "documents/fetchDocuments",
  async (arg, thunkAPI) => {
    try {
      const page = arg?.page || 0;
      const limit = arg?.limit || REQUEST_LIMIT;
      const response = await tokenFetch(
        `/api/v1/documents/?limit=${limit}&offset=${limit * page}`
      );

      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return thunkAPI.rejectWithValue(data);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getDocumentByID = createAsyncThunk(
  "documents/fetchDocumentByID",
  async (arg, thunkAPI) => {
    try {
      const response = await tokenFetch(`/api/v1/documents/${arg}/`);

      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return thunkAPI.rejectWithValue(data);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const saveRecognitionResults = createAsyncThunk(
  "documents/saveRecognitionResults",
  async (arg, thunkAPI) => {
    try {
      const response = await tokenFetch(`/api/v1/documents/${arg.id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({texts: arg.texts}),
      });
      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return thunkAPI.rejectWithValue(data);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const recognize = createAsyncThunk(
  "documents/recognize",
  async (arg, thunkAPI) => {
    try {
      arg.formData.append("datetime", new Date());

      const recognizeResourceUrl = `/api/v1/documents/recognize/${
        arg.templateID ? arg.templateID + "/" : ""
      }`;

      const response = await tokenFetch(recognizeResourceUrl, {
        method: "POST",
        body: arg.formData,
      });

      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return thunkAPI.rejectWithValue(data);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const recognizeAsync = createAsyncThunk(
  "documents/recognizeAsync",
  async (arg, thunkAPI) => {
    try {
      const formData = arg;
      formData.append("datetime", new Date());

      const response = await tokenFetch(
        `/api/v1/documents/recognize-async/?mode=with_ai`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return thunkAPI.rejectWithValue(data);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);
