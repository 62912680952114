import {configureStore} from "@reduxjs/toolkit";
import authV2Reducer from "./feature/authV2";
import templatesV2Reducer from "./feature/templatesV2";
import groupsV2Reducer from "./feature/groupsV2";
import docTemplateEditorV2Reducer from "./feature/docTemplateEditorV2";
import recognitionV2Reducer from "./feature/recognitionV2";
import fileUploadFieldReducer from "./feature/fileUploadField";

import authReducer from "../store/reducers/auth";
import docTemplateEditorReducer from "../store/reducers/docTemplateEditor";
import docTemplatesReducer from "../store/reducers/docTemplates";
import groupReducer from "../store/reducers/group";
import recognitionReducer from "../store/reducers/recognition";
import debounce from "../utils/debounce";

const addToBacklogWithDebounceTemplateEditor = debounce((dispatch) => {
  dispatch({type: "docTemplateEditorV2/addToBacklogWithDebounce"});
}, 1000);

const addToBacklogWithDebounceRecognize = debounce((dispatch) => {
  dispatch({type: "recognitionV2/addToBacklogWithDebounce"});
}, 1000);

const debouncedBackLogMiddleware = (storeAPI) => (next) => (action) => {
  if (
    action.type === "docTemplateEditorV2/resizeArea" ||
    action.type === "docTemplateEditorV2/setDocumentName"
  ) {
    addToBacklogWithDebounceTemplateEditor(storeAPI.dispatch);
    next(action);
    return;
  }
  if (action.type === "recognitionV2/editRecognitionResults") {
    addToBacklogWithDebounceRecognize(storeAPI.dispatch);
    next(action);
    return;
  }

  return next(action);
};

export default configureStore({
  reducer: {
    authV2: authV2Reducer,
    templatesV2: templatesV2Reducer,
    groupsV2: groupsV2Reducer,
    docTemplateEditorV2: docTemplateEditorV2Reducer,
    recognitionV2: recognitionV2Reducer,
    fileUploadField: fileUploadFieldReducer,

    docTemplates: docTemplatesReducer,
    docTemplateEditor: docTemplateEditorReducer,
    auth: authReducer,
    group: groupReducer,
    recognition: recognitionReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(debouncedBackLogMiddleware),
});
