import {createSlice} from "@reduxjs/toolkit";
import {getDocuments, getDocumentByID, recognize} from "./actions";

export const recognitionV2 = createSlice({
  name: "recognitionV2",
  initialState: {
    file: null,
    isDocumentWereEdit: false,
    documents: {
      isLoading: false,
      errorText: null,
      data: [],
      count: null,
      page: 0,
      limit: 0,
    },
    recognitionResultsInitialData: {
      isLoading: false,
      errorText: null,
      data: null,
    },
    recognitionResultsCurrentData: {
      isLoading: false,
      errorText: null,
      data: null,
    },
    highlightedAreas: {
      hovered: null,
      selected: null,
    },
    warningModalInfo: {
      isOpen: false,
      whereToGoAfterAccept: "",
    },
    dataBackLog: [],
    canceledActions: [],
    isUndoRedoIsActive: false,
  },
  reducers: {
    setFileToRecognize: (state, action) => {
      state.file = action.payload;
    },
    addToBacklogWithDebounce: (state) => {
      //backlog logic-------------------------------------
      if (state.isUndoRedoIsActive) {
        state.canceledActions = [];
        const lastAction = state.dataBackLog.pop();
        state.dataBackLog = [];
        state.dataBackLog.push(lastAction);
        state.dataBackLog.push(state.recognitionResultsCurrentData);
        state.isUndoRedoIsActive = false;
      } else {
        state.dataBackLog.push(state.recognitionResultsCurrentData);
      }
      state.isDocumentWereEdit = true;
      //---------------------------------------------------
    },
    setIsDocumentWereEdit: (state, action) => {
      state.isDocumentWereEdit = action.payload;
    },
    highlightArea: (state, action) => {
      if (action.payload.type === "hover") {
        state.highlightedAreas.hovered = action.payload.areaID;
      }
      if (action.payload.type === "click") {
        state.highlightedAreas.selected = action.payload.areaID;
      }
    },
    setWarningModalInfo: (state, action) => {
      state.warningModalInfo.isOpen = action.payload.isOpen;
      state.warningModalInfo.whereToGoAfterAccept = action.payload.whereToGoAfterAccept;
    },
    clearAllHighlightedAreas: (state) => {
      state.highlightedAreas.hovered = null;
      state.highlightedAreas.selected = null;
    },
    editRecognitionResults: (state, action) => {
      const updatedTextList = state.recognitionResultsCurrentData.data.texts.map(
        (item) => {
          if (item.cid === action.payload.regionID) item.text = action.payload.value;
          return item;
        }
      );
      state.recognitionResultsCurrentData.data.texts = updatedTextList;
    },
    deleteRecognitionResultsArea: (state, action) => {
      state.recognitionResultsCurrentData.data.texts = state.recognitionResultsCurrentData.data.texts.filter(
        (item) => item.cid !== action.payload
      );
      //backlog logic-------------------------------------
      if (state.isUndoRedoIsActive) {
        state.canceledActions = [];
        const lastAction = state.dataBackLog.pop();
        state.dataBackLog = [];
        state.dataBackLog.push(lastAction);
        state.dataBackLog.push(state.recognitionResultsCurrentData);
        state.isUndoRedoIsActive = false;
      } else {
        state.dataBackLog.push(state.recognitionResultsCurrentData);
      }
      state.isDocumentWereEdit = true;
      //---------------------------------------------------
    },
    returnOneActionBack: (state) => {
      if (state.dataBackLog.length > 1) {
        state.isUndoRedoIsActive = true;

        const canceledAction = state.dataBackLog.pop();
        state.canceledActions.push(canceledAction);

        state.recognitionResultsCurrentData =
          state.dataBackLog[state.dataBackLog.length - 1];
      }
    },
    returnOneActionForward: (state) => {
      if (state.canceledActions.length) {
        state.isUndoRedoIsActive = true;

        const lastCanceledAction = state.canceledActions.pop();
        state.recognitionResultsCurrentData = lastCanceledAction;
        state.dataBackLog.push(lastCanceledAction);
      }
    },
  },
  extraReducers: {
    [getDocuments.pending]: (state) => {
      state.documents.isLoading = true;
      state.documents.errorText = null;
    },
    [getDocuments.fulfilled]: (state, action) => {
      state.documents.isLoading = false;
      state.documents.data = action.payload.results;
      state.documents.count = action.payload.count;
      state.documents.page = action.meta.arg?.page;
      state.documents.limit = action.meta.arg?.limit;
    },
    [getDocuments.rejected]: (state, action) => {
      state.documents.isLoading = false;
      state.documents.errorText = action.error.message;
    },
    [getDocumentByID.pending]: (state) => {
      state.recognitionResultsInitialData.isLoading = true;
      state.recognitionResultsInitialData.errorText = null;
      state.recognitionResultsCurrentData.isLoading = true;
      state.recognitionResultsCurrentData.errorText = null;
    },
    [getDocumentByID.fulfilled]: (state, action) => {
      //initialData
      state.recognitionResultsInitialData.isLoading = false;
      state.recognitionResultsInitialData.data = action.payload;
      state.recognitionResultsInitialData.data.texts = state.recognitionResultsInitialData.data.texts.map(
        (area, index) => {
          const newArea = Object.assign({}, area);
          newArea.cid = `area${index}`;
          return newArea;
        }
      );
      //currentData
      state.recognitionResultsCurrentData.isLoading = false;
      state.recognitionResultsCurrentData.data = action.payload;
      state.recognitionResultsCurrentData.data.texts = state.recognitionResultsCurrentData.data.texts.map(
        (area, index) => {
          const newArea = Object.assign({}, area);
          newArea.cid = `area${index}`;
          return newArea;
        }
      );
      //backlog
      state.dataBackLog = [];
      state.canceledActions = [];
      state.dataBackLog.push(state.recognitionResultsCurrentData);
    },
    [getDocumentByID.rejected]: (state, action) => {
      state.recognitionResultsInitialData.isLoading = false;
      state.recognitionResultsInitialData.errorText = action.error.message;
      state.recognitionResultsCurrentData.isLoading = false;
      state.recognitionResultsCurrentData.errorText = action.error.message;
    },
    [recognize.pending]: (state) => {
      state.recognitionResultsInitialData.isLoading = true;
      state.recognitionResultsInitialData.errorText = null;
      state.recognitionResultsCurrentData.isLoading = true;
      state.recognitionResultsCurrentData.errorText = null;
    },
    [recognize.fulfilled]: (state, action) => {
      //for changing template from dropdown in recognize page
      const isTemplateWasChanged = action.meta.arg.isTemplateWasChanged;
      if (isTemplateWasChanged) {
        //currentData
        state.recognitionResultsCurrentData.isLoading = false;
        state.recognitionResultsCurrentData.data = action.payload;
        state.recognitionResultsCurrentData.data.texts = state.recognitionResultsCurrentData.data.texts.map(
          (area, index) => {
            const newArea = Object.assign({}, area);
            newArea.cid = `area${index}`;
            return newArea;
          }
        );
        //backlog logic-------------------------------------
        if (state.isUndoRedoIsActive) {
          state.canceledActions = [];
          const lastAction = state.dataBackLog.pop();
          state.dataBackLog = [];
          state.dataBackLog.push(lastAction);
          state.dataBackLog.push(state.recognitionResultsCurrentData);
          state.isUndoRedoIsActive = false;
        } else {
          state.dataBackLog.push(state.recognitionResultsCurrentData);
        }
        state.isDocumentWereEdit = true;
        //---------------------------------------------------
        return;
      }
      //initialData
      state.recognitionResultsInitialData.isLoading = false;
      state.recognitionResultsInitialData.data = action.payload;
      state.recognitionResultsInitialData.data.texts = state.recognitionResultsInitialData.data.texts.map(
        (area, index) => {
          const newArea = Object.assign({}, area);
          newArea.cid = `area${index}`;
          return newArea;
        }
      );
      //currentData
      state.recognitionResultsCurrentData.isLoading = false;
      state.recognitionResultsCurrentData.data = action.payload;
      state.recognitionResultsCurrentData.data.texts = state.recognitionResultsCurrentData.data.texts.map(
        (area, index) => {
          const newArea = Object.assign({}, area);
          newArea.cid = `area${index}`;
          return newArea;
        }
      );
      //backlog
      state.dataBackLog = [];
      state.canceledActions = [];
      state.dataBackLog.push(state.recognitionResultsCurrentData);
    },
    [recognize.rejected]: (state, action) => {
      state.recognitionResultsInitialData.isLoading = false;
      state.recognitionResultsInitialData.errorText = action.error.message;
      state.recognitionResultsCurrentData.isLoading = false;
      state.recognitionResultsCurrentData.errorText = action.error.message;
    },
  },
});

export const {
  setFileToRecognize,
  highlightArea,
  clearAllHighlightedAreas,
  editRecognitionResults,
  deleteRecognitionResultsArea,
  setWarningModalInfo,
  returnOneActionBack,
  returnOneActionForward,
  setIsDocumentWereEdit,
} = recognitionV2.actions;

export default recognitionV2.reducer;
