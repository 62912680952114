import React from "react";
import ReactDOM from "react-dom";

import {YMInitializer} from "react-yandex-metrika";

// import {createStore, applyMiddleware, compose} from "redux";
import {Provider} from "react-redux";
// import thunk from "redux-thunk";
// import {loginUserByLocalStorage} from "./store/actions/auth";
import {loginUserByLocalStorage} from "./redux/feature/authV2/";

import "./scss/custom.scss";

import App from "./App";

// import rootReducer from "./store/reducers";

import store from "./redux/store";

import * as serviceWorker from "./serviceWorker";

window.store = store;

store.dispatch(loginUserByLocalStorage());

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
      <YMInitializer accounts={[68480866]} options={{webvisor: true}} version="2" />
    </Provider>,
    document.getElementById("root")
  );
};

// Do this once
serviceWorker.register();

// Render once
render();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./App", () => {
    render();
  });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
