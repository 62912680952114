import {createSlice} from "@reduxjs/toolkit";

export const fileUploadField = createSlice({
  name: "fileUploadField",
  initialState: {
    templates: {
      file: null,
      isPopUpOpen: false,
    },
    recognition: {
      file: null,
      isPopUpOpen: false,
    },
  },
  reducers: {
    setFileUploadPopUpFile: (state, action) => {
      if (action.payload.page === "templates") {
        state.templates.file = action.payload.file;
      }
      if (action.payload.page === "recognition") {
        state.recognition.file = action.payload.file;
      }
    },
    clearFileUploadPopUpFile: (state, action) => {
      if (action.payload.page === "templates") {
        state.templates.file = null;
      }
      if (action.payload.page === "recognition") {
        state.recognition.file = null;
      }
    },
    showPopUp: (state, action) => {
      if (action.payload.page === "templates") {
        state.templates.isPopUpOpen = action.payload.status;
        state.templates.file = null;
      }
      if (action.payload.page === "recognition") {
        state.recognition.isPopUpOpen = action.payload.status;
        state.recognition.file = null;
      }
    },
  },
});

export const {
  setFileUploadPopUpFile,
  clearFileUploadPopUpFile,
  showPopUp,
} = fileUploadField.actions;

export default fileUploadField.reducer;
