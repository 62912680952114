import React from "react";
import {CloseIcon, MoveToIcon} from "../Icons";
import {MainColors} from "../../constants/CommonProperties";
import CustomDropDown from "../CustomDropDown/CustomDropDown";
import {useTranslation} from "react-i18next";
import {capitalize, capitalizeFirstLetter} from "../../utils";
import "./TemplatesEditingPopUp.scss";
import {DuplicateIcon} from "../Icons";

const TemplatesEditingPopUp = ({
  isShow,
  someData,
  onGetGroups: getGroups,
  onDeleteTemplates: deleteTemplates,
  onDuplicateTemplates: duplicateTemplates,
  onMoveTemplatesToCertainGroup: moveTemplatesToCertainGroup,
  onCreateNewGroup: createNewGroup,
  onDeleteGroup: deleteGroup,
  onEditGroup: editGroup,
}) => {
  const {t} = useTranslation();

  return (
    isShow && (
      <div className={"edit-pop-up__container"}>
        <div className="ml-5 cursor-pointer" onClick={() => deleteTemplates()}>
          <CloseIcon
            className="mr-2 mb-1"
            width="12px"
            height="12px"
            fill={MainColors.greyPalette.grey6}
          />
          <span>{capitalize(t("template-pop-up.delete-all"))}</span>
        </div>
        <CustomDropDown
          data={someData}
          onGetGroups={getGroups}
          onSetGroup={moveTemplatesToCertainGroup}
          onCreateNewGroup={createNewGroup}
          onDeleteGroup={deleteGroup}
          onEditGroup={editGroup}
          component={
            <div className="ml-3 cursor-pointer pr-2">
              <MoveToIcon
                className="mr-2 mb-1"
                width="16px"
                height="16px"
                fill={MainColors.greyPalette.grey6}
              />
              <span>{capitalizeFirstLetter(t("template-pop-up.move-to"))}...</span>
            </div>
          }
        />
        <div className="ml-1 pr-3 cursor-pointer" onClick={() => duplicateTemplates()}>
          <DuplicateIcon
            className="mr-2 mb-1"
            width="16px"
            height="16px"
            fill={MainColors.greyPalette.grey6}
          />
          <span>{capitalizeFirstLetter(t("list-view-buttons.duplicate"))}</span>
        </div>
      </div>
    )
  );
};

export default TemplatesEditingPopUp;
