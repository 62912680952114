import React from "react";
import "./CloseIcon.scss";
import {useTranslation} from "react-i18next";

const CloseIcon = ({onClick: click}) => {
  const [t] = useTranslation();
  return (
    <div title={t("wizard.close-wizard")}>
      <svg
        className="wizard-close-icon"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={click}
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H32C34.2091 0 36 1.79086 36 4V32C36 34.2091 34.2091 36 32 36H4C1.79086 36 0 34.2091 0 32V4Z"
          fill="#323232"
          fill-opacity="0.5"
        />
        <path
          d="M27.4501 8.56499C26.8651 7.97999 25.9201 7.97999 25.3351 8.56499L18.0001 15.885L10.6651 8.54999C10.0801 7.96499 9.13508 7.96499 8.55008 8.54999C7.96508 9.13499 7.96508 10.08 8.55008 10.665L15.8851 18L8.55008 25.335C7.96508 25.92 7.96508 26.865 8.55008 27.45C9.13508 28.035 10.0801 28.035 10.6651 27.45L18.0001 20.115L25.3351 27.45C25.9201 28.035 26.8651 28.035 27.4501 27.45C28.0351 26.865 28.0351 25.92 27.4501 25.335L20.1151 18L27.4501 10.665C28.0201 10.095 28.0201 9.13499 27.4501 8.56499Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default CloseIcon;
