import React, {useState, useEffect} from "react";
import TextareaAutosize from "react-textarea-autosize";
import "./DblClickEditTextArea.scss";
import {useMediaQuery} from "../../hooks/useMediaQuery";

const DblClickEditTextArea = ({value, cid, onBlur: blur, onChange: change, ...props}) => {
  const [isEdit, setIsEdit] = useState(false);
  const isNonDesktop = useMediaQuery();

  const changeHandler = (event) => {
    change(cid, event.target.value);
  };

  const blurHandler = () => {
    blur();
    setIsEdit(false);
  };

  const focusedTextArea = document.getElementById("dblClickEditTextEditOn");
  useEffect(() => {
    if (isNonDesktop) {
      if (focusedTextArea) {
        focusedTextArea.scrollIntoView();
      }
    }
  }, [focusedTextArea, isNonDesktop]);

  return isEdit ? (
    <TextareaAutosize
      id="dblClickEditTextEditOn"
      autoFocus
      value={value}
      maxLength="50"
      onChange={changeHandler}
      onBlur={blurHandler}
      {...props}
    />
  ) : (
    <div id="dblClickEditTextEditOff" onDoubleClick={() => setIsEdit(true)}>
      {value}
    </div>
  );
};

export default DblClickEditTextArea;
