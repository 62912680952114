import React from "react";
import {Navbar, NavItem, Nav} from "reactstrap";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {ReactComponent as DocIcon} from "../../../assets/DocIcon.svg";
import UserInfo from "../../UserInfo/UserInfo";
import {ReactComponent as BackArrow} from "../../../assets/MobileHeader/BackArrow.svg";
import "./NonDesktopHeader.scss";

const NonDesktopHeader = ({linkTitle, backArrow}) => {
  const isLoggedIn = useSelector((state) => state.authV2.isLoggedIn);

  const history = useHistory();

  const returnBack = () => {
    history.goBack();
  };

  return (
    <Navbar light expand="lg" className="pt-3 pb-3 header-navbar">
      <div className={"header-navbar__container"}>
        <a href={process.env.REACT_APP_LANDING_URL}>
          <DocIcon />
        </a>
      </div>
      <Nav style={{alignItems: "center"}} onClick={backArrow && returnBack}>
        {backArrow && <BackArrow className="mr-2" />}
        <NavItem className="non-desktop-header-link">
          {linkTitle?.toLocaleUpperCase()}
        </NavItem>
      </Nav>
      {isLoggedIn && (
        <div className={"header-navbar__container"}>
          <UserInfo noWarning />
        </div>
      )}
    </Navbar>
  );
};

export default NonDesktopHeader;
