import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import "./LanguageSelector.scss";
import i18n from "i18next";
import {ReactComponent as ChevronIcon} from "../../../assets/Chevron.svg";
import {ReactComponent as TickIcon} from "../../../assets/Tick.svg";

const LanguageSelector = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const availableLocales = {
    en: "English",
    ru: "Русский",
  };

  const changeLocale = async (locale) => {
    await i18n.changeLanguage(locale);
    localStorage.setItem("docRecognizer.language", locale);
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const isLocaleSelected = (locale) => i18n.language === locale;

  return (
    <Dropdown
      direction={"down"}
      isOpen={dropdownOpen}
      toggle={toggle}
      className={"language-selector " + (dropdownOpen ? "language-selector_open" : "")}
    >
      <DropdownToggle className={"language-selector__container"}>
        <div className={"language-selector__info"}>{availableLocales[i18n.language]}</div>
        <ChevronIcon
          className={
            "language-selector__icon " +
            (dropdownOpen ? "language-selector__icon_open" : "")
          }
        />
      </DropdownToggle>
      <DropdownMenu className={"language-list shadow"}>
        {Object.keys(availableLocales).map((locale) => (
          <DropdownItem
            className={
              "language-list__item " +
              (isLocaleSelected(locale) ? "language-list__item_selected" : "")
            }
            onClick={() => changeLocale(locale)}
            key={locale}
          >
            {isLocaleSelected(locale) && <TickIcon className={"mr-1 ml-2"} />}
            {availableLocales[locale]}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageSelector;
