export default [
  {
    id: "eng",
    desc: "English",
    subtag: "en",
  },
  {
    id: "rus",
    desc: "Russian",
    subtag: "ru",
  },
  // {
  //   id: "deu",
  //   desc: "Germany",
  //   subtag: "de",
  // },
  // {
  //   id: "fra",
  //   desc: "French",
  //   subtag: "fr",
  // },
];
