import {fetchWithToken} from "../../../utils";
import {createAsyncThunk} from "@reduxjs/toolkit";

const tokenFetch = fetchWithToken(fetch);

export const createNewGroup = createAsyncThunk(
  "groups/createNewGroup",
  async (arg, thunkAPI) => {
    try {
      const group = await getGroup(arg);

      if (group.count === 0) {
        const response = await tokenFetch("/api/v1/groups/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({name: arg}),
        });

        const data = await response.json();

        if (response.status === 401) {
          localStorage.removeItem("token");
        }

        if (!response.ok) {
          return await thunkAPI.rejectWithValue(data);
        }
        return data;
      } else {
        throw new Error("errors.group-name-occupied");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteGroup = createAsyncThunk("groups/deleteGroup", async (arg) => {
  try {
    const response = await tokenFetch(`/api/v1/groups/${arg}/`, {
      method: "DELETE",
    });

    if (response.status === 401) {
      localStorage.removeItem("token");
    }

    if (!response.ok) {
      return Promise.reject("Something is going wrong. Try later");
    }

    return null;
  } catch (error) {
    throw error;
  }
});

export const editGroup = createAsyncThunk("groups/editGroup", async (arg, thunkAPI) => {
  try {
    const group = await getGroup(arg.name);
    const isInitialName = arg.isInitName;

    if (group.count === 0 || isInitialName) {
      const response = await tokenFetch(`/api/v1/groups/${arg.groupID}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({name: arg.name}),
      });

      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        thunkAPI.rejectWithValue(data);
      }

      return data;
    } else {
      throw new Error("errors.group-name-occupied");
    }
  } catch (error) {
    throw error;
  }
});

export const getGroups = createAsyncThunk("groups/getGroups", async (arg, thunkAPI) => {
  try {
    const response = await tokenFetch("/api/v1/groups/?limit=1000&offset=0");
    const data = await response.json();

    if (response.status === 401) {
      localStorage.removeItem("token");
    }

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return data;
  } catch (error) {
    throw error;
  }
});

const getGroup = async (name) => {
  try {
    const response = await tokenFetch(`/api/v1/groups/?name=${name}`);
    const data = await response.json();

    if (response.status === 401) {
      localStorage.removeItem("token");
    }

    if (!response.ok) {
      return data;
    }

    return data;
  } catch (error) {
    throw error;
  }
};
