import React, {useState, useEffect, useRef} from "react";
import {Stage, Layer, Image} from "react-konva";
import {getImageWithAuth} from "../../utils";
import SizeTumbler from "../../components/TemplateEditor/SizeTumbler/SizeTumbler";
import Area from "./Area/Area";

const DEFAULT_SCALE = 0.9;

const RecognitionCanvas = React.forwardRef(
  (
    {
      imageUrl,
      areas = [],
      highlightAreas,
      onHighlightArea: highlightArea,
      onDeleteResultsArea: deleteResultsArea,
    },
    ref
  ) => {
    const [image, setImage] = useState(null);
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [scale, setScale] = useState(DEFAULT_SCALE); //set scale of canvas
    const [initialScale, setInitialScale] = useState(DEFAULT_SCALE);

    const imageXOffset = (canvasWidth - image?.width * scale) / 2; // perfect centered image on canvas
    const imageYOffset = (canvasHeight - image?.height * scale) / 2; // perfect centered image on canvas

    const canvasWrapperRef = useRef();

    const convertAreaCoordinates = (coordinateType = "absolute", area) => {
      //absolute means that area coordinates relative to document
      //otherwise relative. it means that area coordinates relative to canvas
      //absolute coordinates needs backend and relative needs frontend

      return coordinateType === "absolute"
        ? {
            ...area,
            x: area.x - imageXOffset / scale,
            y: area.y - imageYOffset / scale,
          }
        : {
            ...area,
            x: area.x + imageXOffset / scale,
            y: area.y + imageYOffset / scale,
          };
    };

    const checkCanvasSize = () => {
      let computedStyle = getComputedStyle(canvasWrapperRef.current);

      let paddingX =
        parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
      let borderX =
        parseFloat(computedStyle.borderLeftWidth) +
        parseFloat(computedStyle.borderRightWidth);

      let paddingY =
        parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
      let borderY =
        parseFloat(computedStyle.borderTopWidth) +
        parseFloat(computedStyle.borderBottomWidth);

      let canvasWidth = canvasWrapperRef.current.offsetWidth - paddingX - borderX;
      let canvasHeight = canvasWrapperRef.current.offsetHeight - paddingY - borderY;

      setCanvasWidth(canvasWidth);
      setCanvasHeight(canvasHeight);
    }; //for adaptive canvas size

    const changeScaleHandler = (val) => {
      setScale(val);
    };

    const onWheelHandler = (e) => {
      if (e.deltaY > 0) {
        setScale(scale * 1.1);
      }
      if (e.deltaY < 0) {
        setScale(scale / 1.1);
      }
    };

    useEffect(() => {
      //check and set scale of document
      let scaleByWidth = DEFAULT_SCALE;
      let scaleByHeight = DEFAULT_SCALE;

      if (canvasHeight < image?.height) {
        scaleByHeight = (canvasHeight / image?.height) * DEFAULT_SCALE;
      }
      if (canvasWidth < image?.width) {
        scaleByWidth = (canvasWidth / image?.width) * DEFAULT_SCALE;
      }
      const scale = Math.min(scaleByHeight, scaleByWidth);

      setScale(scale);
      setInitialScale(scale);
    }, [canvasHeight, canvasWidth, image]);

    useEffect(() => {
      if (imageUrl) {
        const img = new window.Image();
        getImageWithAuth(imageUrl).then((url) => {
          img.src = url;
          img.onload = () => setImage(img);
        });
      }
    }, [imageUrl]);

    useEffect(() => {
      //check resizing of window object and therefore canvas size
      checkCanvasSize();
      window.addEventListener("resize", checkCanvasSize);

      return function cleanUp() {
        window.removeEventListener("resize", checkCanvasSize);
      };
    }, []);

    const convertedAreas = areas.map((area) => convertAreaCoordinates("relative", area));

    return (
      <div
        ref={canvasWrapperRef}
        style={{height: "100%", width: "100%", overflow: "hidden"}}
        onWheel={onWheelHandler}
      >
        <SizeTumbler
          scale={scale}
          onChangeScale={changeScaleHandler}
          initialScale={initialScale}
        />
        <Stage
          draggable
          ref={ref}
          width={canvasWidth}
          height={canvasHeight}
          scaleX={scale}
          scaleY={scale}
        >
          <Layer>
            <Image y={imageYOffset / scale} x={imageXOffset / scale} image={image} />
          </Layer>
          <Layer>
            {convertedAreas.map((area) => (
              <Area
                area={area}
                highlightAreas={highlightAreas}
                onDeleteResultsArea={deleteResultsArea}
                onHighlightArea={highlightArea}
              />
            ))}
          </Layer>
        </Stage>
      </div>
    );
  }
);

export default RecognitionCanvas;
