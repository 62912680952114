import React from "react";

const GroupField = ({className, group, onClick: clickHandler}) => {
  return (
    <p className={className} onClick={() => clickHandler(group)}>
      {group.name.toUpperCase()}
    </p>
  );
};

export default GroupField;
