import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import "./ContactUsModal.scss";
import {useTranslation} from "react-i18next";

const ContactUsModal = ({toggle, modal}) => {
  const {t} = useTranslation();

  return (
    <Modal centered isOpen={modal} toggle={toggle} className={"contact-modal"}>
      <ModalHeader toggle={toggle} className={"contact-modal__header"}>
        {t("contact-modal.title")}
      </ModalHeader>
      <ModalBody className={"contact-modal__body"}>
        <span>{t("contact-modal.text")} </span>
        <a href="mailto:docrec@parsio.app">contact@parsio.app</a>
      </ModalBody>
    </Modal>
  );
};

export default ContactUsModal;
