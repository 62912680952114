/* eslint-disable no-duplicate-case */
import * as ActionsTypes from "../../constants/ActionsTypes";

const initialState = {
  file: null,
  documents: {
    isLoading: false,
    errorText: null,
    data: null,
    limit: null,
  },
  recognitionResults: {
    isLoading: false,
    errorText: null,
    data: null,
  },
  highlightedAreas: {
    hovered: null,
    selected: null,
  },
};

export default function docTemplateEditor(state = initialState, action) {
  switch (action.type) {
    case ActionsTypes.DOCUMENTS_REQUEST:
      return {
        ...state,
        documents: {...state.documents, isLoading: true, errorText: null},
      };
    case ActionsTypes.DOCUMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          isLoading: false,
          errorText: null,
          data: action.payload.data,
          page: action.meta.page || 0,
          limit: action.meta.limit,
        },
      };
    case ActionsTypes.DOCUMENTS_REQUEST_FAIL:
      return {
        ...state,
        documents: {
          ...state.documents,
          isLoading: false,
          errorText: action.error,
        },
      };

    ////HIGHLIGHT AREA////////////
    case ActionsTypes.R_HIGHLIGHT_AREA:
      if (action.eventType === "hover") {
        return {
          ...state,
          highlightedAreas: {
            ...state.highlightedAreas,
            hovered: action.areaId,
          },
        };
      }
      if (action.eventType === "click") {
        return {
          ...state,
          highlightedAreas: {
            ...state.highlightedAreas,
            selected: action.areaId,
          },
        };
      }
      return state;
    ///////CLEAR HIGHLIGHTED AREAS/////////////////
    case ActionsTypes.R_CLEAR_ALL_HIGHLIGHTED_AREAS:
      return {
        ...state,
        highlightedAreas: {
          hovered: null,
          selected: null,
        },
      };

    /// RECOGNIZE UPLOAD FILE///////////////////////////////////
    case ActionsTypes.RECOGNIZE_FILE_REQUEST:
    case ActionsTypes.DOCUMENT_REQUEST:
      return {
        ...state,
        recognitionResults: {
          ...state.recognitionResults,
          isLoading: true,
          errorText: null,
        },
      };

    case ActionsTypes.RECOGNIZE_FILE_REQUEST_SUCCESS:
    case ActionsTypes.DOCUMENT_REQUEST_SUCCESS:
      return {
        ...state,
        recognitionResults: {
          ...state.recognitionResults,
          isLoading: false,
          data: action.payload,
        },
      };

    case ActionsTypes.RECOGNIZE_FILE_REQUEST_FAIL:
    case ActionsTypes.DOCUMENT_REQUEST_FAIL:
      return {
        ...state,
        recognitionResults: {
          ...state.recognitionResults,
          isLoading: false,
          errorText: "No matching template was found",
        },
      };
    case ActionsTypes.CLEAR_RECOGNITION_RESULTS:
      return {...state, recognitionResults: []};

    case ActionsTypes.EDIT_RECOGNITION_RESULTS:
      const {texts} = state.recognitionResults.data;

      const updatedTextList = texts.map((item) => {
        if (item.cid === action.regionID) item.text = action.value;
        return item;
      });

      return {
        ...state,
        recognitionResults: {
          ...state.recognitionResults,
          data: {
            ...state.recognitionResults.data,
            texts: updatedTextList,
          },
        },
      };

    case ActionsTypes.DELETE_RECOGNITION_RESULTS_AREA:
      return {
        ...state,
        recognitionResults: {
          ...state.recognitionResults,
          data: {
            ...state.recognitionResults.data,
            texts: state.recognitionResults.data.texts.filter(
              (item) => item.cid !== action.regionID
            ),
          },
        },
      };

    case ActionsTypes.SET_FILE_TO_RECOGNIZE:
      return {
        ...state,
        file: action.file,
      };
    /// DEFAULT STATE //////////////////////////////////////////
    default:
      return state;
  }
}
