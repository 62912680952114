import React from "react";
import DefaultLayout from "../../../../components/DefaultLayout/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import "./InstructionMobileVersion.scss";

const questions = [
  {page: 1, textLabel: "help.page1.title"},
  {page: 2, textLabel: "help.page2.title"},
  {page: 3, textLabel: "help.page3.title"},
  {page: 4, textLabel: "help.page4.title"},
  {page: 5, textLabel: "help.page5.title"},
  {page: 6, textLabel: "help.page6.title"},
  {page: 7, textLabel: "help.page7.title"},
  {page: 8, textLabel: "help.page8.title"},
  {page: 9, textLabel: "help.page9.title"},
];

const InstructionListMobileVersion = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const showCertainInstruction = (instruction) => {
    history.push(`/instructions/${instruction}`);
  };

  return (
    <DefaultLayout
      className="instructions-list-mobile-version"
      isNonDesktop
      backArrow
      linkTitle={t("header.help")}
    >
      <div className="instructions-list-mobile-version__wrapper">
        <ul className={"instructions-list-mobile-version__page-list"}>
          {questions.map((q) => (
            <li
              className="instructions-list-mobile-version__page-list-item"
              key={q.textLabel}
              onClick={() => showCertainInstruction(q.page)}
            >
              {t(q.textLabel)}
            </li>
          ))}
        </ul>
      </div>
    </DefaultLayout>
  );
};

export default InstructionListMobileVersion;
