import React from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from "reactstrap";
import "./WarningModal.scss";

const WarningModal = ({
  isOpen,
  headerText,
  bodyText,
  leftButtonText,
  rightButtonText,
  onClickCancel: cancel,
  onClickAccept: accept,
}) => {
  return (
    <Modal className={"warning-modal"} isOpen={isOpen} centered>
      <ModalHeader className={"warning-modal__header"}>{headerText}</ModalHeader>
      <ModalBody className={"warning-modal__body"}>{bodyText}</ModalBody>
      <ModalFooter style={{borderTop: "none"}}>
        <Button className={"text-nowrap"} size="sm" color="link" onClick={() => cancel()}>
          {leftButtonText}
        </Button>{" "}
        <Button
          className={"text-nowrap"}
          size="sm"
          color="primary"
          onClick={() => accept()}
        >
          {rightButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WarningModal;
