import React from "react";
import "./Help.scss";
import {HelpIcon} from "../../Icons";
import {MainColors} from "../../../constants/CommonProperties";

const Help = ({onClick: clickHelpHandler}) => {
  return (
    <div id="help" className="help" onClick={clickHelpHandler}>
      <HelpIcon width="24" height="24" fill={MainColors.greyPalette.grey4} />
    </div>
  );
};

export default Help;
