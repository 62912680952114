import React from "react";

const INNER_CIRCLES_STROKE_WIDTH = 20;

const TargetInnerCircles = ({className, cx, cy, innerRadius}) => {
  return (
    <>
      <circle
        className={className}
        cx={cx}
        cy={cy}
        r={innerRadius - INNER_CIRCLES_STROKE_WIDTH - INNER_CIRCLES_STROKE_WIDTH / 2}
        stroke="rgba(0,0,0, .3)"
        strokeWidth="20"
        fill="none"
      />
      <circle
        className={className}
        cx={cx}
        cy={cy}
        r={innerRadius - INNER_CIRCLES_STROKE_WIDTH / 2}
        stroke="rgba(0,0,0, .4)"
        strokeWidth={INNER_CIRCLES_STROKE_WIDTH}
        fill="none"
      />
    </>
  );
};

export default TargetInnerCircles;
