import React from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as Illustration9} from "../../../../assets/HelpIllustrations/page9-illustration.svg";

const Page9 = () => {
  const {t} = useTranslation();
  return (
    <div className={"help-page"}>
      <h1 className={"help-page__title"}>{t("help.page9.title")}</h1>
      <p>{t("help.page9.list1")}</p>
      <p>{t("help.page9.list2")}</p>
      <p>{t("help.page9.list3")}</p>
      <div className={"help-page__image-container help-page__image-9"}>
        <Illustration9 />
      </div>
    </div>
  );
};

export default Page9;
