import React from "react";
import DocumentCard from "../../../components/DocumentCard";
import "./MobileRecognitionResultsList.scss";

const MobileRecognitionResultsList = ({
  documents,
  className,
  style,
  onSetFailedAIRecognitions: setFailedAIRecognitions,
  onDeleteDocument: deleteDocument,
  onShowDocument: showDocument,
}) => {
  return (
    <div className={`${className || ""} mobile-recgonition-results-list`} style={style}>
      {documents.map((document) => (
        <DocumentCard
          key={document.id}
          document={document}
          onSetFailedAIRecognitions={setFailedAIRecognitions}
          onDeleteDocument={deleteDocument}
          onShowDocument={showDocument}
        />
      ))}
    </div>
  );
};

export default MobileRecognitionResultsList;
