import {
  GROUP_REQUEST,
  GROUP_REQUEST_SUCCESS,
  GROUP_REQUEST_FAIL,
} from "../../constants/ActionsTypes";

const initialState = {
  groups: {
    isLoading: false,
    errorText: null,
    results: [],
  },
};

export default function group(state = initialState, action) {
  switch (action.type) {
    case GROUP_REQUEST:
      return {...state, groups: {...state.groups, isLoading: true, errorText: null}};
    case GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        groups: {...state.group, isLoading: false, results: action.results},
      };
    case GROUP_REQUEST_FAIL:
      return {
        ...state,
        groups: {...state.group, isLoading: false, errorText: action.error},
      };
    default:
      return state;
  }
}
