import React from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as Illustration5} from "../../../../assets/HelpIllustrations/page5-illustration.svg";

const Page5 = () => {
  const {t} = useTranslation();
  return (
    <div className={"help-page"}>
      <h1 className={"help-page__title"}>{t("help.page5.title")}</h1>
      <p className={"help-page__list-title"}>{t("help.page5.text1")}</p>
      <p>{t("help.page5.list1")}</p>
      <p>{t("help.page5.list2")}</p>
      <p>{t("help.page5.list3")}</p>
      <p className={"help-page__list-title"}>{t("help.page5.text2")}</p>
      <div className={"help-page__image-container help-page__image"}>
        <Illustration5 />
      </div>
    </div>
  );
};

export default Page5;
