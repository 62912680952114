import React from "react";
import "./DropMenu.scss";
import GroupField from "../GroupField";

const DropMenu = ({className, groups, activeGroup, onGroupClick: clickGroupHandler}) => {
  return (
    <div className={`${className} drop-menu-component`}>
      {groups.map((group) => (
        <GroupField
          key={group.name}
          group={group}
          className={
            "mb-0 cursor-pointer drop-menu-component__category-name " +
            (activeGroup === group.name
              ? "drop-menu-component__category-name_selected"
              : "")
          }
          onClick={() => clickGroupHandler(group)}
        />
      ))}
    </div>
  );
};

export default DropMenu;
