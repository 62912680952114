import {useState, useEffect} from "react";

export const useMediaQuery = () => {
  const [width, setWidth] = useState(0);

  const f = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", f);

    return function cleanUp() {
      window.removeEventListener("resize", f);
    };
  }, []);

  return window.matchMedia("(max-width: 1024px)").matches;
};
