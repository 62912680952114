import React from "react";

const RefWrapper = React.forwardRef(({children, style, className, ...props}, ref) => {
  return (
    <div id="refWrapper" style={style} className={className} ref={ref} {...props}>
      {children}
    </div>
  );
});

export default RefWrapper;
