import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {ExportIcon} from "../../Icons";
import {MainColors} from "../../../constants/CommonProperties";
import {CSVLink} from "react-csv";
import "./ExportFileButton.scss";

const ExportFileButton = ({data, label, iconWidth, iconHeight}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className={"export-button__container mr-4"}
    >
      <DropdownToggle className={"export-button__icon"}>
        <ExportIcon
          width={iconWidth || "16px"}
          height={iconHeight || "16px"}
          fill={MainColors.greyPalette.grey5}
          className={label ? "mr-2" : ""}
        />
        {label && <span className={"export-button__label"}>{label}</span>}
      </DropdownToggle>
      <DropdownMenu className={"export-button__dropdown-container shadow"}>
        <DropdownItem className={"export-button__link"}>
          <CSVLink
            data={data}
            separator={";"}
            filename={`RECOGNITION_RESULTS-${Date.now()}.csv`}
          >
            .csv
          </CSVLink>
        </DropdownItem>
        <DropdownItem className={"export-button__link"}>
          <a
            href={`data:text/json;charset=utf-8, ${encodeURIComponent(
              JSON.stringify(data)
            )}`}
            download={`RECOGNITION_RESULTS-${Date.now()}.json`}
          >
            .json
          </a>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ExportFileButton;
