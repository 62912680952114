import React from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as HelpIcon} from "../../../../assets/HelpIllustrations/icons/help.svg";
import {ReactComponent as MarkAreaIcon} from "../../../../assets/HelpIllustrations/icons/mark-active.svg";
import {ReactComponent as SelectIcon} from "../../../../assets/HelpIllustrations/icons/arrow-def.svg";
import {ReactComponent as ResizeIcon} from "../../../../assets/HelpIllustrations/icons/resize-hover.svg";
import {ReactComponent as HandIcon} from "../../../../assets/HelpIllustrations/icons/hand.svg";
import {ReactComponent as ZoomInIcon} from "../../../../assets/HelpIllustrations/icons/zoom-in.svg";
import {ReactComponent as ZoomOutIcon} from "../../../../assets/HelpIllustrations/icons/zoom-out.svg";

const Page8 = () => {
  const {t} = useTranslation();
  return (
    <div className={"help-page"}>
      <h1 className={"help-page__title"}>{t("help.page8.title")}</h1>
      <ul>
        <li>
          <HelpIcon />
          <span>{t("help.page8.list1")}</span>
        </li>
        <li>
          <MarkAreaIcon />
          <span>{t("help.page8.list2")}</span>
        </li>
        <li>
          <SelectIcon />
          <span>{t("help.page8.list3")}</span>
        </li>
        <li>
          <ResizeIcon />
          <span>{t("help.page8.list4")}</span>
        </li>
        <li>
          <HandIcon />
          <span>{t("help.page8.list5")}</span>
        </li>
        <li>
          <ZoomInIcon />
          <span>{t("help.page8.list6")}</span>
        </li>
        <li>
          <ZoomOutIcon />
          <span>{t("help.page8.list7")}</span>
        </li>
      </ul>
      <p>{t("help.page8.text1")}</p>
    </div>
  );
};

export default Page8;
