import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import "./UserInfo.scss";
import {ReactComponent as ChevronIcon} from "../../assets/Chevron.svg";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter} from "../../utils";
import {logout} from "../../redux/feature/authV2/actions";
import {useDispatch, useSelector} from "react-redux";
import LanguageSelector from "./LanguageSelector/LanguageSelector";
import ContactUsModal from "./ContactUsModal/ContactUsModal";
import InstructionsModal from "./InstructionsModal/InstructionsModal";
import SupportModal from "./SupportModal/SupportModal";
import {useHistory, useLocation} from "react-router-dom";
import {useMediaQuery} from "../../hooks/useMediaQuery";

const UserInfo = ({onShowWarningBeforeLogout: showWarningBeforeLogout, noWarning}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [contactUsModal, setContactUsModal] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [supportModal, setSupportModal] = useState(false);

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isNonDesktop = useMediaQuery();

  const email = useSelector((state) => state.authV2.user?.email);

  const logoutHandler = () => {
    if (noWarning) {
      dispatch(logout()).then((data) => {
        if (data.error) {
          alert(data.payload);
        }
      });
      return;
    }
    showWarningBeforeLogout("logout");
  };

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const toggleContactUsModal = () => setContactUsModal(!contactUsModal);
  const toggleHelpModal = () => setHelpModal(!helpModal);
  const toggleSupportModal = () => setSupportModal(!supportModal);

  const activateWizard = () => {
    localStorage.removeItem("parsio.skipWizardStatus");
    if (location.pathname.includes("/templates")) {
      history.go(0);
      return;
    }
    history.push("/templates");
  };

  const showMobileSupportPage = () => {
    history.push("/support");
  };

  const showMobileInstruction = () => {
    history.push("/instructions");
  };

  const firstEmailLetter = email ? email[0].toUpperCase() : "";

  return (
    <>
      <Dropdown
        className={"user-dropdown"}
        direction={"down"}
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
      >
        <DropdownToggle className={"user-dropdown__container"}>
          <div className={`user-dropdown__info ${isNonDesktop ? `` : `mr-2`}`}>
            {firstEmailLetter}
          </div>
          {!isNonDesktop && (
            <ChevronIcon
              className={
                "user-dropdown__icon " + (dropdownOpen ? "user-dropdown__icon_open" : "")
              }
            />
          )}
        </DropdownToggle>
        <DropdownMenu className={"list shadow"} right>
          <DropdownItem className={"list__header"} header>
            <span>{email}</span>
          </DropdownItem>
          <DropdownItem className={"list__item"} onClick={logoutHandler}>
            {capitalizeFirstLetter(t("header.sign-out"))}
          </DropdownItem>
          <DropdownItem className={"list__item"} onClick={toggleContactUsModal}>
            {capitalizeFirstLetter(t("header.contact"))}
          </DropdownItem>
          <DropdownItem
            className={"list__item"}
            onClick={isNonDesktop ? showMobileInstruction : toggleHelpModal}
          >
            {capitalizeFirstLetter(t("header.help"))}
          </DropdownItem>
          <DropdownItem
            className={"list__item"}
            onClick={isNonDesktop ? showMobileSupportPage : toggleSupportModal}
          >
            {t("support.title")}
          </DropdownItem>
          {isNonDesktop || (
            <DropdownItem className={"list__item"} onClick={activateWizard}>
              {capitalizeFirstLetter(t("header.repeat-manual"))}
            </DropdownItem>
          )}
          <LanguageSelector />
        </DropdownMenu>
      </Dropdown>
      {contactUsModal && (
        <ContactUsModal modal={contactUsModal} toggle={toggleContactUsModal} />
      )}
      {helpModal && <InstructionsModal modal={helpModal} toggle={toggleHelpModal} />}
      {supportModal && <SupportModal modal={supportModal} toggle={toggleSupportModal} />}
    </>
  );
};

export default UserInfo;
