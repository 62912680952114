import {
  REQUEST_TEMPLATES,
  RECEIVE_TEMPLATES,
  SET_TEMPLATES_LOADING_ERROR,
} from "../../constants/ActionsTypes";

const initialState = {
  isLoading: false,
  data: null,
  errorText: null,
  page: 0,
  limit: 0,
};

export default function docTemplates(state = initialState, action) {
  switch (action.type) {
    case REQUEST_TEMPLATES:
      return {...state, isLoading: true, errorText: null};
    case RECEIVE_TEMPLATES:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        page: action.meta?.page || 0,
        limit: action.meta?.limit || 0,
      };
    case SET_TEMPLATES_LOADING_ERROR:
      return {
        ...state,
        isLoading: false,
        errorText: action.payload.errorText,
        data: null,
      };
    default:
      return state;
  }
}
