import React from "react";
import Header from "./Header/Header";
import NonDesktopHeader from "./NonDesktopHeader";

const DefaultLayout = ({children, isNonDesktop, linkTitle, backArrow}) => (
  <>
    {isNonDesktop ? (
      <NonDesktopHeader backArrow={backArrow} linkTitle={linkTitle} />
    ) : (
      <Header />
    )}
    {children}
  </>
);

export default DefaultLayout;
