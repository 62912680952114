import React from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as Illustration6} from "../../../../assets/HelpIllustrations/page6-illustration.svg";

const Page6 = () => {
  const {t} = useTranslation();
  return (
    <div className={"help-page"}>
      <h1 className={"help-page__title"}>{t("help.page6.title")}</h1>
      <p className={"help-page__subtitle"}>-{t("help.page6.subtitle1")}</p>
      <p>{t("help.page6.text1")}</p>
      <p className={"help-page__subtitle"}>-{t("help.page6.subtitle2")}</p>
      <p>{t("help.page6.text2")}</p>
      <p className={"help-page__subtitle"}>-{t("help.page6.subtitle3")}</p>
      <p>{t("help.page6.text3")}</p>
      <div className={"help-page__image-container help-page__image-6"}>
        <Illustration6 />
      </div>
    </div>
  );
};

export default Page6;
