import React from "react";
import {PayPalButtons, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import {Button, Spinner} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "../../../hooks/useMediaQuery";

const CustomPaypalButton = ({setPaid, donationValue, className}) => {
  const [{isPending}] = usePayPalScriptReducer();
  const {t} = useTranslation();
  const isNonDesktop = useMediaQuery();

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: donationValue,
            },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  };

  return (
    <div className={className}>
      {isPending ? (
        <Button style={{background: "#0070ba", width: "100%", height: "100%"}} disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          {t("loading")}...
        </Button>
      ) : (
        <PayPalButtons
          style={
            isNonDesktop
              ? {layout: "horizontal", color: "blue", tagline: "false", height: 40}
              : {layout: "horizontal", color: "blue"}
          }
          createOrder={createOrder}
          onApprove={() => setPaid(true)}
          onError={(error) => alert(error)}
          forceReRender={donationValue}
        />
      )}
    </div>
  );
};

export default CustomPaypalButton;
