import React, {useEffect, useRef} from "react";
import {Rect, Text, Label, Tag, Group, Transformer, Path} from "react-konva";
import {ControlPanelTools} from "../../../constants/EditorConsts";
import {MainColors} from "../../../constants/CommonProperties";

const SectionInner = ({
  cursor,
  isSelected,
  editable,
  currTool,
  hightlighted,
  area,
  onEditArea: editArea,
  onDeleteArea: deleteArea,
  onSelectArea: selectArea,
  onHighlightArea: highlightArea,
  onDragArea: dragArea,
  onTransformArea: transformArea,
  onMouseenterArea: mouseEnterArea,
  onMouseoutArea: mouseOutArea,
}) => {
  let shapeRef = useRef();
  let trRef = useRef();
  let labelTextRef = useRef();

  useEffect(() => {
    if (isSelected && editable) {
      // we need to attach transformer manually
      trRef.current.setNode(shapeRef.current);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected, editable]);

  const mouseEnterAreaHandler = () => {
    mouseEnterArea && mouseEnterArea(area);
  };
  const mouseOutAreaHandler = () => {
    mouseOutArea && mouseOutArea(area);
  };

  const editAreaHandler = () => {
    editArea(area);
  };

  const resizeAreaHandler = () => {
    selectArea(area.cid);
  };

  const dragEndHandler = (e) => {
    dragArea(e.target.attrs, area.cid);
  };

  //TODO: remake this function with convertCoordinateFunction
  const transfromAreaHandler = () => {
    const node = shapeRef.current;
    const width = area.width * node.scaleX();
    const height = area.height * node.scaleY();
    const x = area.x + node.x();
    const y = area.y + node.y();
    //disable changes on area view by Konva, it made via Redux store
    node.scaleX(1);
    node.scaleY(1);
    node.attrs.x = 0;
    node.attrs.y = 0;
    //end if disable area
    transformArea({x, y, width, height}, area.cid);
  };

  const pathNodeMouseOverHandler = (e) => {
    //for changing cursor to pointer when hover
    if (e.currentTarget.getStage()) {
      e.currentTarget.getStage().container().style.cursor = "pointer";
    }
  };

  const pathNodeMouseOutHandler = (e) => {
    //for changing cursor back when hover
    if (e.currentTarget.getStage()) {
      e.currentTarget.getStage().container().style.cursor = cursor;
    }
  };

  const pathNodeClickHandler = (e, area) => {
    if (e.currentTarget.getStage()) {
      e.currentTarget.getStage().container().style.cursor = cursor;
    }
    deleteArea(area);
  };

  const labelPosition = area.endPosition
    ? {
        x: Math.min(area.startPosition.x, area.endPosition.x),
        y: Math.min(area.startPosition.y, area.endPosition.y),
      }
    : null;

  return (
    <Group
      onDragEnd={currTool === ControlPanelTools.EDITING ? dragEndHandler : null}
      draggable={currTool === ControlPanelTools.EDITING}
      x={area.x}
      y={area.y}
    >
      {
        <>
          <Label
            visible={!!(area.name && hightlighted)}
            offsetY={36}
            offsetX={1}
            {...labelPosition}
          >
            <Tag fill={MainColors.purplePrimary.purplePrimary} />
            <Text
              name="data-area"
              ref={labelTextRef}
              text={area.name + "         "} //need to add filled width to label that allow to add path
              padding={10}
              fontSize={18}
              fill="white"
            />
            <Path
              x={labelTextRef.current?.textWidth - 12} //need to locate path in label correctly
              y={4}
              onMouseOver={pathNodeMouseOverHandler}
              onMouseOut={pathNodeMouseOutHandler}
              onClick={(e) => pathNodeClickHandler(e, area)}
              scale={{x: 2, y: 2}}
              fill={" #AAB5F0"}
              data="M6.99984 1.16675C3.774 1.16675 1.1665 3.77425 1.1665 7.00008C1.1665 10.2259 3.774 12.8334 6.99984 12.8334C10.2257 12.8334 12.8332 10.2259 12.8332 7.00008C12.8332 3.77425 10.2257 1.16675 6.99984 1.16675ZM9.50817 9.50842C9.28067 9.73592 8.91317 9.73592 8.68567 9.50842L6.99984 7.82258L5.314 9.50842C5.0865 9.73592 4.719 9.73592 4.4915 9.50842C4.264 9.28092 4.264 8.91342 4.4915 8.68592L6.17734 7.00008L4.4915 5.31425C4.264 5.08675 4.264 4.71925 4.4915 4.49175C4.719 4.26425 5.0865 4.26425 5.314 4.49175L6.99984 6.17758L8.68567 4.49175C8.91317 4.26425 9.28067 4.26425 9.50817 4.49175C9.73567 4.71925 9.73567 5.08675 9.50817 5.31425L7.82234 7.00008L9.50817 8.68592C9.72984 8.90758 9.72984 9.28092 9.50817 9.50842Z"
            />
          </Label>
        </>
      }
      <Rect
        name="data-area"
        onMouseenter={mouseEnterAreaHandler}
        onMouseout={mouseOutAreaHandler}
        onTransform={transfromAreaHandler}
        onMouseDown={() => highlightArea(area)}
        onDblClick={editAreaHandler}
        onClick={resizeAreaHandler}
        width={area.width}
        height={area.height}
        stroke={MainColors.purplePrimary.purplePrimary30}
        fill={MainColors.purplePrimary.purplePrimary30}
        ref={shapeRef}
      />
      {isSelected && editable && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          anchorSize={7}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </Group>
  );
};

export default SectionInner;
