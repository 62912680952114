import {useState, useCallback} from "react";

export const useNode = () => {
  const [node, setNode] = useState(null);

  const ref = useCallback((node) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  return [node, ref];
};
