import React from "react";
import {Button} from "reactstrap";
import "./SizeTumbler.scss";
import {MinusIcon, PlusIcon} from "../../Icons";
import {MainColors} from "../../../constants/CommonProperties";
import {useTranslation} from "react-i18next";

const SizeTumbler = (props) => {
  const {t} = useTranslation();

  const isValidScale = (val) => {
    return val >= 0 && val <= 2;
  };

  const decrScale = () => {
    let newScaleVal = props.scale - 0.05;

    if (props.onChangeScale && isValidScale(newScaleVal)) {
      props.onChangeScale(newScaleVal);
    }
  };

  const incrScale = () => {
    let newScaleVal = props.scale + 0.05;

    if (props.onChangeScale && isValidScale(newScaleVal)) {
      props.onChangeScale(newScaleVal);
    }
  };

  const resetScale = () => {
    props.onChangeScale(props.initialScale);
  };

  return (
    <div className="size-tumbler" title={t("size-tumbler.title")}>
      <Button
        style={{borderRadius: "5px 5px 0 0"}}
        className="p-2 btn-light"
        disabled={props.disabled}
        onClick={incrScale}
      >
        <PlusIcon width="10" height="10" fill={MainColors.greyPalette.grey6} />
      </Button>
      <span
        className="text-center body-regular cursor-pointer form-control size-tumbler-info"
        onClick={resetScale}
      >
        {Math.ceil(props.scale * 100) + "%"}
      </span>
      <Button
        style={{borderRadius: "0 0 5px 5px"}}
        className="p-2"
        disabled={props.disabled}
        onClick={decrScale}
      >
        <MinusIcon width="10" height="2" fill={MainColors.greyPalette.grey6} />
      </Button>
    </div>
  );
};

export default SizeTumbler;
