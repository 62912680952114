import React, {useEffect} from "react";
import {CloseIcon} from "../../../components/Icons";
import {MainColors} from "../../../constants/CommonProperties";
import {Table} from "reactstrap";
import DocumentsEditingPopUp from "../../../components/DocumentsEditingPopUp/DocumentsEditingPopUp";
import "./RecognitionResultList.scss";
import {
  getTimeFromDateTime,
  getDateFromDateTime,
  capitalizeFirstLetter,
  truncateString,
} from "../../../utils";
import {convertDataForCSVLink} from "../../../utils";
import {useTranslation} from "react-i18next";
import ExportFileButton from "../../../components/Buttons/ExportFileButton/ExportFileButton";
import Processing from "../RecognitionResultList/Processing";
import usePrevious from "../../../hooks/usePrevious";

const RecognitionResultList = React.forwardRef(
  (
    {
      documents,
      documentsListWithCheck,
      onSetDocumentListWithCheck: setDocumentsListWithCheck,
      onDeleteDocument: deleteDocument,
      onDeleteDocumentsByEditingPopUp: deleteDocumentByEditingPopUp,
      onShowDocument: showDocument,
      onSetFailedAIRecognitions: setFailedAIRecognitions,
    },
    ref
  ) => {
    const {t} = useTranslation();

    const checkAllCheckBoxIsTrue = () => {
      for (let item of documentsListWithCheck) {
        if (!item.checked) return false;
      }
      return true;
    };

    const checkListHasCheckedBox = () => {
      for (let item of documentsListWithCheck) {
        if (item.checked) return true;
      }
      return false;
    };

    const checkListHasCheckedBoxWithId = (id) => {
      for (let item of documentsListWithCheck) {
        if (item.checked && item.id === id) return true;
      }
      return false;
    };

    const checkBoxChangeHandler = (e, document) => {
      e.persist();

      setDocumentsListWithCheck((documentsListWithCheck) =>
        documentsListWithCheck.map((item) => {
          if (item.id === document.id) item.checked = !item.checked;
          return item;
        })
      );
    };

    const toogleAllCheckBoxHandler = () => {
      setDocumentsListWithCheck((documentsListWithCheck) => {
        let updated = [];
        for (let template of documentsListWithCheck) {
          if (template.checked) {
            updated = documentsListWithCheck.map((item) => {
              item.checked = false;
              return item;
            });
            return updated;
          }
        }
        updated = documentsListWithCheck.map((item) => {
          item.checked = true;
          return item;
        });
        return updated;
      });
    };

    const generateHeaders = () => [
      "",
      "list-view.file-name",
      "list-view.time",
      "list-view.date",
      "list-view.template",
      "list-view.group",
      "list-view.actions",
    ];

    const prevDocumentsLength = usePrevious(documents.length);
    useEffect(() => {
      if (documents.length && documents.length !== prevDocumentsLength) {
        const checkedDocuments = documents?.map((document) => ({
          ...document,
          checked: false,
        }));
        setDocumentsListWithCheck(checkedDocuments);
      }
    }, [documents, setDocumentsListWithCheck, prevDocumentsLength]);

    return (
      <div className="recognition-result-list">
        <DocumentsEditingPopUp
          isShow={checkListHasCheckedBox()}
          data={documentsListWithCheck.filter((item) => item.checked)}
          onDeleteTemplates={deleteDocumentByEditingPopUp}
        />
        <Table striped borderless className="position-relative">
          <thead>
            <tr className="position-relative">
              {generateHeaders().map((header, index) => {
                if (index === 0)
                  return (
                    <th key={header}>
                      <label
                        className={
                          checkAllCheckBoxIsTrue()
                            ? "checkbox-container main active"
                            : "checkbox-container main intermediate"
                        }
                        style={{bottom: "12px"}}
                      >
                        <input
                          checked={checkListHasCheckedBox()}
                          onClick={() => toogleAllCheckBoxHandler()}
                          readOnly
                          type="checkbox"
                        />
                        <span className="checkmark" style={{zIndex: 2}} />
                      </label>
                    </th>
                  );
                return (
                  <th className="metadata-bold-2" key={header}>
                    {capitalizeFirstLetter(t(header))}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {documents.map((document, index) => (
              <tr
                key={document.id}
                style={
                  checkListHasCheckedBoxWithId(document.id)
                    ? {backgroundColor: MainColors.purplePrimary.purpleClick}
                    : null
                }
                className={"cursor-pointer"}
              >
                <td>
                  <label className="checkbox-container">
                    <input
                      onChange={(e) => checkBoxChangeHandler(e, document)}
                      type="checkbox"
                      checked={checkListHasCheckedBoxWithId(document.id)}
                    />
                    <span className="checkmark" />
                  </label>
                </td>
                <td onClick={() => showDocument(document)}>
                  <span
                    className="body-regular"
                    style={{color: MainColors.greyPalette.typeBlack}}
                  >
                    {truncateString(document.filename || "", 10)}
                  </span>
                </td>
                <td onClick={() => showDocument(document.id)}>
                  <span className="body-regular">
                    {getTimeFromDateTime(document.datetime)}
                  </span>
                </td>
                <td onClick={() => showDocument(document.id)}>
                  <span className="body-regular">
                    {getDateFromDateTime(document.datetime)}
                  </span>
                </td>
                <td onClick={() => showDocument(document.id)}>
                  <span className="body-regular">
                    {truncateString(document.template?.name || "", 9)}
                  </span>
                </td>
                <td onClick={() => showDocument(document.id)}>
                  <span id={document.template?.name ? "group" : null}>
                    {truncateString(
                      document.template?.group?.name.toUpperCase() || "",
                      9
                    ) ||
                      (truncateString(document.template?.name || "", 9) &&
                        t("dropdown.no-group").toLocaleUpperCase())}
                  </span>
                </td>
                <td>
                  {document.status === "success" ? (
                    <div style={{maxWidth: "60px"}}>
                      <ExportFileButton
                        data={convertDataForCSVLink(document?.texts || [])}
                      />
                      <CloseIcon
                        onClick={() => deleteDocument(document)}
                        width="11px"
                        height="11px"
                        fill={MainColors.greyPalette.grey5}
                      />
                    </div>
                  ) : (
                    <Processing
                      status={document.status}
                      documentID={document.id}
                      onSetFailedAIRecognitions={setFailedAIRecognitions}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
);

export default RecognitionResultList;
