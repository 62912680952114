import React, {useState, useMemo} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {useTranslation} from "react-i18next";
import "./InstructionsModal.scss";
import Page1 from "./Pages/Page1";
import Page2 from "./Pages/Page2";
import Page3 from "./Pages/Page3";
import Page4 from "./Pages/Page4";
import Page5 from "./Pages/Page5";
import Page6 from "./Pages/Page6";
import Page7 from "./Pages/Page7";
import Page8 from "./Pages/Page8";
import Page9 from "./Pages/Page9";

const questions = [
  {page: 1, textLabel: "help.page1.title"},
  {page: 2, textLabel: "help.page2.title"},
  {page: 3, textLabel: "help.page3.title"},
  {page: 4, textLabel: "help.page4.title"},
  {page: 5, textLabel: "help.page5.title"},
  {page: 6, textLabel: "help.page6.title"},
  {page: 7, textLabel: "help.page7.title"},
  {page: 8, textLabel: "help.page8.title"},
  {page: 9, textLabel: "help.page9.title"},
];

const pages = [Page1, Page2, Page3, Page4, Page5, Page6, Page7, Page8, Page9];

const InstructionsModal = ({toggle, modal}) => {
  const {t} = useTranslation();
  const [currentPage, setPage] = useState(1);

  const CurrentPageComponent = useMemo(() => pages[currentPage - 1], [currentPage]);

  return (
    <Modal
      className={"instructions-modal__container"}
      centered
      isOpen={modal}
      toggle={toggle}
    >
      <ModalHeader className={"instructions-modal__header"} toggle={toggle}>
        {t("help.title")}
      </ModalHeader>
      <ModalBody className={"instructions-modal__body"}>
        <div>
          <ul className={"instructions-modal__page-list"}>
            {questions.map((q) => (
              <li
                className={
                  currentPage === q.page
                    ? "instructions-modal__page-list__item instructions-modal__page-list__item--active"
                    : "instructions-modal__page-list__item"
                }
                onClick={() => setPage(q.page)}
              >
                {t(q.textLabel)}
              </li>
            ))}
          </ul>
        </div>
        <div className={"instructions-modal__content"}>
          <CurrentPageComponent />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InstructionsModal;
