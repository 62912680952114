import React, {useState, useRef, useEffect, useCallback} from "react";
import {ListGroup, ListGroupItem} from "reactstrap";
import {CloseIcon, PlusIcon, PencilIcon} from "../Icons";
import "./CustomDropDown.scss";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import {capitalize, capitalizeFirstLetter} from "../../utils";
import {useTranslation} from "react-i18next";
import {MainColors} from "../../constants/CommonProperties";
import debounce from "../../utils/debounce";

const CustomDropDown = React.forwardRef(
  (
    {
      component,
      style,
      data,
      onGetGroups: getGroups,
      onSetGroup: setGroup,
      onCreateNewGroup: createNewGroup,
      onDeleteGroup: deleteGroup,
      onEditGroup: editGroup,
      props,
    },
    ref
  ) => {
    const {t} = useTranslation();
    const [isShowDropDown, setIsShowDropDown] = useState(false);
    const [addGroupField, setAddGroupField] = useState("");
    const [isAddingGroupActive, setIsAddingGroupActive] = useState(false);
    const [groupNames, setGroupNames] = useState([]);
    const [initialGroupName, setInitialGroupNames] = useState([]);
    const [isGroupAlreadyExist, setIsGroupAlreadyExist] = useState(false);
    const dropDownRef = useRef();

    useOnClickOutside(dropDownRef, () => {
      const emptyGroupValue = groupNames.find((currentValue) => currentValue === "");

      if (emptyGroupValue !== undefined) {
        alert(t("template-editor.empty-value"));
        return;
      }
      getGroups();

      setIsShowDropDown(false);
      setIsAddingGroupActive(false);
    });

    const chooseGroupHandler = (group, index) => {
      const emptyGroupValue = groupNames.find((currentValue) => currentValue === "");

      if (emptyGroupValue !== undefined) {
        alert(t("template-editor.empty-value"));
        inputsRefs[index].blur();
        return;
      }

      if (isGroupAlreadyExist) {
        alert(t("template-editor.occupied-value"));
        return;
      }

      setIsShowDropDown(false);
      setIsAddingGroupActive(false);

      //check if groupName was edited:-----------
      const editedGroupName = groupNames[index];
      index || index === 0
        ? setGroup({...group, name: editedGroupName})
        : setGroup(group);
      //-----------------------------------------
    };

    const createNewGroupHandler = (groupName) => {
      createNewGroup(groupName)
        .then((data) => {
          if (data) {
            if (data.error) {
              if (data.error.message) {
                alert(t(data.error.message));
              } else {
                alert(t("dropdown.error-text"));
              }
            } else {
              chooseGroupHandler(data.payload);
            }
          }
        })
        .catch((err) => alert(t(err)));
    };

    const inputsRefs = [];

    const clickEditPencilHandler = (index) => {
      inputsRefs[index].focus();
    };

    const debouncedEditGroup = useCallback(
      debounce((groupId, name, isInitName) => {
        editGroup(groupId, name, isInitName)
          .then((data) => {
            if (data.error) {
              if (data.error.message) {
                alert(t(data.error.message));
                setIsGroupAlreadyExist(true);
              } else {
                alert(t("template-editor.error-text"));
              }
            } else {
              setIsGroupAlreadyExist(false);
            }
          })
          .catch(() => alert(t("template-editor.error-text")));
      }, 500),
      []
    );

    const editGroupHandler = (e, index, groupId) => {
      const name = e.target.value;

      setGroupNames(groupNames.map((item, i) => (i === index ? name : item)));

      const isInitName = initialGroupName[index] === name;

      if (name) {
        debouncedEditGroup(groupId, name, isInitName);
      }
    };

    useEffect(() => {
      setGroupNames(data.map((item) => item.name));
      setInitialGroupNames(data.map((item) => item.name));
    }, [data]);

    return (
      <div ref={ref} style={style} {...props}>
        <div onClick={() => setIsShowDropDown(true)} className="position-relative">
          {component}
        </div>
        {isShowDropDown && (
          /*----------------------All existing groups-------------------*/
          <div ref={dropDownRef}>
            <ListGroup className={"custom-dropdown__list-group"}>
              {data.length ? (
                <>
                  <ListGroupItem
                    className="p-2 border-0 cursor-pointer group-pop-up-item d-flex align-items-center justify-content-center"
                    style={{
                      color: "black",
                      fontSize: "12px",
                    }}
                  >
                    <span onClick={() => chooseGroupHandler(null)}>
                      {capitalize(t("dropdown.remove-group"))}
                    </span>
                  </ListGroupItem>
                  {data.map((item, index) => (
                    <ListGroupItem
                      key={item.name}
                      className="p-2 border-0 custom-dropdown__list-group-item"
                    >
                      <input
                        className={"custom-dropdown__input"}
                        maxLength="30"
                        onClick={() => chooseGroupHandler(item, index)}
                        ref={(ref) => (inputsRefs[index] = ref)}
                        value={groupNames[index]}
                        onChange={(e) => editGroupHandler(e, index, item.id)}
                        placeholder={capitalizeFirstLetter(
                          t("dropdown.input-group-name")
                        )}
                      />
                      <div className={"custom-dropdown__button-container"}>
                        <PencilIcon
                          //Refactor using svg icons
                          className="mr-2"
                          width="12px"
                          height="12px"
                          fill={MainColors.greyPalette.grey6}
                          onClick={() => clickEditPencilHandler(index)}
                        />
                        <CloseIcon
                          width="10px"
                          height="10px"
                          fill={MainColors.greyPalette.grey6}
                          onClick={(e) => deleteGroup(e, item.id)}
                        />
                      </div>
                    </ListGroupItem>
                  ))}
                </>
              ) : (
                <div className="pb-3 pt-4 pr-4 pl-4 custom-dropdown__no-groups-placeholder">
                  {capitalizeFirstLetter(t("dropdown.no-group"))}
                </div>
              )}
              {/*------adding new group by  new group input field------*/}
              {isAddingGroupActive && (
                <ListGroupItem className="p-1 border-0">
                  <div className="add-new-group-wrapper">
                    <input
                      className="add-new-group-wrapper__input p-1"
                      type="text"
                      maxLength="30"
                      value={addGroupField}
                      onChange={(e) => setAddGroupField(e.target.value)}
                      placeholder={capitalizeFirstLetter(t("dropdown.input-group-name"))}
                    />
                    <div
                      className="add-new-group-wrapper__icon-container"
                      onClick={() => createNewGroupHandler(addGroupField)}
                    >
                      <PlusIcon
                        width="14px"
                        height="14px"
                        fill={MainColors.purplePrimary.purplePrimary}
                        className={"plus-icon"}
                      />
                    </div>
                  </div>
                </ListGroupItem>
              )}
              {/*----------------------+ NEW field-------------------*/}
              <ListGroupItem
                onClick={() => {
                  if (!isAddingGroupActive) {
                    setIsAddingGroupActive(true);
                  }
                }}
                className={
                  "p-2 border-0 custom-dropdown__list-group-item new-field-container " +
                  (isAddingGroupActive ? "custom-dropdown__list-group-item_blocked" : "")
                }
              >
                <PlusIcon
                  width="12px"
                  height="12px"
                  fill={
                    isAddingGroupActive
                      ? MainColors.greyPalette.grey5
                      : MainColors.purplePrimary.purplePrimary
                  }
                  className="mr-2"
                />
                <span
                  className={
                    "new-field-container__field " +
                    (isAddingGroupActive ? "new-field-container__field_blocked" : "")
                  }
                >
                  {t("dropdown.new").toLocaleUpperCase()}
                </span>
              </ListGroupItem>
            </ListGroup>
          </div>
        )}
      </div>
    );
  }
);

export default CustomDropDown;
