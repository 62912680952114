import React, {useRef} from "react";
import {MainColors} from "../../../constants/CommonProperties";
import {Group, Label, Tag, Text, Rect, Path} from "react-konva";

const Area = ({
  area,
  highlightAreas,
  onDeleteResultsArea: deleteResultsArea,
  onHighlightArea: highlightArea,
}) => {
  const labelTextRef = useRef();

  const pathNodeMouseOverHandler = (e) => {
    //for changing cursor to pointer when hover
    if (e.currentTarget.getStage()) {
      e.currentTarget.getStage().container().style.cursor = "pointer";
    }
  };

  const pathNodeMouseOutHandler = (e) => {
    //for changing cursor back when hover
    if (e.currentTarget.getStage()) {
      e.currentTarget.getStage().container().style.cursor = "default";
    }
  };

  return (
    <Group x={area.x} y={area.y}>
      <>
        <Label
          visible={
            area.name &&
            (highlightAreas.hovered === area.cid || highlightAreas.selected === area.cid)
          }
          offsetY={36}
          offsetX={1}
        >
          <Tag fill={MainColors.purplePrimary.purplePrimary} />
          <Text
            ref={labelTextRef}
            name="data-area"
            text={area.name + "         "} //need to add filled width to label that allow to add path
            padding={10}
            fontSize={18}
            fill="white"
          />
          <Path
            x={labelTextRef.current?.textWidth - 12} //need to locate path in label correctly
            y={4}
            onMouseOver={pathNodeMouseOverHandler}
            onMouseOut={pathNodeMouseOutHandler}
            onClick={() => deleteResultsArea(area.cid)}
            scale={{x: 2, y: 2}}
            fill={" #AAB5F0"}
            data="M6.99984 1.16675C3.774 1.16675 1.1665 3.77425 1.1665 7.00008C1.1665 10.2259 3.774 12.8334 6.99984 12.8334C10.2257 12.8334 12.8332 10.2259 12.8332 7.00008C12.8332 3.77425 10.2257 1.16675 6.99984 1.16675ZM9.50817 9.50842C9.28067 9.73592 8.91317 9.73592 8.68567 9.50842L6.99984 7.82258L5.314 9.50842C5.0865 9.73592 4.719 9.73592 4.4915 9.50842C4.264 9.28092 4.264 8.91342 4.4915 8.68592L6.17734 7.00008L4.4915 5.31425C4.264 5.08675 4.264 4.71925 4.4915 4.49175C4.719 4.26425 5.0865 4.26425 5.314 4.49175L6.99984 6.17758L8.68567 4.49175C8.91317 4.26425 9.28067 4.26425 9.50817 4.49175C9.73567 4.71925 9.73567 5.08675 9.50817 5.31425L7.82234 7.00008L9.50817 8.68592C9.72984 8.90758 9.72984 9.28092 9.50817 9.50842Z"
          />
        </Label>
      </>
      <Rect
        name="data-area"
        onMouseEnter={() => highlightArea(area.cid, "hover")}
        onMouseLeave={() => highlightArea(null, "hover")}
        onClick={() => highlightArea(area.cid, "click")}
        width={area.width}
        height={area.height}
        stroke={MainColors.purplePrimary.purplePrimary30}
        fill={MainColors.purplePrimary.purplePrimary30}
      />
    </Group>
  );
};

export default Area;
