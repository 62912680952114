import {MainColors} from "./CommonProperties";

export const ControlPanelTools = {
  SELECTING: "SELECTING",
  EDITING: "EDITING",
  MOVING: "MOVING",
  RESIZING: "RESIZING",
};

export const ControlPanelColors = {
  SELECT: MainColors.purplePrimary.purplePrimary,
  NOTSELECT: MainColors.greyPalette.grey6,
};
