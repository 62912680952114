import React from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as Illustration1} from "../../../../assets/HelpIllustrations/page1-illustration.svg";
import "./Pages.scss";

const Page1 = () => {
  const {t} = useTranslation();
  return (
    <div className={"help-page"}>
      <h1 className={"help-page__title"}>{t("help.page1.title")}</h1>
      <p className={"help-page__paragraph"}>{t("help.page1.text1")}</p>
      <p>
        {t("help.page1.text2")}
        <span className={"help-page__bold"}> {t("help.page1.text3")}</span>
      </p>
      <div className={"help-page__image-container help-page__image"}>
        <Illustration1 />
      </div>
    </div>
  );
};

export default Page1;
