import {useEffect} from "react";

export const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements

        if (!ref.current || ref.current.contains(event.target)) return;

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
};

//попробовать сделать два отдельных хука от useOnClickOutside - useOnClickOutsideDocumentArea и useOnClickOutsideCanvasArea
//первый перехватывает события document и обрабатывает их, но если поймает событие canvas то затыкается
//второй перехватывает события canvas и обарабатывает их.

export const useOnClickOutsideCanvasAreas = (stage, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        event.target.__proto__.className === "Rect" ||
        event.target.__proto__.className === "Text" ||
        event.target.__proto__.className === "Path"
      ) {
        return;
      }
      handler(event);
    };

    stage && stage.on("mousedown", listener);
    stage && stage.on("touchstart", listener);

    return () => {
      stage && stage.off("mousedown", listener);
      stage && stage.off("touchstart", listener);
    };
  }, [stage, handler]);
};

export const useOnClickOutsideDocumentAreas = (selector, handler) => {
  useEffect(() => {
    const listener = (event) => {
      const areas = document.querySelectorAll(`[${selector}]`);
      for (let area of areas) {
        if (event.target.nodeName === "CANVAS") return;
        if (!area || area.contains(event.target)) return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [selector, handler]);
};
