import * as Yup from "yup";

// Yup can't work with locale library i18n, so its passed name of the locale key as error text
// In component this error text must be wrapped in t-function of i18n to display proper localization

export const SignUpScheme = Yup.object().shape({
  email: Yup.string()
    .required("sign-up.email-validation")
    .matches(/^([\w]){1,64}@([\w])+(?:\.[\w]+)$/, "sign-up.email-validation"),
  password1: Yup.string()
    .required("sign-up.password-validation")
    .matches(/^[A-Za-z0-9]+$/, "sign-up.password-validation"),
  password2: Yup.string()
    .required("sign-up.password-validation")
    .oneOf([Yup.ref("password1"), null], "sign-up.password-match"),
});

export const SignInScheme = Yup.object().shape({
  email: Yup.string()
    .required("sign-up.email-validation")
    .matches(/^([\w]){1,64}@([\w])+(?:\.[\w]+)$/, "sign-up.email-validation"),
  password: Yup.string()
    .required("sign-up.password-validation")
    .matches(/^[A-Za-z0-9]+$/, "sign-up.password-validation"),
});

export const ResetScheme = Yup.object().shape({
  new_password1: Yup.string()
    .required("sign-up.password-validation")
    .matches(/^[A-Za-z0-9]+$/, "sign-up.password-validation"),
  new_password2: Yup.string()
    .required("sign-up.password-validation")
    .oneOf([Yup.ref("new_password1"), null], "sign-up.password-match"),
});

export const RecoverScheme = Yup.object().shape({
  email: Yup.string()
    .required("sign-up.email-validation")
    .matches(/^([\w]){1,64}@([\w])+(?:\.[\w]+)$/, "sign-up.email-validation"),
});
