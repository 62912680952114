import React from "react";
import Section from "../Section/Section";
import {ControlPanelTools} from "../../../constants/EditorConsts";
import {Layer} from "react-konva";

const AreasList = ({
  areas,
  scale,
  currTool,
  stage,
  cursor,
  highlightedAreas,
  selectedAreaId,
  onHighlightArea: highlightArea,
  onEditArea: editArea,
  onDeleteArea: deleteArea,
  onSelectArea: selectArea,
  onDragArea: dragArea,
  onTransformArea: transformArea,
}) => {
  const mouseEnterHandler = (area) => {
    highlightArea(area.cid, "hover");
    // Commented for ISSUE-254
    // if (
    //   currTool === ControlPanelTools.RESIZING ||
    //   currTool === ControlPanelTools.EDITING ||
    //   currTool === ControlPanelTools.SELECTING
    // ) {
    //   stage.container().style.cursor = "pointer";
    // }
  };

  const mouseLeaveHandler = () => {
    highlightArea(null, "hover");
    if (
      currTool === ControlPanelTools.RESIZING ||
      currTool === ControlPanelTools.EDITING
    ) {
      stage.container().style.cursor = "";
    }
    if (currTool === ControlPanelTools.SELECTING)
      stage.container().style.cursor = "crosshair";
  };

  const highlightAreaHandler = (area) => {
    highlightArea(area.cid, "click");
  };

  const editAreaHandler = (area) => {
    editArea(area);
    if (currTool === ControlPanelTools.RESIZING) {
      mouseLeaveHandler();
    }
  };

  const deleteAreaHandler = (area) => {
    deleteArea(area);
  };

  return (
    <Layer>
      {areas &&
        areas.map((area) => (
          <Section
            scale={scale}
            hightlighted={
              highlightedAreas.hovered === area.cid ||
              highlightedAreas.selected === area.cid
            }
            cursor={cursor}
            key={area.cid}
            isSelected={area.cid === selectedAreaId}
            currTool={currTool}
            editable={currTool === ControlPanelTools.RESIZING}
            area={area}
            onEditArea={editAreaHandler}
            onDeleteArea={deleteAreaHandler}
            onSelectArea={selectArea}
            onHighlightArea={highlightAreaHandler}
            onDragArea={dragArea}
            onTransformArea={transformArea}
            onMouseenterArea={mouseEnterHandler}
            onMouseoutArea={mouseLeaveHandler}
          />
        ))}
    </Layer>
  );
};

export default AreasList;
