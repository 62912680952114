import React from "react";
import "./Tooltip.scss";

export const Tooltip = ({children, text}) => {
  return (
    <div className="tooltip__container">
      {children}
      <span className="tooltip__text">{text}</span>
    </div>
  );
};

export default Tooltip;
