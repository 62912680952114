import React from "react";
import {ReactComponent as DownArrow} from "../../../assets/DownArrow.svg";
import {ReactComponent as UpArrow} from "../../../assets/UpArrow.svg";
import {DoubleBackArrow} from "../../Icons";
import {DoubleForwardArrow} from "../../Icons";
import {MainColors} from "../../../constants/CommonProperties";

const ButtonField = ({
  className,
  isShowDropView,
  isDisabledNext,
  isDisabledPrev,
  onClickNext: clickNext,
  onClickPrev: clickPrev,
  onToogleDropView: toogleDropView,
}) => {
  return (
    <div className={className}>
      <button disabled={isShowDropView || isDisabledPrev} onClick={clickPrev}>
        <DoubleBackArrow
          width="16px"
          height="13px"
          fill={
            isShowDropView || isDisabledPrev
              ? MainColors.greyPalette.grey4
              : MainColors.purplePrimary.purplePrimary
          }
        />
      </button>
      <button disabled={isShowDropView || isDisabledNext} onClick={clickNext}>
        <DoubleForwardArrow
          width="16px"
          height="13px"
          fill={
            isShowDropView || isDisabledNext
              ? MainColors.greyPalette.grey4
              : MainColors.purplePrimary.purplePrimary
          }
        />
      </button>
      <button className="ml-2" onClick={toogleDropView}>
        {isShowDropView ? <UpArrow /> : <DownArrow />}
      </button>
    </div>
  );
};

export default ButtonField;
