import React from "react";
import Input from "../../Input/Input";
import {PencilIcon} from "../../Icons";
import MarkedAreaList from "./MarkedAreasList/MarkedAreasList";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {MainColors} from "../../../constants/CommonProperties";
import CustomDropDown from "../../CustomDropDown/CustomDropDown";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter} from "../../../utils";
import {VisibilityIcon} from "../../../components/Icons";
import RefWrapper from "../../RefWrapper";
import "./EditBox.scss";

const styles = {
  groupDropDown: {
    position: "absolute",
    top: 13,
    right: 16,
    fontSize: "10px",
    padding: "2.5px",
    backgroundColor: MainColors.greyPalette.grey3,
    color: MainColors.purplePrimary.purplePrimary,
    borderRadius: "2px",
    cursor: "pointer",
  },
  markedAreaList: {
    backgroundColor: "white",
    maxHeight: "70%",
    overflowY: "auto",
    overflowX: "none",
  },
};

const EditBox = React.forwardRef(
  (
    {
      isSaving,
      isSavingAndRecognize,
      templateUuid,
      isTemplateWereEdit,
      areas,
      onDocumentNameChanged: setDocumentName,
      highlightedAreas,
      document,
      groups,
      onGetGroups: getGroups,
      onCreateNewGroup: createNewGroup,
      onDeleteGroup: deleteGroup,
      onEditGroup: editGroup,
      onEditArea: editAreaHandler,
      onRemoveArea: removeAreaHandler,
      onSaveDocTemplate: saveDocTemplateHandler,
      onSaveDocTemplateAndRecognize: saveDocTemplateAndRecognizeHandler,
      onHighlightArea: highlightArea,
      onCancelSavingDocTemplate: cancelSavingDocumentHandler,
      onSetTemplateGroup: setTemplateGroup,
      onWizardNextStep: wizardNextStep,
    },
    ref
  ) => {
    const {t} = useTranslation();

    const mouseEnterHandler = (area) => {
      highlightArea(area.cid, "hover");
    };

    const mouseLeaveHandler = (area) => {
      highlightArea(null, "hover");
    };

    const mouseDownHandler = (area) => {
      highlightArea(area.cid, "click");
    };

    return (
      <div className={"edit-box__container"}>
        <div style={{height: "100%"}} ref={ref?.wizardFourthTargetRef}>
          <Input
            className="edit-box__input pt-2 pb-2 pr-3 pl-3"
            label={capitalizeFirstLetter(t("list-view.template-name"))}
            fontSize="20px"
            value={document.templateName}
            onChange={(e) => setDocumentName(e.target.value)}
            icon={
              <PencilIcon
                className="cursor-pointer icon-inside-input"
                width={16}
                height={16}
                fill={MainColors.greyPalette.grey6}
              />
            }
          />
          <CustomDropDown
            style={styles.groupDropDown}
            component={
              <span>
                {document.group?.name.toUpperCase() ||
                  t("dropdown.no-group").toLocaleUpperCase()}
              </span>
            }
            onGetGroups={getGroups}
            onSetGroup={setTemplateGroup}
            onCreateNewGroup={createNewGroup}
            onDeleteGroup={deleteGroup}
            onEditGroup={editGroup}
            onWizardNextStep={wizardNextStep}
            data={groups}
          />
          <MarkedAreaList
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            onMouseDown={mouseDownHandler}
            style={styles.markedAreaList}
            areas={areas}
            highlightedAreas={highlightedAreas}
            className="pt-3"
            onEditArea={editAreaHandler}
            onRemoveArea={removeAreaHandler}
          />
        </div>
        <div className="edit-box__button-field p-3">
          <Button
            size="sm"
            className="mr-3"
            color="link"
            onClick={cancelSavingDocumentHandler}
          >
            {t("edit-box.cancel-all-changes").toLocaleUpperCase()}
          </Button>

          <RefWrapper ref={ref?.wizardFifthTargetRef}>
            <Button
              size="sm"
              onClick={() => saveDocTemplateHandler("/templates")}
              color="primary"
              disabled={isSaving}
              className="mr-2"
            >
              {isSaving && <FontAwesomeIcon className="mr-2" spin icon={faCircleNotch} />}
              {t("edit-box.save-template").toLocaleUpperCase()}
            </Button>
          </RefWrapper>
          <Button
            disabled={isSavingAndRecognize}
            onClick={saveDocTemplateAndRecognizeHandler}
            size="sm"
            color="primary"
            className="d-flex align-items-center"
          >
            {isSavingAndRecognize ? (
              <FontAwesomeIcon className="mr-2" spin icon={faCircleNotch} />
            ) : (
              <VisibilityIcon
                style={{marginTop: "1px"}}
                className="mr-2"
                width="14px"
                height="14px"
                fill="white"
              />
            )}
            <span>{t("results.recognize").toLocaleUpperCase()}</span>
          </Button>
        </div>
      </div>
    );
  }
);

export default EditBox;
