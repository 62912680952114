import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {ResetScheme} from "../../formik/validate";
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {capitalize, capitalizeFirstLetter} from "../../utils";
import Tooltip from "../../components/Tooltip/Tooltip";
import {newPasswordRequest} from "../../store/actions/auth";
import "./Forms.scss";
import {ReactComponent as DocIcon} from "../../assets/DocIcon.svg";
import {useMediaQuery} from "../../hooks/useMediaQuery";

const PasswordReset = () => {
  const {uid, token} = useParams();

  const {t} = useTranslation();
  const history = useHistory();
  const isNonDesktop = useMediaQuery();

  //Attempting to refresh that component will lead to SignIn
  useEffect(() => {
    if (sessionStorage.getItem("passwordReload") === "true") {
      history.push("/signin");
    }
    sessionStorage.setItem("passwordReload", "true");
    return () => sessionStorage.removeItem("passwordReload");
  });

  const [apiErrors, setApiErrors] = useState({
    new_password1: "",
    new_password2: "",
  });

  const formik = useFormik({
    initialValues: {
      new_password1: "",
      new_password2: "",
    },
    validationSchema: ResetScheme,
  });

  const isSaveButtonDisabled =
    formik.values.new_password1 === "" ||
    formik.values.new_password2 === "" ||
    formik.errors.new_password1 ||
    formik.errors.new_password2;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApiErrors({});
    try {
      await newPasswordRequest({
        uid,
        token,
        new_password1: formik.values.new_password1,
        new_password2: formik.values.new_password2,
      });
      history.push({
        pathname: "/signin",
        state: {success: {message: t("reset-password.success")}},
      });
    } catch (err) {
      if (err.password) {
        setApiErrors({non_field_errors: err.password[0]});
      } else if (err.token) {
        setApiErrors({non_field_errors: err.token[0]});
      } else {
        setApiErrors({non_field_errors: err});
      }
    }
  };

  return (
    <Row noGutters className={"sign-form__row"}>
      <Col className="sign-form__col">
        <Form className={"sign-form__form"} onSubmit={handleSubmit}>
          <div>
            <div className="p-3 sign-form__header">
              {isNonDesktop && <DocIcon className="sign-form__logo" />}
              {isNonDesktop
                ? t("reset-password.reset-password").toLocaleUpperCase()
                : capitalize(t("reset-password.reset-password"))}
            </div>
            <div className="p-3 sign-form__body">
              <FormGroup>
                <Label for="new_password1">
                  {capitalize(t("reset-password.new-password"))}
                </Label>
                {isNonDesktop ? (
                  <>
                    <Input
                      invalid={
                        !!(
                          formik.touched.new_password1 &&
                          (formik.errors.new_password1 ||
                            (apiErrors.new_password1 && apiErrors.new_password1[0]))
                        )
                      }
                      valid={
                        formik.touched.new_password1 &&
                        (!formik.errors.new_password1 || !apiErrors.new_password1)
                      }
                      type="password"
                      name="new_password1"
                      id="new_password1"
                      autoComplete="off"
                      placeholder={capitalizeFirstLetter(
                        t("reset-password.new-password-placeholder")
                      )}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.new_password1}
                    />
                    <FormFeedback>
                      {t(
                        formik.touched.new_password1 &&
                          (formik.errors.new_password1 ||
                            (apiErrors.new_password1 && apiErrors.new_password1[0]))
                      )}
                    </FormFeedback>
                  </>
                ) : (
                  <Tooltip text={t("sign-up.password-tooltip")}>
                    <Input
                      invalid={
                        !!(
                          formik.touched.new_password1 &&
                          (formik.errors.new_password1 ||
                            (apiErrors.new_password1 && apiErrors.new_password1[0]))
                        )
                      }
                      valid={
                        formik.touched.new_password1 &&
                        (!formik.errors.new_password1 || !apiErrors.new_password1)
                      }
                      type="password"
                      name="new_password1"
                      id="new_password1"
                      autoComplete="off"
                      placeholder={capitalizeFirstLetter(
                        t("reset-password.new-password-placeholder")
                      )}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.new_password1}
                    />
                    <FormFeedback>
                      {t(
                        formik.touched.new_password1 &&
                          (formik.errors.new_password1 ||
                            (apiErrors.new_password1 && apiErrors.new_password1[0]))
                      )}
                    </FormFeedback>
                  </Tooltip>
                )}
              </FormGroup>
              <FormGroup style={{marginTop: "-10px"}}>
                <Label for="new_password2">
                  {capitalize(t("reset-password.confirm-new-password"))}
                </Label>
                {isNonDesktop ? (
                  <>
                    <Input
                      invalid={
                        !!(
                          formik.touched.new_password2 &&
                          (formik.errors.new_password2 ||
                            (apiErrors.new_password2 && apiErrors.new_password2[0]))
                        )
                      }
                      valid={
                        formik.touched.new_password2 &&
                        (!formik.errors.new_password2 || !apiErrors.new_password2)
                      }
                      type="password"
                      name="new_password2"
                      id="new_password2"
                      autoComplete="off"
                      placeholder={capitalizeFirstLetter(
                        t("reset-password.confirm-password-placeholder")
                      )}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.new_password2}
                    />
                    <FormFeedback>
                      {t(
                        formik.touched.new_password2 &&
                          (formik.errors.new_password2 ||
                            (apiErrors.new_password2 && apiErrors.new_password2[0]))
                      )}
                    </FormFeedback>
                  </>
                ) : (
                  <Tooltip text={t("sign-up.password-tooltip")}>
                    <Input
                      invalid={
                        !!(
                          formik.touched.new_password2 &&
                          (formik.errors.new_password2 ||
                            (apiErrors.new_password2 && apiErrors.new_password2[0]))
                        )
                      }
                      valid={
                        formik.touched.new_password2 &&
                        (!formik.errors.new_password2 || !apiErrors.new_password2)
                      }
                      type="password"
                      name="new_password2"
                      id="new_password2"
                      autoComplete="off"
                      placeholder={capitalizeFirstLetter(
                        t("reset-password.confirm-password-placeholder")
                      )}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.new_password2}
                    />
                    <FormFeedback>
                      {t(
                        formik.touched.new_password2 &&
                          (formik.errors.new_password2 ||
                            (apiErrors.new_password2 && apiErrors.new_password2[0]))
                      )}
                    </FormFeedback>
                  </Tooltip>
                )}
              </FormGroup>
              {apiErrors.non_field_errors && (
                <div className="mt-4 text-danger text-center">
                  {t(apiErrors.non_field_errors)}
                </div>
              )}
            </div>
          </div>
          <div className={`d-flex justify-content-end sign-form__footer`}>
            <Button
              style={isNonDesktop ? {width: "100%"} : null}
              size="sm"
              color="primary"
              className="sign-form__button"
              disabled={isSaveButtonDisabled}
            >
              {t("reset-password.save").toLocaleUpperCase()}
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default PasswordReset;
