export const MainColors = {
  purplePrimary: {
    purplePrimary: "#5674EC",
    primaryBtClick: "#2B50E4",
    primaryBtHover: "#385CED",
    purplePrimary30: "rgba(86, 116, 236, 0.3)",
    purplePrimary10: "#rgba(86, 116, 236, 0.3)",
    purpleClick: "#CFD4E3",
    purpleHover: "#F0F2F7",
  },
  greyPalette: {
    typeBlack: "#202022",
    grey6: "#646970",
    grey5: "#90949B",
    grey4: "#C2C6CC",
    grey3: "#DBDFE4",
    grey2: "#E6E8ED",
    grey1: "#F2F3F5",
    white: "#FFFFFF",
    white80: "rgba(255,255,255,0.8)",
    white40: "rgba(255,255,255,0.4)",
  },
  systemPalette: {
    error: "#E22134",
    success: "#1DB977",
    warning: "#F2C03F",
  },
};
