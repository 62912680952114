import React from "react";

const OVERLAY_STROKE = 2000;

const TargetCircle = ({className, cx, cy, innerRadius, targetNode, isShow}) => {
  return isShow ? (
    <circle
      className={className}
      cx={cx}
      cy={cy}
      r={targetNode ? innerRadius + OVERLAY_STROKE / 2 : 1000}
      stroke="rgba(0,0,0, .5)"
      strokeWidth={OVERLAY_STROKE}
      fill="none"
    />
  ) : null;
};

export default TargetCircle;
