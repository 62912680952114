import React, {useState, useRef} from "react";
import {ListGroup, ListGroupItem} from "reactstrap";
import {MainColors} from "../../constants/CommonProperties";
import "./CustomSearchDropDown.scss";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import {SearchIcon} from "../Icons";
import {useTranslation} from "react-i18next";
import {capitalize} from "../../utils";

const CustomSearchDropDown = ({
  component,
  style,
  data,
  onSetActive: setActive,
  onChoose: setValue,
}) => {
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dropDownRef = useRef();
  const {t} = useTranslation();

  useOnClickOutside(dropDownRef, () => {
    setIsShowDropDown(false);
    setActive(false);
  });

  const chooseItemHandler = (value) => {
    setIsShowDropDown(false);
    setValue(value);
    setActive(false);
  };

  const clickHandler = () => {
    setIsShowDropDown(true);
    setActive(true);
  };

  return (
    <div style={style}>
      <div onClick={() => clickHandler()} className="position-relative">
        {component}
      </div>
      {isShowDropDown && (
        /*----------------------All existing groups-------------------*/
        <div ref={dropDownRef}>
          <ListGroup className={"list-group"}>
            <ListGroupItem className="pb-2 pr-2 pl-2 pt-3 list-group__list-item">
              <input
                className={"list-item__input"}
                type="text"
                placeholder={capitalize(t("dropdown.search"))}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              {!searchValue && (
                <SearchIcon
                  className={"list-item__search-icon"}
                  width="16px"
                  height="16px"
                  fill={MainColors.greyPalette.grey5}
                />
              )}
            </ListGroupItem>
            {data.length ? (
              data.map((item) => (
                <ListGroupItem
                  key={item.id}
                  onClick={() => chooseItemHandler(item.id)}
                  className="p-2 border-0 list-item__dropdown-item"
                >
                  {item.name}
                </ListGroupItem>
              ))
            ) : (
              <div className="pb-3 pt-4 pr-4 pl-4 list-item__no-data-placeholder">
                {t("dropdown.no-templates")}
              </div>
            )}
          </ListGroup>
        </div>
      )}
    </div>
  );
};

export default CustomSearchDropDown;
