import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalHeader, Input} from "reactstrap";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import "./SupportModal.scss";
import {useTranslation} from "react-i18next";
import {ReactComponent as DocManIcon} from "../../../assets/SupportIllustrations/docman.svg";
import CustomPaypalButton from "./CustomPaypalButton";

const SupportModal = ({toggle, modal}) => {
  const {t} = useTranslation();
  const [paid, setPaid] = useState(false);
  const [donation, setDonation] = useState(1);

  return (
    <Modal className={"support-modal__container"} centered isOpen={modal} toggle={toggle}>
      <ModalHeader className={"support-modal__header"} toggle={toggle}>
        {t("support.title")}
      </ModalHeader>
      <ModalBody>
        <div className={"support-modal__content-container"}>
          <div className={"support-modal__content-container__description"}>
            <DocManIcon className={"support-modal__docman-icon"} />
            <div>
              {paid ? t("support.success") : t("support.description-part-1")}{" "}
              <div className="m-0">{paid ? null : t("support.description-part-2")}</div>
            </div>
          </div>
          {!paid ? (
            <div className={"support-modal__set-paid-container"}>
              <span>Please enter a sum of your donation</span>
              <span className="support-modal__input-symbol">
                <Input
                  min={1}
                  value={donation}
                  onChange={(e) => {
                    e.target.value === "" ? setDonation(1) : setDonation(e.target.value);
                  }}
                  type={"number"}
                />
              </span>
            </div>
          ) : null}
        </div>
        <div className={"support-modal__buttons-container"}>
          {paid ? (
            <Button className={"support-modal__ok-button"} onClick={toggle}>
              Ok
            </Button>
          ) : (
            <>
              <Button onClick={toggle} className={"support-modal__cancel-button"}>
                {t("modal.cancel")}
              </Button>
              <PayPalScriptProvider
                options={{
                  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                  currency: "USD",
                }}
              >
                <CustomPaypalButton setPaid={setPaid} donationValue={donation} />
              </PayPalScriptProvider>
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SupportModal;
