import {createSlice} from "@reduxjs/toolkit";
import {getGroups, deleteGroup} from "./actions";

// придумать что-то с setUserTokenExpired - он используется практически во всех экшнах

export const groupsV2 = createSlice({
  name: "groupsV2",
  initialState: {
    isLoading: false,
    errorText: null,
    results: [],
  },
  extraReducers: {
    [getGroups.pending]: (state) => {
      state.isLoading = true;
    },
    [getGroups.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.results = action.payload.results;
      state.errorText = null;
    },
    [getGroups.rejected]: (state, action) => {
      state.isPending = false;
      state.errorText = action.error.message;
    },
    [deleteGroup.pending]: (state, action) => {
      const deletedGroupId = action.meta.arg;
      state.results = state.results.filter((group) => group.id !== deletedGroupId);
    },
  },
});

// export const {setUserTokenExpired, loginUserByLocalStorage} = groupsV2.actions;

export default groupsV2.reducer;
