import * as ActionsTypes from "../../constants/ActionsTypes";
import {ControlPanelTools} from "../../constants/EditorConsts";

const initialState = {
  isLoading: false,
  errorText: null,
  currTool: ControlPanelTools.SELECTING,
  selectedSectionId: null,
  areasCidCounter: 0,
  tplId: null,
  document: null,
  areas: [],
  highlightedAreas: {
    hovered: null,
    selected: null,
  },
};

let areas = [];

export default function docTemplateEditor(state = initialState, action) {
  switch (action.type) {
    case ActionsTypes.CLEAR_ERROR_TEXT:
      return {...state, errorText: null};
    //// CREATE_NEW_DOCUMENT ////////////////////////
    case ActionsTypes.CREATE_NEW_DOCUMENT:
      if (!action.data) return state;

      return {
        ...state,
        document: {
          width: action.data.image.width,
          height: action.data.image.height,
          image: action.data.image,
          imageBlob: action.data.imageBlob,
          fileName: action.data.fileName,
          templateName: "Template name #",
          group: null,
        },
        areas: [],
      };

    case ActionsTypes.SET_TEMPLATE_GROUP:
      return {...state, document: {...state.document, group: action.group}};
    //// CREATE_NEW_AREA /////////////////////////
    case ActionsTypes.CREATE_NEW_AREA:
      const areaParams = action.areaParams;
      const cid = `area_${state.areasCidCounter}`;
      return {
        ...state,
        areas: [...state.areas, {cid, ...areaParams}],
        areasCidCounter: state.areasCidCounter + 1,
        highlightedAreas: {...state.highlightedAreas, selected: cid},
      };
    ////
    /////////////////
    case ActionsTypes.SET_CONTROL_PANEL_TOOL:
      return {...state, currTool: action.tool};
    //// REQUEST_TEMPLATE /////////////////
    case ActionsTypes.REQUEST_TEMPLATE:
      return {...state, isLoading: true, errorText: null};
    //// SET_TEMPLATE_DATA/////////////////
    case ActionsTypes.SET_TEMPLATE_DATA:
      const template = action.template;
      const img = action.img;
      const template_region_coordinates = template.template_region_coordinates.map(
        (region, index) => {
          region.cid = `area_${index}`;
          return region;
        }
      );

      return {
        ...state,
        isLoading: false,
        tplId: action.tplId,
        areas: template_region_coordinates,
        document: {
          width: img.width,
          height: img.height,
          image: img,
          templateName: template.name,
          group: template.group,
        },
        areasCidCounter: template_region_coordinates.length,
      };
    //// SET_TEMPLATE_LOADING_ERROR /////////////////
    case ActionsTypes.SET_TEMPLATE_LOADING_ERROR:
      return {...state, isLoading: false, errorText: action.errorText};

    //// CLEAR_DOCUMENT_DATA /////////////////
    case ActionsTypes.CLEAR_DOCUMENT_DATA:
      return {...state, isLoading: false, tplId: null, document: null, areas: []};
    //// REMOVE_AREA /////////////////////////
    case ActionsTypes.REMOVE_AREA:
      areas = state.areas.filter((area) => area.cid !== action.area.cid);
      return {...state, areas};

    //// MOVE_AREA /////////////////////////
    case ActionsTypes.MOVE_AREA:
      areas = state.areas.map((area) =>
        area.cid === action.cid ? {...area, ...action.areaParams} : area
      );
      return {...state, areas};

    //// RESIZE_AREA /////////////////////////
    case ActionsTypes.RESIZE_AREA:
      areas = state.areas.map((area) =>
        area.cid === action.cid ? {...area, ...action.areaParams} : area
      );
      return {...state, areas};

    //// EDIT_AREA /////////////////////////
    case ActionsTypes.EDIT_AREA:
      areas = state.areas.map((area) =>
        area.cid === action.areaParams.area.cid
          ? {...area, ...action.areaParams.data}
          : area
      );
      return {...state, areas};

    ////HIGHLIGHT AREA////////////
    case ActionsTypes.HIGHLIGHT_AREA:
      if (action.eventType === "hover") {
        return {
          ...state,
          highlightedAreas: {
            ...state.highlightedAreas,
            hovered: action.areaId,
          },
        };
      }
      if (action.eventType === "click") {
        return {
          ...state,
          highlightedAreas: {
            ...state.highlightedAreas,
            selected: action.areaId,
          },
        };
      }
      return state;
    ///////CLEAR HIGHLIGHTED AREAS/////////////////
    case ActionsTypes.CLEAR_ALL_HIGHLIGHTED_AREAS:
      return {
        ...state,
        highlightedAreas: {
          hovered: null,
          selected: null,
        },
      };

    //// SET_DOCUMENT_NAME /////////////////////////
    case ActionsTypes.SET_DOCUMENT_NAME:
      return {
        ...state,
        document: {
          ...state.document,
          templateName: action.templateName,
        },
      };
    /// DEFAULT STATE //////////////////////////////////////////
    default:
      return state;
  }
}
