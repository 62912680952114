import React from "react";
import {Button} from "reactstrap";
import {BackAction, ForwardAction} from "../../Icons";
import {MainColors} from "../../../constants/CommonProperties";
import "./ActionsManagePanel.scss";

const ActionsManagePanel = ({
  style,
  className = "",
  onReturnOneActionBack: returnOneActionBack,
  onReturnOneActionForward: returnOneActionForward,
  ...props
}) => {
  return (
    <div style={style} className={`${className} manage-actions-panel`} {...props}>
      <Button onClick={returnOneActionBack} className="manage-actions-panel__left-button">
        <BackAction width="20px" height="20px" fill={MainColors.greyPalette.grey5} />
      </Button>
      <Button
        onClick={returnOneActionForward}
        className="manage-actions-panel__right-button"
      >
        <ForwardAction width="20px" height="20px" fill={MainColors.greyPalette.grey5} />
      </Button>
    </div>
  );
};

export default ActionsManagePanel;
