import React from "react";
import {ViewModuleIcon, ViewListIcon} from "../../Icons";
import {viewTypes} from "../../TemplatesList/TemplatesList";
import "./ToogleView.scss";

const ToogleView = ({className, view, onChooseView: viewChooseHandler}) => {
  return (
    <div className={`${className} toogle-view-component`}>
      <ViewListIcon
        onClick={() => viewChooseHandler(viewTypes.LIST)}
        className={
          "cursor-pointer mr-2 toogle-view-component__view-list-icon " +
          (view === viewTypes.LIST
            ? "toogle-view-component__view-list-icon_selected"
            : "")
        }
      />
      <ViewModuleIcon
        onClick={() => viewChooseHandler(viewTypes.MODULE)}
        className={
          "cursor-pointer toogle-view-component__view-list-icon " +
          (view === viewTypes.MODULE
            ? "toogle-view-component__view-list-icon_selected"
            : "")
        }
      />
    </div>
  );
};

export default ToogleView;
