import React from "react";
import "./HelperText.scss";
import {useTranslation} from "react-i18next";

const HelperText = ({
  className,
  cx,
  cy,
  helperTextList,
  isLastAction,
  onSkipTutorial: skipTutorial,
  onNextStep: nextStep,
  isPointerAnimationEnd,
}) => {
  const {t} = useTranslation();

  return helperTextList ? (
    <div className={`${className} helper-text`} style={{top: `${cy}%`, left: `${cx}%`}}>
      {helperTextList.map((helperText, index) => (
        <p
          key={`id${index}`}
          style={helperTextList.length === 1 && !nextStep ? {margin: 0} : null}
        >
          {helperText}
        </p>
      ))}
      {nextStep && (
        <button
          disabled={!isPointerAnimationEnd}
          className="helper-text__next-step-button"
          onClick={nextStep}
        >
          {t("wizard.next-button").toLocaleUpperCase()}
        </button>
      )}
      {isLastAction && (
        <button
          disabled={!isPointerAnimationEnd}
          className="helper-text__finish-button"
          onClick={skipTutorial}
        >
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mb-1 mr-2"
          >
            <path
              d="M1.00002 7.00002L14.17 7.00002L11.29 9.88002C10.9 10.27 10.9 10.9 11.29 11.29C11.68 11.68 12.31 11.68 12.7 11.29L17.29 6.70002C17.68 6.31002 17.68 5.68002 17.29 5.29002L12.7 0.70002C12.31 0.31002 11.68 0.31002 11.29 0.70002C10.9 1.09002 10.9 1.72002 11.29 2.11002L14.17 5.00002L1.00002 5.00002C0.45002 5.00002 1.96096e-05 5.45002 1.95615e-05 6.00002C1.95134e-05 6.55002 0.45002 7.00002 1.00002 7.00002Z"
              fill="white"
            />
          </svg>
          {t("wizard.finish-button").toLocaleUpperCase()}
        </button>
      )}
    </div>
  ) : null;
};

export default HelperText;
