import React from "react";
import {PencilIcon} from "../../../Icons";
import {CloseIcon} from "../../../Icons";
import {MainColors} from "../../../../constants/CommonProperties";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter} from "../../../../utils";
import "./MarkedAreasList.scss";
import TextareaAutosize from "react-textarea-autosize";

const MarkedAreaList = ({
  areas,
  className,
  style,
  highlightedAreas,
  onEditArea: editAreaHandler,
  onRemoveArea: removeAreaHandler,
  onMouseEnter: mouseEnterHandler,
  onMouseLeave: mouseLeaveHandler,
  onMouseDown: mouseDownHandler,
}) => {
  const {t} = useTranslation();

  const styles = {
    hoveredLi: {
      padding: "5px 0",
      borderBottom: `1px solid ${MainColors.purplePrimary.purplePrimary}`,
      backgroundColor: MainColors.purplePrimary.purpleHover,
      color: MainColors.greyPalette.typeBlack,
      // marginTop: "-1px",
    },
    selectedLi: {
      padding: "5px 0",
      borderBottom: `1px solid ${MainColors.purplePrimary.purplePrimary}`,
      backgroundColor: MainColors.purplePrimary.purpleClick,
      // marginTop: "-1px",
      color: MainColors.greyPalette.typeBlack,
    },
    notHighlightedLi: {
      padding: "5px 0",
      borderBottom: `1px solid ${MainColors.greyPalette.grey2}`,
      color: MainColors.greyPalette.typeBlack,
    },
  };

  const getStyle = (area) => {
    if (highlightedAreas.selected === area.cid) return styles.selectedLi;
    if (highlightedAreas.hovered === area.cid) return styles.hoveredLi;
    return styles.notHighlightedLi;
  };

  return (
    <div id="market-area-list" className={className} style={style}>
      <label className="pl-3 marked-area-list__label">
        {capitalizeFirstLetter(t("marked-area.marked-areas"))}
      </label>
      {areas.length ? (
        <div className="marked-area-list__list-container">
          {areas.map((area) => (
            <div
              style={{...getStyle(area)}}
              className="marked-area-list__list-item pr-3 pl-3 pt-2 pb-2 body-regular"
            >
              {/* <input
                data-area=""
                onMouseEnter={() => mouseEnterHandler(area)}
                onMouseLeave={() => mouseLeaveHandler(area)}
                onMouseDown={() => mouseDownHandler(area)}
                key={area.cid}
                value={area.name}
              /> */}
              <TextareaAutosize
                autoFocus
                readOnly
                onMouseEnter={() => mouseEnterHandler(area)}
                onMouseLeave={() => mouseLeaveHandler(area)}
                onMouseDown={() => mouseDownHandler(area)}
                value={area.name}
                maxLength="50"
                key={area.cid}
                data-area=""
              />
              <div>
                <PencilIcon
                  className="mr-3 cursor-pointer"
                  width={16}
                  height={16}
                  fill={MainColors.greyPalette.grey6}
                  onClick={() => editAreaHandler(area)}
                />
                <CloseIcon
                  className="cursor-pointer"
                  witdh={12}
                  height={12}
                  fill={MainColors.greyPalette.grey6}
                  onClick={() => removeAreaHandler(area)}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="pl-3 ">{capitalizeFirstLetter(t("marked-area.no-area"))}</p>
      )}
    </div>
  );
};

export default MarkedAreaList;
