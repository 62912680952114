import React from "react";

export const BackAction = ({
  width,
  height,
  fill,
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33335 7.49999V6.17499C8.33335 5.43333 7.43335 5.05833 6.90835 5.58333L3.08335 9.40833C2.75835 9.73333 2.75835 10.2583 3.08335 10.5833L6.90835 14.4083C7.43335 14.9333 8.33335 14.5667 8.33335 13.825V12.4167C12.5 12.4167 15.4167 13.75 17.5 16.6667C16.6667 12.5 14.1667 8.33333 8.33335 7.49999Z"
        fill="#C2C5CC"
      />
    </svg>
  );
};

export const ForwardAction = ({
  width,
  height,
  fill,
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6667 7.49999V6.17499C11.6667 5.43333 12.5667 5.05833 13.0917 5.58333L16.9167 9.40833C17.2417 9.73333 17.2417 10.2583 16.9167 10.5833L13.0917 14.4083C12.5667 14.9333 11.6667 14.5667 11.6667 13.825V12.4167C7.49998 12.4167 4.58332 13.75 2.49998 16.6667C3.33332 12.5 5.83332 8.33333 11.6667 7.49999Z"
        fill="#C2C5CC"
      />
    </svg>
  );
};

export const DoubleForwardArrow = ({
  width,
  height,
  fill,
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        d="M5.22 12.42L6.4 13.6L13 6.99998L6.4 0.399984L5.22 1.57998L10.64 6.99998L5.22 12.42Z"
        fill={fill}
      />
      <path
        d="M0.22 12.42L1.4 13.6L8 6.99998L1.4 0.399984L0.219999 1.57998L5.64 6.99998L0.22 12.42Z"
        fill={fill}
      />
    </svg>
  );
};

export const DoubleBackArrow = ({
  width,
  height,
  fill,
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        d="M8.10892 1.77126L6.9584 0.562494L0.197501 6.99757L6.63258 13.7585L7.84135 12.608L2.55678 7.05582L8.10892 1.77126Z"
        fill={fill}
      />
      <path
        d="M13.1074 1.89467L11.9569 0.685907L5.19597 7.12098L11.6311 13.8819L12.8398 12.7314L7.55526 7.17924L13.1074 1.89467Z"
        fill={fill}
      />
    </svg>
  );
};

export const ViewListIcon = ({
  width,
  height,
  fill,
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      onClick={clickHandler}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66667 9.33325H4C4.36667 9.33325 4.66667 9.03325 4.66667 8.66659V7.33325C4.66667 6.96659 4.36667 6.66659 4 6.66659H2.66667C2.3 6.66659 2 6.96659 2 7.33325V8.66659C2 9.03325 2.3 9.33325 2.66667 9.33325ZM2.66667 12.6666H4C4.36667 12.6666 4.66667 12.3666 4.66667 11.9999V10.6666C4.66667 10.2999 4.36667 9.99992 4 9.99992H2.66667C2.3 9.99992 2 10.2999 2 10.6666V11.9999C2 12.3666 2.3 12.6666 2.66667 12.6666ZM2.66667 5.99992H4C4.36667 5.99992 4.66667 5.69992 4.66667 5.33325V3.99992C4.66667 3.63325 4.36667 3.33325 4 3.33325H2.66667C2.3 3.33325 2 3.63325 2 3.99992V5.33325C2 5.69992 2.3 5.99992 2.66667 5.99992ZM6 9.33325H12.6667C13.0333 9.33325 13.3333 9.03325 13.3333 8.66659V7.33325C13.3333 6.96659 13.0333 6.66659 12.6667 6.66659H6C5.63333 6.66659 5.33333 6.96659 5.33333 7.33325V8.66659C5.33333 9.03325 5.63333 9.33325 6 9.33325ZM6 12.6666H12.6667C13.0333 12.6666 13.3333 12.3666 13.3333 11.9999V10.6666C13.3333 10.2999 13.0333 9.99992 12.6667 9.99992H6C5.63333 9.99992 5.33333 10.2999 5.33333 10.6666V11.9999C5.33333 12.3666 5.63333 12.6666 6 12.6666ZM5.33333 3.99992V5.33325C5.33333 5.69992 5.63333 5.99992 6 5.99992H12.6667C13.0333 5.99992 13.3333 5.69992 13.3333 5.33325V3.99992C13.3333 3.63325 13.0333 3.33325 12.6667 3.33325H6C5.63333 3.33325 5.33333 3.63325 5.33333 3.99992Z"
        fill={fill}
      />
    </svg>
  );
};

export const TemplateNotFoundIcon = ({
  width,
  height,
  fill,
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      className={className}
      style={style}
      onClick={clickHandler}
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 12H13.71L13.43 11.73C14.63 10.33 15.25 8.42003 14.91 6.39003C14.44 3.61003 12.12 1.40003 9.33003 1.05003C5.54003 0.580026 2.30003 3.38003 2.03003 7.00003H4.05003C4.29003 4.88003 5.97003 3.20003 8.11003 3.02003C10.65 2.80003 13 4.95003 13 7.50003C13 9.99003 10.99 12 8.50003 12C8.33003 12 8.17003 11.97 8.00003 11.95V13.97C8.00003 13.97 8.00003 13.97 8.01003 13.98C9.81003 14.11 11.48 13.51 12.73 12.43L13 12.71V13.5L17.25 17.75C17.66 18.16 18.33 18.16 18.74 17.75C19.15 17.34 19.15 16.67 18.74 16.26L14.5 12Z"
        fill="#E22134"
      />
      <path
        d="M5.12002 9.17002L3.00002 11.29L0.88002 9.17002C0.68002 8.97002 0.37002 8.97002 0.17002 9.17002C-0.0299805 9.37002 -0.0299805 9.68002 0.17002 9.88002L2.29002 12L0.17002 14.12C-0.0299805 14.32 -0.0299805 14.63 0.17002 14.83C0.37002 15.03 0.68002 15.03 0.88002 14.83L3.00002 12.71L5.12002 14.83C5.32002 15.03 5.63002 15.03 5.83002 14.83C6.03002 14.63 6.03002 14.32 5.83002 14.12L3.71002 12L5.83002 9.88002C6.03002 9.68002 6.03002 9.37002 5.83002 9.17002C5.63002 8.98002 5.32002 8.98002 5.12002 9.17002Z"
        fill={fill}
      />
    </svg>
  );
};

export const ViewModuleIcon = ({
  width,
  height,
  fill,
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      onClick={clickHandler}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33366 7.33325H5.33366C5.70033 7.33325 6.00033 7.03325 6.00033 6.66659V3.99992C6.00033 3.63325 5.70033 3.33325 5.33366 3.33325H3.33366C2.96699 3.33325 2.66699 3.63325 2.66699 3.99992V6.66659C2.66699 7.03325 2.96699 7.33325 3.33366 7.33325ZM3.33366 11.9999H5.33366C5.70033 11.9999 6.00033 11.6999 6.00033 11.3333V8.66659C6.00033 8.29992 5.70033 7.99992 5.33366 7.99992H3.33366C2.96699 7.99992 2.66699 8.29992 2.66699 8.66659V11.3333C2.66699 11.6999 2.96699 11.9999 3.33366 11.9999ZM7.33366 11.9999H9.33366C9.70033 11.9999 10.0003 11.6999 10.0003 11.3333V8.66659C10.0003 8.29992 9.70033 7.99992 9.33366 7.99992H7.33366C6.96699 7.99992 6.66699 8.29992 6.66699 8.66659V11.3333C6.66699 11.6999 6.96699 11.9999 7.33366 11.9999ZM11.3337 11.9999H13.3337C13.7003 11.9999 14.0003 11.6999 14.0003 11.3333V8.66659C14.0003 8.29992 13.7003 7.99992 13.3337 7.99992H11.3337C10.967 7.99992 10.667 8.29992 10.667 8.66659V11.3333C10.667 11.6999 10.967 11.9999 11.3337 11.9999ZM7.33366 7.33325H9.33366C9.70033 7.33325 10.0003 7.03325 10.0003 6.66659V3.99992C10.0003 3.63325 9.70033 3.33325 9.33366 3.33325H7.33366C6.96699 3.33325 6.66699 3.63325 6.66699 3.99992V6.66659C6.66699 7.03325 6.96699 7.33325 7.33366 7.33325ZM10.667 3.99992V6.66659C10.667 7.03325 10.967 7.33325 11.3337 7.33325H13.3337C13.7003 7.33325 14.0003 7.03325 14.0003 6.66659V3.99992C14.0003 3.63325 13.7003 3.33325 13.3337 3.33325H11.3337C10.967 3.33325 10.667 3.63325 10.667 3.99992Z"
        fill={fill}
      />
    </svg>
  );
};
export const PlusIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      onClick={clickHandler}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66671 5.66671H5.66671V9.66671H4.33337V5.66671H0.333374V4.33337H4.33337V0.333374H5.66671V4.33337H9.66671V5.66671Z"
        fill={fill}
      />
    </svg>
  );
};

export const MinusIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      onClick={clickHandler}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 10 2"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.66671 1.66671H0.333374V0.333374H9.66671V1.66671Z" fill={fill} />
    </svg>
  );
};

export const UploadIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      onClick={clickHandler}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 21 18"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 10.5V16.5H19.5V10.5" stroke={fill} strokeWidth="2" />
      <path d="M10.5 0V10.5" stroke={fill} strokeWidth="2" />
      <path d="M6.5 7L10.5 11L14.5 7" stroke={fill} strokeWidth="2" />
    </svg>
  );
};

export const SelectIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        d="M23 6V2C23 1.45 22.55 1 22 1H18C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1H2C1.45 1 1 1.45 1 2V6C1 6.55 1.45 7 2 7H3V17H2C1.45 17 1 17.45 1 18V22C1 22.55 1.45 23 2 23H6C6.55 23 7 22.55 7 22V21H17V22C17 22.55 17.45 23 18 23H22C22.55 23 23 22.55 23 22V18C23 17.45 22.55 17 22 17H21V7H22C22.55 7 23 6.55 23 6ZM3 3H5V5H3V3ZM5 21H3V19H5V21ZM17 19H7V18C7 17.45 6.55 17 6 17H5V7H6C6.55 7 7 6.55 7 6V5H17V6C17 6.55 17.45 7 18 7H19V17H18C17.45 17 17 17.45 17 18V19ZM21 21H19V19H21V21ZM19 5V3H21V5H19ZM12.94 7.65C12.79 7.26 12.41 7 11.99 7C11.57 7 11.19 7.26 11.05 7.65L8.28 14.98C8.09 15.47 8.45 16 8.98 16C9.3 16 9.58 15.8 9.69 15.5L10.24 14H13.73L14.29 15.51C14.4 15.8 14.68 16 15 16H15.01C15.54 16 15.9 15.47 15.72 14.98L12.94 7.65ZM10.69 12.74L12 8.91L13.3 12.74H10.69Z"
        fill={fill}
      />
    </svg>
  );
};

export const ResizeIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      onClick={clickHandler}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2083 3.20833L14.4167 4.41667L12.6 6.21667C12.275 6.54167 12.275 7.075 12.6 7.4C12.925 7.725 13.4583 7.725 13.7833 7.4L15.5833 5.58333L16.7917 6.79167C17.05 7.05 17.5 6.86667 17.5 6.49167V2.91667C17.5 2.68333 17.3167 2.5 17.0833 2.5H13.5083C13.1333 2.5 12.95 2.95 13.2083 3.20833ZM3.20833 6.79167L4.41667 5.58333L6.21667 7.4C6.54167 7.725 7.075 7.725 7.4 7.4C7.725 7.075 7.725 6.54167 7.4 6.21667L5.58333 4.41667L6.79167 3.20833C7.05 2.95 6.86667 2.5 6.49167 2.5H2.91667C2.68333 2.5 2.5 2.68333 2.5 2.91667V6.49167C2.5 6.86667 2.95 7.05 3.20833 6.79167ZM6.79167 16.7917L5.58333 15.5833L7.4 13.7833C7.725 13.4583 7.725 12.925 7.4 12.6C7.075 12.275 6.54167 12.275 6.21667 12.6L4.41667 14.4167L3.20833 13.2083C2.95 12.95 2.5 13.1333 2.5 13.5083V17.0833C2.5 17.3167 2.68333 17.5 2.91667 17.5H6.49167C6.86667 17.5 7.05 17.05 6.79167 16.7917ZM16.7917 13.2083L15.5833 14.4167L13.7833 12.6C13.4583 12.275 12.925 12.275 12.6 12.6C12.275 12.925 12.275 13.4583 12.6 13.7833L14.4167 15.5833L13.2083 16.7917C12.95 17.05 13.1333 17.5 13.5083 17.5H17.0833C17.3167 17.5 17.5 17.3167 17.5 17.0833V13.5083C17.5 13.1333 17.05 12.95 16.7917 13.2083Z"
        fill={fill}
      />
    </svg>
  );
};

export const MoveIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        d="M21.5001 4C20.6701 4 20.0001 4.67 20.0001 5.5V10.5C20.0001 10.78 19.7801 11 19.5001 11C19.2201 11 19.0001 10.78 19.0001 10.5V2.5C19.0001 1.67 18.3301 1 17.5001 1C16.6701 1 16.0001 1.67 16.0001 2.5V10.5C16.0001 10.78 15.7801 11 15.5001 11C15.2201 11 15.0001 10.78 15.0001 10.5V1.5C15.0001 0.67 14.3301 0 13.5001 0C12.6701 0 12.0001 0.67 12.0001 1.5V10.49C12.0001 10.77 11.7801 10.99 11.5001 10.99C11.2201 10.99 11.0001 10.77 11.0001 10.49V4.5C11.0001 3.67 10.3301 3 9.50012 3C8.67012 3 8.00012 3.67 8.00012 4.5V15.91L3.88012 13.56C3.30012 13.23 2.58012 13.32 2.10012 13.78C1.50012 14.36 1.48012 15.32 2.07012 15.91L8.85012 22.8C9.60012 23.57 10.6201 24 11.7001 24H19.0001C21.2101 24 23.0001 22.21 23.0001 20V5.5C23.0001 4.67 22.3301 4 21.5001 4Z"
        fill={fill}
      />
    </svg>
  );
};

export const EditIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        d="M4.04304 3.28339L16.2764 8.40005C16.968 8.69172 16.9514 9.67506 16.2597 9.94172L11.8097 11.6667C11.593 11.7501 11.418 11.9251 11.3347 12.1417L9.61804 16.5834C9.35137 17.2834 8.35971 17.3001 8.06804 16.6084L2.94304 4.38339C2.66804 3.69172 3.35971 3.00005 4.04304 3.28339Z"
        fill={fill}
      />
    </svg>
  );
};

export const RemoveIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        d="M4.99996 15.8333C4.99996 16.75 5.74996 17.5 6.66663 17.5H13.3333C14.25 17.5 15 16.75 15 15.8333V7.5C15 6.58333 14.25 5.83333 13.3333 5.83333H6.66663C5.74996 5.83333 4.99996 6.58333 4.99996 7.5V15.8333ZM15 3.33333H12.9166L12.325 2.74167C12.175 2.59167 11.9583 2.5 11.7416 2.5H8.25829C8.04163 2.5 7.82496 2.59167 7.67496 2.74167L7.08329 3.33333H4.99996C4.54163 3.33333 4.16663 3.70833 4.16663 4.16667C4.16663 4.625 4.54163 5 4.99996 5H15C15.4583 5 15.8333 4.625 15.8333 4.16667C15.8333 3.70833 15.4583 3.33333 15 3.33333Z"
        fill={fill}
      />
    </svg>
  );
};

export const CancelIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99984 1.16675C3.774 1.16675 1.1665 3.77425 1.1665 7.00008C1.1665 10.2259 3.774 12.8334 6.99984 12.8334C10.2257 12.8334 12.8332 10.2259 12.8332 7.00008C12.8332 3.77425 10.2257 1.16675 6.99984 1.16675ZM9.50817 9.50842C9.28067 9.73592 8.91317 9.73592 8.68567 9.50842L6.99984 7.82258L5.314 9.50842C5.0865 9.73592 4.719 9.73592 4.4915 9.50842C4.264 9.28092 4.264 8.91342 4.4915 8.68592L6.17734 7.00008L4.4915 5.31425C4.264 5.08675 4.264 4.71925 4.4915 4.49175C4.719 4.26425 5.0865 4.26425 5.314 4.49175L6.99984 6.17758L8.68567 4.49175C8.91317 4.26425 9.28067 4.26425 9.50817 4.49175C9.73567 4.71925 9.73567 5.08675 9.50817 5.31425L7.82234 7.00008L9.50817 8.68592C9.72984 8.90758 9.72984 9.28092 9.50817 9.50842Z"
        fill="#AAB5F0"
      />
    </svg>
  );
};

export const DropDownIcon = ({
  width,
  height,
  fill,
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      onClick={clickHandler}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00033 5.33341C8.73366 5.33341 9.33366 4.73341 9.33366 4.00008C9.33366 3.26675 8.73366 2.66675 8.00033 2.66675C7.26699 2.66675 6.66699 3.26675 6.66699 4.00008C6.66699 4.73341 7.26699 5.33341 8.00033 5.33341ZM8.00033 6.66675C7.26699 6.66675 6.66699 7.26675 6.66699 8.00008C6.66699 8.73341 7.26699 9.33341 8.00033 9.33341C8.73366 9.33341 9.33366 8.73341 9.33366 8.00008C9.33366 7.26675 8.73366 6.66675 8.00033 6.66675ZM8.00033 10.6667C7.26699 10.6667 6.66699 11.2667 6.66699 12.0001C6.66699 12.7334 7.26699 13.3334 8.00033 13.3334C8.73366 13.3334 9.33366 12.7334 9.33366 12.0001C9.33366 11.2667 8.73366 10.6667 8.00033 10.6667Z"
        fill={fill}
      />
    </svg>
  );
};

export const VisibilityIcon = ({
  width,
  title,
  height,
  fill,
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      onClick={clickHandler}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M8.00033 2.66663C4.66699 2.66663 1.82033 4.73996 0.666992 7.66663C1.82033 10.5933 4.66699 12.6666 8.00033 12.6666C11.3337 12.6666 14.1803 10.5933 15.3337 7.66663C14.1803 4.73996 11.3337 2.66663 8.00033 2.66663ZM8.00033 11C6.16033 11 4.66699 9.50663 4.66699 7.66663C4.66699 5.82663 6.16033 4.33329 8.00033 4.33329C9.84033 4.33329 11.3337 5.82663 11.3337 7.66663C11.3337 9.50663 9.84033 11 8.00033 11ZM8.00033 5.66663C6.89366 5.66663 6.00033 6.55996 6.00033 7.66663C6.00033 8.77329 6.89366 9.66663 8.00033 9.66663C9.10699 9.66663 10.0003 8.77329 10.0003 7.66663C10.0003 6.55996 9.10699 5.66663 8.00033 5.66663Z"
        fill={fill}
      />
    </svg>
  );
};

export const PencilIcon = ({
  width,
  height,
  title,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <title>{title}</title>
      <path
        d="M3 17.4601V20.5001C3 20.7801 3.22 21.0001 3.5 21.0001H6.54C6.67 21.0001 6.8 20.9501 6.89 20.8501L17.81 9.94006L14.06 6.19006L3.15 17.1001C3.05 17.2001 3 17.3201 3 17.4601ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z"
        fill={fill}
      />
    </svg>
  );
};

export const CloseIcon = ({
  width,
  height,
  title,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      onClick={clickHandler}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M9.66659 1.27337L8.72659 0.333374L4.99992 4.06004L1.27325 0.333374L0.333252 1.27337L4.05992 5.00004L0.333252 8.72671L1.27325 9.66671L4.99992 5.94004L8.72659 9.66671L9.66659 8.72671L5.93992 5.00004L9.66659 1.27337Z"
        fill={fill}
      />
    </svg>
  );
};

export const MoveToIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        d="M12.6667 2H3.32667C2.58667 2 2 2.6 2 3.33333V12.6667C2 13.4 2.58667 14 3.32667 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM12.6667 10H10.58C10.2667 10 10.0133 10.2267 9.92667 10.5333C9.69333 11.38 8.91333 12 8 12C7.08667 12 6.30667 11.38 6.07333 10.5333C5.98667 10.2267 5.73333 10 5.42 10H3.32667V4C3.32667 3.63333 3.62667 3.33333 3.99333 3.33333H12C12.3667 3.33333 12.6667 3.63333 12.6667 4V10ZM10.6667 6.66667H9.33333V4.66667H6.66667V6.66667H5.33333L7.76667 9.1C7.9 9.23333 8.10667 9.23333 8.24 9.1L10.6667 6.66667Z"
        fill={fill}
      />
    </svg>
  );
};

export const WarningIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        d="M7.99998 4.66683C8.36665 4.66683 8.66665 4.96683 8.66665 5.3335V8.00016C8.66665 8.36683 8.36665 8.66683 7.99998 8.66683C7.63331 8.66683 7.33331 8.36683 7.33331 8.00016V5.3335C7.33331 4.96683 7.63331 4.66683 7.99998 4.66683ZM7.99331 1.3335C4.31331 1.3335 1.33331 4.32016 1.33331 8.00016C1.33331 11.6802 4.31331 14.6668 7.99331 14.6668C11.68 14.6668 14.6666 11.6802 14.6666 8.00016C14.6666 4.32016 11.68 1.3335 7.99331 1.3335ZM7.99998 13.3335C5.05331 13.3335 2.66665 10.9468 2.66665 8.00016C2.66665 5.0535 5.05331 2.66683 7.99998 2.66683C10.9466 2.66683 13.3333 5.0535 13.3333 8.00016C13.3333 10.9468 10.9466 13.3335 7.99998 13.3335ZM8.66665 11.3335H7.33331V10.0002H8.66665V11.3335Z"
        fill={fill}
      />
    </svg>
  );
};

export const DeleteIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 18"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM11 6V16H3V6H11ZM1 4H13V16C13 17.1 12.1 18 11 18H3C1.9 18 1 17.1 1 16V4Z"
        fill={fill}
      />
    </svg>
  );
};

export const NoRecognitionResultsIcon = () => {
  return (
    <svg
      width="102"
      height="132"
      viewBox="0 0 102 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 1.5C3.96243 1.5 1.5 3.96243 1.5 7V125C1.5 128.038 3.96244 130.5 7 130.5H95C98.0376 130.5 100.5 128.038 100.5 125V29.6569C100.5 28.1982 99.9205 26.7992 98.8891 25.7678L76.2322 3.11091C75.2008 2.07946 73.8018 1.5 72.3431 1.5H7Z"
        fill="white"
        stroke="#5674EC"
        strokeWidth="3"
      />
      <circle
        cx="49.2041"
        cy="66.2041"
        r="16.5291"
        transform="rotate(-45 49.2041 66.2041)"
        fill="white"
        stroke="#5674EC"
        strokeWidth="4"
      />
      <path
        d="M58.7329 79.7026L62.7032 75.7323L75.8053 88.8344C76.9017 89.9307 76.9017 91.7083 75.8053 92.8047C74.7089 93.9011 72.9313 93.9011 71.835 92.8047L58.7329 79.7026Z"
        fill="#5674EC"
      />
      <path d="M74 3L99 28H78C75.7909 28 74 26.2091 74 24V3Z" fill="#5674EC" />
    </svg>
  );
};

export const NewRecognitionIcon = () => {
  return (
    <svg
      width="102"
      height="132"
      viewBox="0 0 102 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="1.5"
        width="99"
        height="129"
        rx="5.5"
        fill="white"
        stroke="#5674EC"
        strokeWidth="3"
      />
      <rect x="11" y="18" width="23" height="4" rx="2" fill="#90949B" />
      <rect x="40" y="18" width="35" height="4" rx="2" fill="#90949B" />
      <rect x="81" y="18" width="9" height="4" rx="2" fill="#90949B" />
      <rect x="11" y="30" width="38" height="4" rx="2" fill="#DBDFE4" />
      <rect x="55" y="30" width="35" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="42" width="23" height="4" rx="2" fill="#DBDFE4" />
      <rect x="40" y="42" width="50" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="54" width="29" height="4" rx="2" fill="#90949B" />
      <rect x="11" y="90" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="78" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="66" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="102" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="114" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="90" width="47" height="4" rx="2" fill="#DBDFE4" />
      <rect x="52" y="78" width="39" height="4" rx="2" fill="#DBDFE4" />
      <rect x="68" y="66" width="23" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="102" width="47" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="114" width="47" height="4" rx="2" fill="#DBDFE4" />
      <rect x="61" y="54" width="30" height="4" rx="2" fill="#90949B" />
      <path
        d="M24.3482 63.1668L23.6258 65L24.3482 66.8332C28.5328 77.4519 38.8697 85 51 85C63.1303 85 73.4672 77.4519 77.6518 66.8332L78.3742 65L77.6518 63.1668C73.4672 52.5481 63.1303 45 51 45C38.8697 45 28.5328 52.5481 24.3482 63.1668Z"
        stroke="white"
        strokeWidth="10"
      />
      <path
        d="M51 50C41 50 32.46 56.22 29 65C32.46 73.78 41 80 51 80C61 80 69.54 73.78 73 65C69.54 56.22 61 50 51 50ZM51 75C45.48 75 41 70.52 41 65C41 59.48 45.48 55 51 55C56.52 55 61 59.48 61 65C61 70.52 56.52 75 51 75ZM51 59C47.68 59 45 61.68 45 65C45 68.32 47.68 71 51 71C54.32 71 57 68.32 57 65C57 61.68 54.32 59 51 59Z"
        fill="#5674EC"
      />
    </svg>
  );
};

export const NewTemplateIcon = () => {
  return (
    <svg
      width="102"
      height="132"
      viewBox="0 0 102 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="1.5"
        width="99"
        height="129"
        rx="2.5"
        fill="white"
        stroke="#5674EC"
        strokeWidth="3"
      />
      <rect x="11" y="16" width="23" height="4" rx="2" fill="#5674EC" />
      <rect x="40" y="16" width="35" height="4" rx="2" fill="#90949B" />
      <rect x="81" y="16" width="9" height="4" rx="2" fill="#90949B" />
      <rect x="11" y="28" width="38" height="4" rx="2" fill="#DBDFE4" />
      <rect x="55" y="28" width="35" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="40" width="23" height="4" rx="2" fill="#DBDFE4" />
      <rect x="40" y="40" width="50" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="52" width="27" height="4" rx="2" fill="#90949B" />
      <rect x="11" y="88" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="76" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="64" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="100" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="112" width="27" height="4" rx="2" fill="#5674EC" />
      <rect x="44" y="88" width="47" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="76" width="47" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="64" width="47" height="4" rx="2" fill="#5674EC" />
      <rect x="44" y="100" width="47" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="112" width="47" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="52" width="47" height="4" rx="2" fill="#90949B" />
      <rect opacity="0.4" x="8" y="11" width="30" height="14" rx="1" fill="#5674EC" />
      <rect opacity="0.4" x="8" y="107" width="33" height="14" rx="1" fill="#5674EC" />
      <rect opacity="0.4" x="41" y="59" width="53" height="14" rx="1" fill="#5674EC" />
    </svg>
  );
};

export const FirstTemplateIcon = () => {
  return (
    <svg
      width="102"
      height="132"
      viewBox="0 0 102 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="1.5"
        width="99"
        height="129"
        rx="2.5"
        fill="white"
        stroke="#5674EC"
        strokeWidth="3"
      />
      <rect x="11" y="15" width="23" height="4" rx="2" fill="#90949B" />
      <rect x="40" y="15" width="35" height="4" rx="2" fill="#90949B" />
      <rect x="81" y="15" width="9" height="4" rx="2" fill="#90949B" />
      <rect x="11" y="27" width="38" height="4" rx="2" fill="#DBDFE4" />
      <rect x="55" y="27" width="35" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="39" width="23" height="4" rx="2" fill="#DBDFE4" />
      <rect x="40" y="39" width="50" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="51" width="27" height="4" rx="2" fill="#90949B" />
      <rect x="11" y="87" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="75" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="63" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="99" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="11" y="111" width="27" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="87" width="47" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="75" width="47" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="63" width="47" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="99" width="47" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="111" width="47" height="4" rx="2" fill="#DBDFE4" />
      <rect x="44" y="51" width="47" height="4" rx="2" fill="#90949B" />
      <rect x="3" y="87" width="96" height="25" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="51"
          y1="87"
          x2="51"
          y2="112"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5674EC" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ExportIcon = ({
  fill = "none",
  className,
  onClick: clickHandler,
  style,
  width,
  height,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        d="M6.66683 10.6665H9.3335C9.70016 10.6665 10.0002 10.3665 10.0002 9.99982V6.66648H11.0602C11.6535 6.66648 11.9535 5.94648 11.5335 5.52648L8.4735 2.46648C8.2135 2.20648 7.7935 2.20648 7.5335 2.46648L4.4735 5.52648C4.0535 5.94648 4.34683 6.66648 4.94016 6.66648H6.00016V9.99982C6.00016 10.3665 6.30016 10.6665 6.66683 10.6665ZM4.00016 11.9998H12.0002C12.3668 11.9998 12.6668 12.2998 12.6668 12.6665C12.6668 13.0332 12.3668 13.3332 12.0002 13.3332H4.00016C3.6335 13.3332 3.3335 13.0332 3.3335 12.6665C3.3335 12.2998 3.6335 11.9998 4.00016 11.9998Z"
        fill={fill}
      />
    </svg>
  );
};

export const TemplateGroupIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        d="M13.4333 2.10018L11.5733 0.240183C11.36 0.0268498 11 0.173516 11 0.473516V1.66685H1.66667C1.3 1.66685 1 1.96685 1 2.33352C1 2.70018 1.3 3.00018 1.66667 3.00018H11V4.19352C11 4.49352 11.36 4.64018 11.5667 4.42685L13.4267 2.56685C13.56 2.44018 13.56 2.22685 13.4333 2.10018Z"
        fill={fill}
      />
      <path
        d="M0.566839 7.90035L2.42684 9.76034C2.64017 9.97368 3.00017 9.82701 3.00017 9.52701V8.33368H12.3335C12.7002 8.33368 13.0002 8.03368 13.0002 7.66701C13.0002 7.30035 12.7002 7.00035 12.3335 7.00035H3.00017V5.80701C3.00017 5.50701 2.64017 5.36035 2.43351 5.57368L0.573506 7.43368C0.440172 7.56035 0.440172 7.77368 0.566839 7.90035Z"
        fill={fill}
      />
    </svg>
  );
};

export const SearchIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        d="M10.3333 9.33326H9.8066L9.61993 9.15326C10.4199 8.21993 10.8333 6.9466 10.6066 5.59326C10.2933 3.73993 8.7466 2.25993 6.87993 2.03326C4.05993 1.6866 1.6866 4.05993 2.03326 6.87993C2.25993 8.7466 3.73993 10.2933 5.59326 10.6066C6.9466 10.8333 8.21993 10.4199 9.15326 9.61993L9.33326 9.8066V10.3333L12.1666 13.1666C12.4399 13.4399 12.8866 13.4399 13.1599 13.1666C13.4333 12.8933 13.4333 12.4466 13.1599 12.1733L10.3333 9.33326ZM6.33326 9.33326C4.67326 9.33326 3.33326 7.99326 3.33326 6.33326C3.33326 4.67326 4.67326 3.33326 6.33326 3.33326C7.99326 3.33326 9.33326 4.67326 9.33326 6.33326C9.33326 7.99326 7.99326 9.33326 6.33326 9.33326Z"
        fill={fill}
      />
    </svg>
  );
};

export const FileIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      onClick={clickHandler}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00008 1.33325C3.26675 1.33325 2.67341 1.93325 2.67341 2.66659L2.66675 13.3333C2.66675 14.0666 3.26008 14.6666 3.99341 14.6666H12.0001C12.7334 14.6666 13.3334 14.0666 13.3334 13.3333V5.88659C13.3334 5.53325 13.1934 5.19325 12.9401 4.94659L9.72008 1.72659C9.47341 1.47325 9.13341 1.33325 8.78008 1.33325H4.00008ZM8.66675 5.33325V2.33325L12.3334 5.99992H9.33341C8.96675 5.99992 8.66675 5.69992 8.66675 5.33325Z"
        fill={fill}
      />
    </svg>
  );
};

export const HelpIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.67 12.68 13.31 13.14 13.13 13.86C13.05 14.18 13 14.54 13 15H11V14.5C11 14.04 11.08 13.6 11.22 13.19C11.42 12.61 11.75 12.09 12.17 11.67L13.41 10.41C13.87 9.97 14.09 9.31 13.96 8.61C13.83 7.89 13.27 7.28 12.57 7.08C11.46 6.77 10.43 7.4 10.1 8.35C9.98 8.72 9.67 9 9.28 9H8.98C8.4 9 8 8.44 8.16 7.88C8.59 6.41 9.84 5.29 11.39 5.05C12.91 4.81 14.36 5.6 15.26 6.85C16.44 8.48 16.09 10.23 15.07 11.25Z"
        fill={fill}
      />
    </svg>
  );
};

export const DuplicateIcon = ({
  width,
  height,
  fill = "none",
  className,
  onClick: clickHandler,
  style,
  title,
}) => {
  return (
    <svg
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox="-56 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={clickHandler}
    >
      <title>{title}</title>
      <path
        fill={fill}
        d="m395.980469 112.582031-108.023438-108.023437c-2.960937-2.960938-6.949219-4.558594-11.019531-4.558594h-156.339844c-8.597656 0-15.578125 6.980469-15.578125 15.578125v89.445313h-89.441406c-8.597656 0-15.578125 6.976562-15.578125 15.578124v375.820313c0 8.597656 6.980469 15.578125 15.578125 15.578125h264.359375c8.601562 0 15.582031-6.980469 15.582031-15.578125v-89.445313h89.441407c8.597656 0 15.578124-6.976562 15.578124-15.578124v-267.796876c0-3.960937-1.523437-7.984374-4.558593-11.019531zm-26.597657 263.238281h-73.863281v-147.195312c0-4.035156-1.570312-8.03125-4.5625-11.023438l-108.019531-108.019531c-2.933594-2.9375-6.914062-4.5625-11.019531-4.5625h-35.738281v-73.863281h125.179687v92.445312c0 8.597657 6.980469 15.578126 15.578125 15.578126h92.445312zm-105.023437 105.023438h-233.203125v-344.664062h125.179688v92.445312c0 8.597656 6.980468 15.578125 15.582031 15.578125h92.441406zm-76.863281-322.636719 54.835937 54.835938h-54.835937zm159.855468-50.183593h-54.835937v-54.835938c6.855469 6.851562 49.367187 49.367188 54.835937 54.835938zm0 0"
      />
    </svg>
  );
};
