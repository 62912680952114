import React from "react";
import DefaultLayout from "../../../../components/DefaultLayout/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Page1 from "../../../../components/UserInfo/InstructionsModal/Pages/Page1";
import Page2 from "../../../../components/UserInfo/InstructionsModal/Pages/Page2";
import Page3 from "../../../../components/UserInfo/InstructionsModal/Pages/Page3";
import Page4 from "../../../../components/UserInfo/InstructionsModal/Pages/Page4";
import Page5 from "../../../../components/UserInfo/InstructionsModal/Pages/Page5";
import Page6 from "../../../../components/UserInfo/InstructionsModal/Pages/Page6";
import Page7 from "../../../../components/UserInfo/InstructionsModal/Pages/Page7";
import Page8 from "../../../../components/UserInfo/InstructionsModal/Pages/Page8";
import Page9 from "../../../../components/UserInfo/InstructionsModal/Pages/Page9";
import "./InstructionPagesMobileVersion.scss";

const InstructionPagesMobileVersion = () => {
  const {t} = useTranslation();
  let {id} = useParams();
  let page;

  switch (id) {
    case "1":
      page = <Page1 />;
      break;
    case "2":
      page = <Page2 />;
      break;
    case "3":
      page = <Page3 />;
      break;
    case "4":
      page = <Page4 />;
      break;
    case "5":
      page = <Page5 />;
      break;
    case "6":
      page = <Page6 />;
      break;
    case "7":
      page = <Page7 />;
      break;
    case "8":
      page = <Page8 />;
      break;
    case "9":
      page = <Page9 />;
      break;

    default:
      page = null;
      break;
  }

  return (
    <DefaultLayout isNonDesktop backArrow linkTitle={t("header.help")}>
      <div className="instruction-pages-mobile-version">{page}</div>
    </DefaultLayout>
  );
};

export default InstructionPagesMobileVersion;
