import React, {useRef, useEffect, useState} from "react";

const ImageWithAuth = ({url, style}) => {
  const imgRef = useRef();
  const token = localStorage.getItem("token");
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `token ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        const objectURL = URL.createObjectURL(blob);

        setLoading(false);

        if (imgRef.current != null) {
          imgRef.current.src = objectURL;
        }
      });
  }, [token, url]);

  return isLoading ? null : <img style={style} src={""} alt={""} ref={imgRef} />;
};

export default ImageWithAuth;
