import {createAsyncThunkAction} from "../../../utils/createAsyncThunkAction";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const googleAuth = createAsyncThunk(
  "groups/createNewGroup",
  async (arg, thunkAPI) => {
    try {
      let formData = new FormData();
      formData.append("code", arg.code);
      formData.append("redirect_uri", arg.redirectUri);

      const response = await fetch("/api/v1/oauth/social/token/google-oauth2/", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (response.status === 401) {
        localStorage.removeItem("token");
      }

      if (!response.ok) {
        return await thunkAPI.rejectWithValue(data);
      }

      localStorage.setItem("token", data.token);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const registerUser = createAsyncThunkAction(
  "auth/user/register",
  "/api/v1/auth/registration/",
  "POST",
  "data",
  {cb: (data) => localStorage.setItem("token", data.key)}
);

// export const registerUser = createAsyncThunk(
//   "auth/user/register",
//   async (arg, thunkAPI) => {
//     try {
//       const response = await fetch(`/api/v1/auth/registration/`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(arg),
//       });

//       const data = await response.json();

//       if (response.status === 401) {
//         localStorage.removeItem("token");
//       }

//       if (!response.ok) {
//         return thunkAPI.rejectWithValue(data);
//       }

//       localStorage.setItem("token", data.key);

//       return data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

export const loginUser = createAsyncThunkAction(
  "auth/user/login",
  "/api/v1/auth/login/",
  "POST",
  "data",
  {cb: (data) => localStorage.setItem("token", data.key)}
);

// export const loginUser = createAsyncThunk("auth/user/login", async (arg, thunkAPI) => {
//   try {
//     const response = await fetch(`/api/v1/auth/login/`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(arg),
//     });

//     const data = await response.json();

//     if (response.status === 401) {
//       localStorage.removeItem("token");
//     }

//     if (!response.ok) {
//       return thunkAPI.rejectWithValue(data);
//     }

//     localStorage.setItem("token", data.key);
//     return data;
//   } catch (error) {
//     throw error;
//   }
// });

export const logout = createAsyncThunkAction(
  "auth/user/logout",
  "/api/v1/auth/logout/",
  "POST",
  undefined,
  {cb: () => localStorage.removeItem("token")}
);

// export const logout = createAsyncThunk("auth/user/logout", async (arg, thunkAPI) => {
//   try {
//     const response = await tokenFetch(`/api/v1/auth/logout/`, {
//       method: "POST",
//     });

//     const data = await response.json();

//     if (response.status === 401) {
//       localStorage.removeItem("token");
//     }

//     if (!response.ok) {
//       return thunkAPI.rejectWithValue(data);
//     }

//     localStorage.removeItem("token");
//     return data;
//   } catch (error) {
//     throw error;
//   }
// });

export const getUserData = createAsyncThunkAction(
  "auth/user/data",
  "/api/v1/auth/user/",
  "GET",
  undefined,
  {isNeedAuth: true}
);

// export const getUserData = createAsyncThunk("auth/user/data", async (arg, thunkAPI) => {
//   try {
//     const response = await tokenFetch(`/api/v1/auth/user/`);

//     const data = await response.json();

//     if (response.status === 401) {
//       localStorage.removeItem("token");
//     }

//     if (!response.ok) {
//       return thunkAPI.rejectWithValue(data);
//     }

//     return data;
//   } catch (error) {
//     throw error;
//   }
// });
