import * as actionTypes from "../../constants/ActionsTypes";

const initialState = {
  isLoading: false,
  errorText: null,
  user: null,
  isLoggedIn: false,
};

export default function auth(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.GET_USER_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorText: null,
      };
    case actionTypes.GET_USER_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        user: action.payload,
      };
    case actionTypes.GET_USER_DATA_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false,
        errorText: action.payload,
      };
    case actionTypes.USER_LOGIN:
      return {
        ...state,
        isLoggedIn: true,
      };
    case actionTypes.USER_LOGIN_BY_LOCALSTORAGE:
      return {
        ...state,
        isLoggedIn: true,
      };
    case actionTypes.USER_LOGOUT:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
      };
    case actionTypes.USER_TOKEN_IS_EXPIRED:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        isLoading: false,
        errorText: "token is expired",
      };
    default:
      return state;
  }
}
