import {createSlice} from "@reduxjs/toolkit";
import {fetchTemplates, fetchTemplatesByGroup} from "./actions";

export const templatesV2 = createSlice({
  name: "templatesV2",
  initialState: {
    isLoading: false,
    count: null,
    items: [],
    errorText: null,
    page: 0,
    limit: 0,
  },
  extraReducers: {
    [fetchTemplates.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTemplates.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.items = action.payload.results;
      state.count = action.payload.count;
      state.page = action.meta.arg?.page;
      state.limit = action.meta.arg?.limit;
    },
    [fetchTemplates.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorText = action.error.message;
    },
    [fetchTemplatesByGroup.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTemplatesByGroup.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.items = action.payload.results;
      state.count = action.payload.count;
      state.page = action.meta.arg?.page;
      state.limit = action.meta.arg?.limit;
    },
    [fetchTemplatesByGroup.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorText = action.error.message;
    },
  },
});

export default templatesV2.reducer;
