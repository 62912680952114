import React from "react";
import {Modal, ModalBody, ModalFooter, Button, Spinner} from "reactstrap";
import {MainColors} from "../../constants/CommonProperties";
import {TemplateNotFoundIcon} from "../Icons";
import {useTranslation} from "react-i18next";
import "./NoTemplateFoundModal.scss";

const NoTemplateFoundModal = ({
  isOpen,
  headerText,
  paragraphText,
  saveButtonText,
  onDecline: cancel,
  onAccept: accept,
  showSpinner,
  hiddenCancelButton,
}) => {
  const {t} = useTranslation();

  return (
    <Modal isOpen={isOpen} centered>
      <ModalBody className="position-relative pt-4 pr-4 pl-4 pb-0">
        <TemplateNotFoundIcon
          width="20"
          height="19"
          fill={MainColors.systemPalette.error}
          style={{position: "absolute", top: 30, left: 20}}
        />
        <h5 className={"modal__header mb-3 pl-4"}>
          <b>{headerText}</b>
        </h5>
        <p className="ml-4">{paragraphText}</p>
      </ModalBody>
      <ModalFooter className={"modal__footer"}>
        {!hiddenCancelButton && (
          <Button size="sm" color="secondary" onClick={() => cancel()}>
            {t("modal.cancel").toLocaleUpperCase()}
          </Button>
        )}
        <Button size="sm" color="primary" onClick={() => accept()}>
          {saveButtonText}
          {showSpinner ? (
            <Spinner style={{marginLeft: "10px"}} size="sm" color="light" />
          ) : null}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NoTemplateFoundModal;
