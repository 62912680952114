import React, {useState, useEffect} from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  Alert,
} from "reactstrap";
import {useFormik} from "formik";
import {loginUser, googleAuth} from "../../redux/feature/authV2/actions";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {SignInScheme} from "../../formik/validate";
import {capitalize, capitalizeFirstLetter} from "../../utils";
import {useTranslation} from "react-i18next";
import {ReactComponent as GoogleIcon} from "../../assets/Google.svg";
import "./Forms.scss";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import {ReactComponent as DocIcon} from "../../assets/DocIcon.svg";

const SignIn = () => {
  const [apiErrors, setApiErrors] = useState({});
  const history = useHistory();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const isNonDesktop = useMediaQuery();

  //Get information from location state to recognize link from previous page
  //If previous page send additional information - show this information as an alert
  const isAlertShown = location.state ? !!location.state.success : false;
  const alertText = location.state
    ? location.state.success
      ? location.state.success.message
      : ""
    : "";

  //Remove alert after 3 sec.
  useEffect(() => {
    sessionStorage.removeItem("passwordReload");
    if (isAlertShown) {
      setTimeout(() => history.replace(), 3000);
    }
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SignInScheme,
    onSubmit: () => {
      const values = {...formik.values, email: formik.values.email};
      dispatch(loginUser(values)).then((data) => {
        if (data.error) {
          setApiErrors(data.payload);
        }
      });
    },
  });

  const goToSignUp = () => {
    history.push("/signup");
  };

  const goToReset = () => {
    history.push("/reset");
  };

  const googleAuthHandler = () => {
    const {gapi} = window;

    gapi.auth2.authorize(
      {
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "email profile openid",
        response_type: "code",
      },
      function (response) {
        if (response.error) {
          alert(response.error);
          return;
        }

        const code = response.code;

        dispatch(googleAuth({code, redirectUri: window.location.origin}));
      }
    );
  };

  if (isNonDesktop) {
    return (
      <>
        <Alert isOpen={isAlertShown} className={"success-alert"} color="success">
          {alertText}
        </Alert>
        <Row noGutters className={"sign-form__row"}>
          <Form onSubmit={formik.handleSubmit} className={"sign-form__form"}>
            <div>
              <div className="p-3 sign-form__header">
                <DocIcon className="sign-form__logo" />
                {t("sign-in.sign-in").toLocaleUpperCase()}
              </div>
              <div className="p-3 sign-form__body">
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    invalid={
                      !!(
                        formik.touched.email &&
                        (formik.errors.email ||
                          (apiErrors.email && Boolean(apiErrors.email[0])))
                      )
                    }
                    valid={
                      formik.touched.email && (!formik.errors.email || !apiErrors.email)
                    }
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="off"
                    placeholder={capitalizeFirstLetter(t("sign-in.email-placeholder"))}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <FormFeedback>
                    {t(
                      formik.touched.email &&
                        (formik.errors.email || (apiErrors.email && apiErrors.email[0]))
                    )}
                  </FormFeedback>
                </FormGroup>
                <FormGroup style={{marginTop: "-10px"}}>
                  <Label for="password">{capitalize(t("sign-in.password"))}</Label>
                  <Input
                    invalid={
                      !!(
                        formik.touched.password &&
                        (formik.errors.password ||
                          (apiErrors.password && Boolean(apiErrors.password[0])))
                      )
                    }
                    valid={
                      formik.touched.password &&
                      (!formik.errors.password || !apiErrors.password)
                    }
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="off"
                    placeholder={capitalizeFirstLetter(t("sign-in.password-placeholder"))}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <FormFeedback>
                    {t(
                      formik.touched.password &&
                        (formik.errors.password ||
                          (apiErrors.password && apiErrors.password[0]))
                    )}
                  </FormFeedback>
                </FormGroup>
                <div className="mt-4 text-danger text-center">
                  {apiErrors.non_field_errors && apiErrors.non_field_errors[0]}
                </div>
              </div>
            </div>
            <div className="sign-form__footer">
              <div className="d-flex justify-content-between">
                <div style={{width: "40%"}}>
                  <Button
                    size="sm"
                    style={{width: "100%"}}
                    color="link"
                    className="sign-form__button"
                    onClick={goToSignUp}
                  >
                    {t("sign-up.sign-up").toLocaleUpperCase()}
                  </Button>
                </div>
                <div style={{width: "60%"}} className="d-flex justify-content-end">
                  <Button
                    style={{width: "30%"}}
                    size="sm"
                    color="primary"
                    className="sign-form__button mr-2"
                    onClick={googleAuthHandler}
                  >
                    <GoogleIcon />
                  </Button>
                  <Button
                    style={{width: "70%"}}
                    size="sm"
                    type="submit"
                    color="primary"
                    className="sign-form__button"
                  >
                    {t("sign-in.sign-in").toLocaleUpperCase()}
                  </Button>
                </div>
              </div>
              <div className="text-center pt-2">
                <Button
                  size="sm"
                  color="link"
                  className="mr-3 sign-form__button"
                  onClick={goToReset}
                >
                  {t("sign-in.forgot-password").toLocaleUpperCase()}
                </Button>
              </div>
            </div>
          </Form>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Alert isOpen={isAlertShown} className={"success-alert"} color="success">
          {alertText}
        </Alert>
        <Row noGutters className={"sign-form__row"}>
          <Form onSubmit={formik.handleSubmit} className={"sign-form__form"}>
            <div className="p-3 sign-form__header">
              {capitalize(t("sign-in.sign-in"))}
            </div>
            <div className="p-3 sign-form__body">
              <Button
                size="sm"
                color="primary"
                className="sign-form__button mb-2 mt-2"
                onClick={googleAuthHandler}
              >
                <GoogleIcon className="mr-2" />
                {t("sign-up.google-auth").toLocaleUpperCase()}
              </Button>
              <FormGroup>
                <Label for="email">{capitalizeFirstLetter(t("sign-in.email"))}</Label>
                <Input
                  invalid={
                    !!(
                      formik.touched.email &&
                      (formik.errors.email ||
                        (apiErrors.email && Boolean(apiErrors.email[0])))
                    )
                  }
                  valid={
                    formik.touched.email && (!formik.errors.email || !apiErrors.email)
                  }
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="off"
                  placeholder={capitalizeFirstLetter(t("sign-in.email-placeholder"))}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <FormFeedback>
                  {t(
                    formik.touched.email &&
                      (formik.errors.email || (apiErrors.email && apiErrors.email[0]))
                  )}
                </FormFeedback>
              </FormGroup>
              <FormGroup style={{marginTop: "-10px"}}>
                <Label for="password">{capitalize(t("sign-in.password"))}</Label>
                <Input
                  invalid={
                    !!(
                      formik.touched.password &&
                      (formik.errors.password ||
                        (apiErrors.password && Boolean(apiErrors.password[0])))
                    )
                  }
                  valid={
                    formik.touched.password &&
                    (!formik.errors.password || !apiErrors.password)
                  }
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="off"
                  placeholder={capitalizeFirstLetter(t("sign-in.password-placeholder"))}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                <FormFeedback>
                  {t(
                    formik.touched.password &&
                      (formik.errors.password ||
                        (apiErrors.password && apiErrors.password[0]))
                  )}
                </FormFeedback>
              </FormGroup>
              <div className="mt-4 text-danger text-center">
                {apiErrors.non_field_errors && apiErrors.non_field_errors[0]}
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <Button
                    size="sm"
                    color="link"
                    className="sign-form__button"
                    onClick={goToReset}
                  >
                    {t("sign-in.forgot-password").toLocaleUpperCase()}
                  </Button>
                </div>
                <div>
                  <Button
                    size="sm"
                    color="link"
                    className="mr-2 sign-form__button"
                    onClick={goToSignUp}
                  >
                    {t("sign-in.no-account").toLocaleUpperCase()}
                  </Button>
                  <Button
                    size="sm"
                    type="submit"
                    color="primary"
                    className="sign-form__button"
                  >
                    {t("sign-in.sign-in").toLocaleUpperCase()}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Row>
      </>
    );
  }
};

export default SignIn;
