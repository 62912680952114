/* global gapi */
import React, {useState, useEffect} from "react";
import {Button, Form, FormGroup, Label, Input, FormFeedback, Row, Col} from "reactstrap";
import {useFormik} from "formik";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {registerUser, googleAuth} from "../../redux/feature/authV2/actions";
import {SignUpScheme} from "../../formik/validate";
import Tooltip from "../../components/Tooltip/Tooltip";
import {useTranslation} from "react-i18next";
import {capitalize, capitalizeFirstLetter} from "../../utils";
import {ReactComponent as GoogleIcon} from "../../assets/Google.svg";
import "./Forms.scss";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import {ReactComponent as DocIcon} from "../../assets/DocIcon.svg";

const PRIVACY_URL =
  "https://drive.google.com/file/d/1kLmUEHc1TuGs8G9VWxfChSJMIeToFexR/view?usp=sharing";

const SignUp = () => {
  const history = useHistory();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isNonDesktop = useMediaQuery();

  const [apiErrors, setApiErrors] = useState({
    email: "",
    password1: "",
    password2: "",
    business_domain: "",
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password1: "",
      password2: "",
      business_domain: "other",
    },
    validationSchema: SignUpScheme,
    onSubmit: () => {
      const values = {...formik.values};
      const {grecaptcha} = window;

      //reCAPTCHA
      const siteKey =
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_PROD
          : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_LOCAL;

      grecaptcha.ready(function () {
        grecaptcha
          .execute(siteKey, {action: "submit"})
          .then(function (token) {
            dispatch(registerUser({...values, recaptcha_response: token})).then(
              (data) => {
                if (data.error) {
                  setApiErrors(data.payload);
                }
              }
            );
          })
          .catch((err) => console.log(err));
      });
    },
  });

  const goToSignIn = () => {
    history.push("/signin");
  };

  const googleAuthHandler = () => {
    gapi.auth2.authorize(
      {
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "email profile openid",
        response_type: "code",
      },
      function (response) {
        if (response.error) {
          console.log("gapiAuthError:", response.error);
          return;
        }

        const code = response.code;

        dispatch(googleAuth({code, redirectUri: window.location.origin}));
      }
    );
  };

  useEffect(() => {
    const {gapi} = window;
    gapi.load("auth2", null);
  }, []);

  if (isNonDesktop) {
    return (
      <Row noGutters className={"sign-form__row"}>
        <Form className={"sign-form__form"} onSubmit={formik.handleSubmit}>
          <div>
            <div className="p-3 sign-form__header">
              <DocIcon className="sign-form__logo" />
              {t("sign-up.sign-up").toLocaleUpperCase()}
            </div>
            <div className="p-3 sign-form__body">
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  invalid={
                    !!(
                      formik.touched.email &&
                      (formik.errors.email || (apiErrors.email && apiErrors.email[0]))
                    )
                  }
                  valid={
                    !!(formik.touched.email && (!formik.errors.email || !apiErrors.email))
                  }
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="off"
                  placeholder={capitalizeFirstLetter(t("sign-up.email-placeholder"))}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <FormFeedback>
                  {t(
                    formik.touched.email &&
                      (formik.errors.email || (apiErrors.email && apiErrors.email[0]))
                  )}
                </FormFeedback>
              </FormGroup>
              <FormGroup style={{marginTop: "-10px"}}>
                <Label for="password1">{capitalize(t("sign-in.password"))}</Label>
                <Input
                  invalid={
                    formik.touched.password1 &&
                    (formik.errors.password1 ||
                      (apiErrors.password1 && apiErrors.password1[0]))
                  }
                  valid={
                    formik.touched.password1 &&
                    (!formik.errors.password1 || !apiErrors.password1)
                  }
                  type="password"
                  name="password1"
                  id="password1"
                  autoComplete="off"
                  placeholder={capitalizeFirstLetter(t("sign-in.password-placeholder"))}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password1}
                />
                <FormFeedback>
                  {t(
                    formik.touched.password1 &&
                      (formik.errors.password1 ||
                        (apiErrors.password1 && apiErrors.password1[0]))
                  )}
                </FormFeedback>
              </FormGroup>
              <FormGroup style={{marginTop: "-10px"}}>
                <Label for="password2">{capitalize(t("sign-up.confirm-password"))}</Label>
                <Input
                  invalid={
                    formik.touched.password2 &&
                    (formik.errors.password2 ||
                      (apiErrors.password2 && apiErrors.password2[0]))
                  }
                  valid={
                    formik.touched.password2 &&
                    (!formik.errors.password2 || !apiErrors.password2)
                  }
                  type="password"
                  name="password2"
                  id="password2"
                  autoComplete="off"
                  placeholder={capitalizeFirstLetter(
                    t("sign-in.verify-password-placeholder")
                  )}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password2}
                />
                <FormFeedback>
                  {t(
                    formik.touched.password2 &&
                      (formik.errors.password2 ||
                        (apiErrors.password2 && apiErrors.password2[0]))
                  )}
                </FormFeedback>
              </FormGroup>
              <FormGroup style={{marginTop: "-10px"}}>
                <Label for="business_domain">
                  {capitalizeFirstLetter(t("sign-up.business-domain"))}
                </Label>
                <Input
                  type="select"
                  name="business_domain"
                  id="business_domain"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.business_domain}
                >
                  {[
                    "fintech",
                    "healthcare",
                    "education",
                    "real-estate",
                    "social-media",
                    "hr",
                    "other",
                  ].map((domain) => (
                    <option key={domain} value={domain}>
                      {capitalizeFirstLetter(t(`domain.${domain}`))}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <div className="mt-4 text-danger text-center">
                {apiErrors.non_field_errors && apiErrors.non_field_errors[0]}
              </div>
            </div>
          </div>
          <div className="sign-form__footer">
            <div className="d-flex justify-content-end ">
              <div style={{width: "40%"}}>
                <Button
                  style={{width: "100%"}}
                  size="sm"
                  color="link"
                  className="sign-form__button"
                  onClick={goToSignIn}
                >
                  {t("sign-in.sign-in").toLocaleUpperCase()}
                </Button>
              </div>
              <div style={{width: "60%"}} className="d-flex justify-content-end">
                <Button
                  style={{width: "30%"}}
                  size="sm"
                  color="primary"
                  className="sign-form__button mr-2"
                  onClick={googleAuthHandler}
                >
                  <GoogleIcon />
                </Button>
                <Button
                  style={{width: "70%"}}
                  size="sm"
                  type="submit"
                  color="primary"
                  className={"sign-form__button"}
                >
                  {t("sign-up.sign-up").toLocaleUpperCase()}
                </Button>
              </div>
            </div>
            <div className={"sign-form__privacy-text"}>
              {t("sign-up.privacy-text")}
              <a target={"_blank"} href={PRIVACY_URL}>
                {t("sign-up.privacy-link")}
              </a>
            </div>
          </div>
        </Form>
      </Row>
    );
  } else {
    return (
      <Row noGutters className={"sign-form__row"}>
        <Form className={"sign-form__form"} onSubmit={formik.handleSubmit}>
          <div className="p-3 sign-form__header">{capitalize(t("sign-up.sign-up"))}</div>
          <div className="p-3 sign-form__body">
            <Button
              size="sm"
              color="primary"
              className="sign-form__button mb-2 mt-2"
              onClick={googleAuthHandler}
            >
              <GoogleIcon className="mr-2" />
              {t("sign-up.google-auth").toLocaleUpperCase()}
            </Button>
            <FormGroup>
              <Label for="email">{capitalizeFirstLetter(t("sign-up.email"))}</Label>
              <Tooltip text={t("sign-up.email-tooltip")}>
                <Input
                  invalid={
                    !!(
                      formik.touched.email &&
                      (formik.errors.email || (apiErrors.email && apiErrors.email[0]))
                    )
                  }
                  valid={
                    !!(formik.touched.email && (!formik.errors.email || !apiErrors.email))
                  }
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="off"
                  placeholder={capitalizeFirstLetter(t("sign-up.email-placeholder"))}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <FormFeedback>
                  {t(
                    formik.touched.email &&
                      (formik.errors.email || (apiErrors.email && apiErrors.email[0]))
                  )}
                </FormFeedback>
              </Tooltip>
            </FormGroup>
            <FormGroup style={{marginTop: "-10px"}}>
              <Label for="password1">{capitalize(t("sign-in.password"))}</Label>
              <Tooltip text={t("sign-up.password-tooltip")}>
                <Input
                  invalid={
                    formik.touched.password1 &&
                    (formik.errors.password1 ||
                      (apiErrors.password1 && apiErrors.password1[0]))
                  }
                  valid={
                    formik.touched.password1 &&
                    (!formik.errors.password1 || !apiErrors.password1)
                  }
                  type="password"
                  name="password1"
                  id="password1"
                  autoComplete="off"
                  placeholder={capitalizeFirstLetter(t("sign-in.password-placeholder"))}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password1}
                />
                <FormFeedback>
                  {t(
                    formik.touched.password1 &&
                      (formik.errors.password1 ||
                        (apiErrors.password1 && apiErrors.password1[0]))
                  )}
                </FormFeedback>
              </Tooltip>
            </FormGroup>
            <FormGroup style={{marginTop: "-10px"}}>
              <Label for="password2">{capitalize(t("sign-up.confirm-password"))}</Label>
              <Tooltip text={t("sign-up.password-tooltip")}>
                <Input
                  invalid={
                    formik.touched.password2 &&
                    (formik.errors.password2 ||
                      (apiErrors.password2 && apiErrors.password2[0]))
                  }
                  valid={
                    formik.touched.password2 &&
                    (!formik.errors.password2 || !apiErrors.password2)
                  }
                  type="password"
                  name="password2"
                  id="password2"
                  autoComplete="off"
                  placeholder={capitalizeFirstLetter(
                    t("sign-in.verify-password-placeholder")
                  )}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password2}
                />
                <FormFeedback>
                  {t(
                    formik.touched.password2 &&
                      (formik.errors.password2 ||
                        (apiErrors.password2 && apiErrors.password2[0]))
                  )}
                </FormFeedback>
              </Tooltip>
            </FormGroup>
            <FormGroup style={{marginTop: "-10px"}}>
              <Label for="business_domain">
                {capitalizeFirstLetter(t("sign-up.business-domain"))}
              </Label>
              <Input
                type="select"
                name="business_domain"
                id="business_domain"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.business_domain}
              >
                {[
                  "fintech",
                  "healthcare",
                  "education",
                  "real-estate",
                  "social-media",
                  "hr",
                  "other",
                ].map((domain) => (
                  <option key={domain} value={domain}>
                    {capitalizeFirstLetter(t(`domain.${domain}`))}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <div className="mt-4 text-danger text-center">
              {apiErrors.non_field_errors && apiErrors.non_field_errors[0]}
            </div>
            <div className="d-flex justify-content-end mt-4">
              <Button
                size="sm"
                color="link"
                className=" mr-3 sign-form__button"
                onClick={goToSignIn}
              >
                {t("sign-in.sign-in").toLocaleUpperCase()}
              </Button>
              <Button
                size="sm"
                type="submit"
                color="primary"
                className={"sign-form__button"}
              >
                {t("sign-up.sign-up").toLocaleUpperCase()}
              </Button>
            </div>
            <div className={"sign-form__privacy-text"}>
              {t("sign-up.privacy-text")}
              <a target={"_blank"} href={PRIVACY_URL}>
                {t("sign-up.privacy-link")}
              </a>
            </div>
          </div>
        </Form>
      </Row>
    );
  }
};

export default SignUp;
