import * as actionTypes from "../../constants/ActionsTypes";
import {fetchWithToken} from "../../utils";

const tokenFetch = fetchWithToken(fetch);

export const setUserTokenExpired = (dispatch) => {
  localStorage.removeItem("token");
  dispatch({type: actionTypes.USER_TOKEN_IS_EXPIRED});
};

//async action for user registration
export const registerUser = (userRegInfo) => async (dispatch) => {
  try {
    let response = await fetch(`/api/v1/auth/registration/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userRegInfo),
    });
    if (response.ok) {
      const data = await response.json();
      localStorage.setItem("token", data.key);
      dispatch({type: actionTypes.USER_LOGIN});
      return response;
    } else {
      if (response.status === 401) {
        setUserTokenExpired(dispatch);
      }
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

//async action for user log in
export const loginUser = (userLoginInfo) => async (dispatch) => {
  try {
    let response = await fetch(`/api/v1/auth/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userLoginInfo),
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem("token", data.key);
      dispatch({type: actionTypes.USER_LOGIN});
      return response;
    } else {
      if (response.status === 401) {
        setUserTokenExpired(dispatch);
      }
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

//action for user log in by localStorage (receive token from localStorage)
export const loginUserByLocalStorage = (dispatch) => {
  if (localStorage.getItem("token"))
    dispatch({type: actionTypes.USER_LOGIN_BY_LOCALSTORAGE});
};

//async action for getting user data
export const getUserData = () => async (dispatch) => {
  dispatch({type: actionTypes.GET_USER_DATA_REQUEST});
  try {
    let response = await tokenFetch(`/api/v1/auth/user/`);
    let data;

    if (response.ok) {
      data = await response.json();
      dispatch({type: actionTypes.GET_USER_DATA_REQUEST_SUCCESS, payload: data});
      return response;
    } else {
      if (response.status === 401) {
        setUserTokenExpired(dispatch);
      }
      throw response;
    }
  } catch (error) {
    dispatch({
      type: actionTypes.GET_USER_DATA_REQUEST_FAIL,
      payload: "fetch user data error",
    });
    throw error;
  }
};

//async action for user log out
export const logout = () => async (dispatch) => {
  try {
    let response = await tokenFetch(`/api/v1/auth/logout/`, {
      method: "POST",
    });

    if (response.ok) {
      dispatch({type: actionTypes.USER_LOGOUT});
      localStorage.removeItem("token");
      return response;
    } else {
      if (response.status === 401) {
        setUserTokenExpired(dispatch);
      }
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

//async action to request password reset
export const passwordResetRequest = async (email) => {
  try {
    const response = await fetch("/api/v1/auth/password/reset/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({email}),
    });

    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    } else {
      throw await response.json();
    }
  } catch (error) {
    throw error;
  }
};

//async action to change password
export const newPasswordRequest = async (info) => {
  try {
    const response = await fetch("/api/v1/auth/password/reset/confirm/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify(info),
    });

    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    } else {
      throw await response.json();
    }
  } catch (error) {
    throw error;
  }
};
