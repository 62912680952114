import React from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as Illustration2} from "../../../../assets/HelpIllustrations/page2-illustration.svg";

const Page2 = () => {
  const {t} = useTranslation();
  return (
    <div className={"help-page"}>
      <h1 className={"help-page__title"}>{t("help.page2.title")}</h1>
      <p>{t("help.page2.text1")}</p>
      <p>{t("help.page2.text2")}</p>
      <div className={"help-page__image-container help-page__image"}>
        <Illustration2 />
      </div>
    </div>
  );
};

export default Page2;
