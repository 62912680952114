import React, {useState, useEffect} from "react";
import {Navbar, NavItem, NavLink, Nav} from "reactstrap";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import "../Header.scss";
import UserInfo from "../../UserInfo/UserInfo";
import {ReactComponent as DocIcon} from "../../../assets/DocIcon.svg";

const Header = () => {
  const isLoggedIn = useSelector((state) => state.authV2.isLoggedIn);
  const [activeRef, setActiveRef] = useState("");

  const {t} = useTranslation();

  useEffect(() => {
    if (
      window.location.href.includes("templates") ||
      window.location.href.includes("edit")
    ) {
      setActiveRef("templates");
    } else {
      setActiveRef("recognition");
    }
  }, []);

  return (
    <Navbar light expand="lg" className="pt-3 pb-3 header-navbar">
      <div className={"header-navbar__container"}>
        <a href={process.env.REACT_APP_LANDING_URL}>
          <DocIcon />
        </a>
        <Nav>
          <NavItem>
            <NavLink
              className={activeRef !== "templates" ? "header-navbar__disabled-item" : ""}
              to="/templates"
              tag={Link}
              active={activeRef === "templates"}
            >
              {t("header.templates").toLocaleUpperCase()}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={
                activeRef !== "recognition" ? "header-navbar__disabled-item" : ""
              }
              to="/recognition/results"
              tag={Link}
              active={activeRef === "recognition"}
            >
              {t("header.recognition").toLocaleUpperCase()}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      {isLoggedIn && (
        <div className={"header-navbar__container"}>
          <UserInfo noWarning />
        </div>
      )}
    </Navbar>
  );
};

export default Header;
